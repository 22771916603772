import React from "react";
import { Link } from "react-router-dom";
import SponsorView from "../../Sponsor/View";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { isEmpty } from "lodash";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";

const Sponsor = ({ sponsors, props }) => {
  return (
    <>
      {!isEmpty(sponsors) ? (
        <div>
          <h3 className="text-xl md:text-[32px] font-semibold text-[#212121] ">
            Sponsors
          </h3>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper mt-5 gallary-slider !pb-10"
          >
            {sponsors &&
              sponsors.map((singleSponsor, index) => {
                return (
                  <>
                    <SwiperSlide>
                      {/* <Link to={"/sponsor/" + singleSponsor.id + "/details"}> */}
                      <SponsorView singleSponsor={singleSponsor} key={index} />
                      {/* </Link> */}
                    </SwiperSlide>
                  </>
                );
              })}
          </Swiper>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Sponsor;
