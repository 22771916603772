import React from "react";
import ArtistView from "../../components/Artist/View";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
import { isEmpty } from "lodash";
import artistPerformance from "../../assets/light_theme_images/artist-performance.gif";
import arrowRight from "../../assets/light_theme_images/arrow-right.svg";

const Artist = ({ artists, props }) => {
  return (
    <>
      {!isEmpty(artists) ? (
        <div className="pb-16">
          <div className="flex md:items-center justify-between gap-4 mb-6">
            <div className="flex md:gap-4">
              <div className="flex">
                <img
                  src={artistPerformance}
                  alt="popular evrnt"
                  className="inline-block mr-1 md:mr-2 size-6 md:size-9"
                />
                <h3 className="text-base md:text-3xl font-bold text-[#212121] capitalize line-clamp-1">
                  Artists Performing Near You
                </h3>
              </div>
              {/* <img
                src={arrowRight}
                alt="popular evrnt"
                className="inline-block"
              /> */}
            </div>

            {/* <button
                onClick={() => {
                  props.router.navigate("/events");
                }}
                className="text-[#CB2129] border border-[#CB2129] text-sm md:text-base font-semibold py-2 px-4 rounded-lg font-body whitespace-nowrap hidden md:inline-block"
              >
                View all
              </button> */}
          </div>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2.4,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3.4,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper"
          >
            {artists &&
              artists.map((singleArtist, index) => (
                <>
                  {!isEmpty(singleArtist.artist) ? (
                    <SwiperSlide>
                      {/* <Link to={"/artiest/" + singleArtist.artist.slug}> */}
                      <ArtistView
                        singleArtist={singleArtist.artist}
                        pageTitle="EventDetails"
                        props={props}
                        key={index}
                      />
                      {/* </Link> */}
                    </SwiperSlide>
                  ) : (
                    ""
                  )}
                </>
              ))}
          </Swiper>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Artist;
