import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/default/organiser-logo.png";
import banner from "../../assets/images/mobile/artist-banner.png";
import banner2 from "../../assets/images/organiser-banner.png";
import icon from "../../assets/images/small/star-yellow.png";
import testimonail from "../../assets/images/small/testimonail-logo.png";
import { numberFormatter } from "../../common/commonFunction";
import StarRating from "../../components/Common/StarRating";
import Artist from "../../components/Event/Details/Artist";
import EventView from "../../components/Event/View";
import SkeletonLoaderOrganiserDetails from "../../components/SkeletonLoader/Organiser/Details";
import api from "../../constants/api";
import SeoDetails from "../../seo/SeoDetails";
import { clearData, getData } from "../../store/AppMaster/actions";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import calendarIcon from "../../assets/light_theme_images/event-calendar.svg";
import calendar from "../../assets/light_theme_images/calender-dark.svg";
import fanebaseIcon from "../../assets/light_theme_images/fanebase.svg";
import starIcon from "../../assets/light_theme_images/star-dark.svg";
import locationIcon from "../../assets/light_theme_images/location-dark.svg";
import instagramIcon from "../../assets/light_theme_images/instagram.svg";
import facebookIcon from "../../assets/light_theme_images/facebook.svg";
import twitterIcon from "../../assets/light_theme_images/twitter.svg";
import postEvent from "../../assets/light_theme_images/post-event.png";
import postEvent2 from "../../assets/light_theme_images/post-event2.png";
import postEvent3 from "../../assets/light_theme_images/post-event3.png";

const OrganiserDetails = (props) => {
  const [activeButton, setActiveButton] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const [profileData, setProfileData] = useState([]);
  // const [artistList, setArtistList] = useState([]);
  const [artists, setArtists] = useState(null);
  const [profileFlag, setProfileFlag] = useState(false);
  const [organiser, setOrganiser] = useState(null);
  const [ratingUser, setRatingUser] = useState({});
  const [eventDetail, setEventDetail] = useState(null);
  const [isEventData, setIsEventData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isOrganiserDataGet, setIsOrganiserDataGet] = useState(false);
  const [isUpcomingEventData, setIsUpcomingEventData] = useState(false);
  const [upcomingEventDetail, setUpcomingEventDetail] = useState("");
  const [isArtistDataGet, setIsArtistDataGet] = useState(true);

  const ratings = [1, 2, 3, 4, 5];

  // Calculate total ratings from ratingUser
  const totalRatings = Object.values(ratingUser).reduce(
    (sum, count) => sum + count,
    0
  );

  // Function to handle button click and change active button
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    if (buttonId === 1) {
      upComingEventDataGet(); // Fetch upcoming events
    } else {
      eventDataGet(); // Fetch past events
    }
  };

  const organiserDataGet = useCallback(() => {
    setIsOrganiserDataGet(true);
    let requestData = {
      merchant_company_slug: params.organiser_slug,
    };
    dispatch(clearData());
    dispatch(getData(requestData, "", api.OrganiserDetails));
  });

  const eventDataGet = useCallback(() => {
    setIsEventData(true);

    let requestData = {
      merchant_company_slug: params.organiser_slug,
      events: "Past",
    };
    dispatch(clearData());
    dispatch(getData(requestData, "", api.EventList));
  });

  const upComingEventDataGet = useCallback(() => {
    setIsUpcomingEventData(true);
    let requestData = {
      merchant_company_slug: params.organiser_slug,
      events: "Upcoming",
    };
    dispatch(clearData());
    dispatch(getData(requestData, "", api.EventList));
  });

  const artistDataGet = useCallback(() => {
    setIsArtistDataGet(true);
    let requestData = {
      merchant_company_slug: params.organiser_slug,
    };
    dispatch(clearData());
    dispatch(getData(requestData, "", api.EventArtistList));
  });

  useEffect(() => {
    if (isApiCall === 0) {
      organiserDataGet();
      upComingEventDataGet();
      artistDataGet();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.userView) && isOrganiserDataGet) {
      setOrganiser(data.userView);
      setIsOrganiserDataGet(false);
      setRatingUser(data.userView.ratingUser);
    }
    if (!isEmpty(data) && Array.isArray(data.eventList)) {
      if (activeButton === 1) {
        setEventDetail(data.eventList); // Update past events
      } else {
        setUpcomingEventDetail(data.eventList); // Update upcoming events
      }
    }
    if (!isEmpty(data) && !isEmpty(data.profileView) && profileFlag) {
      setProfileData(data.profileView);
    }

    if (!isEmpty(data) && !isEmpty(data.artistList) && isArtistDataGet) {
      setArtists(data.artistList);
      setIsArtistDataGet(false);
    }
  }, [data, activeButton]);

  const testimonialEnabled =
    process.env.REACT_APP_ARTIST_VIEW_TESTIMONIAL === "true";

  return (
    <>
      <SeoDetails
        title={organiser && organiser.merchant_company_name + " | mepass.in"}
        description={organiser && organiser.short_description}
        ogImage={organiser && organiser.logo}
      />
      <section className="pt-10">
        <div className="container">
          <Swiper
            cssMode={true}
            mousewheel={true}
            keyboard={true}
            slidesPerView={1}
            centeredSlides={false}
            spaceBetween={40}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1.1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1.1,
                spaceBetween: 40,
              },
            }}
            modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
            className="mySwiper event-details-slider"
          >
            <SwiperSlide>
              <img
                src={
                  organiser && organiser.cover_image
                    ? organiser.cover_image
                    : banner2
                }
                alt=""
                className="rounded-xl aspect-[99/40] w-full"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="bg-white pt-14 pb-14">
        <div className="container">
          <div className="flex">
            <div className="flex-none w-[20%] md:w-[40%] lg:w-1/4">
              {organiser && organiser.logo && (
                <div className="bg-white rounded-lg max-h-full md:max-h-80 flex items-center justify-center">
                  <img
                    src={organiser.logo ? organiser.logo : logo}
                    alt=""
                    className="rounded-xl"
                  />
                </div>
              )}
            </div>
            <div className="flex-1 w-full md:w-3/4 ml-6">
              <div className="flex mb-5">
                <div className="flex-1">
                  {organiser && organiser.merchant_company_name && (
                    <h2 className="text-lg md:text-2xl lg:text-4xl font-bold text-[#101010]">
                      {organiser.merchant_company_name}
                    </h2>
                  )}
                </div>
                {/* <div className="flex-none">
                  <button className="btn-liner-gradient text-white text-sm md:text-base text-center font-medium py-[6px] md:py-[10px] px-2 md:px-8 rounded-lg font-body duration-1000 ease-in-out md:h-[54px] flex items-center justify-center">
                    <i className="fa fa-plus mr-2"></i>Follow
                  </button>
                </div> */}
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex gap-1.5">
                  <img
                    src={locationIcon}
                    alt="img"
                    className="inline-block mt-[2px] size-4 md:size-6"
                  />
                  <p className="text-[#101010] text-sm md:text-xl font-normal">
                    {organiser && organiser.address}
                  </p>
                </div>
                <div className="flex gap-2">
                  {organiser && organiser.total_event && (
                    <div className="flex items-center gap-2">
                      <img
                        src={calendarIcon}
                        alt="img"
                        className="inline-block size-4 md:size-5 -mt-[2px]"
                      />
                      <p className="text-[#101010] text-sm md:text-xl font-normal">
                        Events Organised:{" "}
                        <b>{numberFormatter(organiser.total_event)}+</b>
                      </p>
                    </div>
                  )}
                  {organiser && organiser.rating > 0 && totalRatings > 0 && (
                    <div className="flex items-center gap-2">
                      <img
                        src={starIcon}
                        alt="img"
                        className="inline-block size-4 md:size-5 -mt-[2px] md:-mt-[3px]"
                      />
                      {organiser && organiser.rating && (
                        <p className="text-[#101010] text-sm md:text-xl font-normal">
                          {organiser.rating}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                {organiser && organiser.fanbase && (
                  <div className="flex gap-2">
                    <img
                      src={fanebaseIcon}
                      alt="img"
                      className="inline-block size-4 md:size-6"
                    />
                    <p className="text-[#101010] text-sm md:text-xl font-normal">
                      Fanbase: <b>{numberFormatter(organiser.fanbase)}</b>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* --------About Organiser------------ */}

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 md:gap-20 pt-10">
            <div className="md:col-span-2">
              {/* <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] ">
                About Organiser
              </h2>
              <p className="text-base text-[#101010] font-normal mt-4">
                Under Sky Music is a premier event organizer based in Navi
                Mumbai, Maharashtra, 410210, known for curating unforgettable
                live music experiences that bring people together under the open
                sky. Established with a vision to redefine live entertainment,
                the organization has become a leading name in concert and
                festival production, combining innovative concepts with
                meticulous execution. From intimate acoustic evenings to
                large-scale international music festivals, Under Sky Music
                delivers events that leave a lasting impression on audiences.
              </p> */}
              {/* <p className="text-base text-[#101010] font-normal mt-6">
                Rooted in the vibrant cultural hub of Navi Mumbai, Under Sky
                Music not only entertains but also fosters a sense of community.
                By supporting local artists and eco-conscious practices, the
                organization emphasizes sustainable growth within the
                entertainment sector.
              </p> */}
            </div>
            {/* <div>
              <h4 className="text-2xl md:text-3xl font-semibold text-[#101010] ">
                Office Address
              </h4>
              <p className="text-base text-[#101010] font-normal mt-2">
                {organiser && organiser.address}
              </p>
              {organiser &&
                (organiser.facebook_url ||
                  organiser.instagram_url ||
                  organiser.youtube_url) && (
                  <div className="flex flex-col gap-4 mt-10">
                    <h4 className="text-2xl md:text-3xl font-semibold text-[#101010] ">
                      Follow
                    </h4>
                    <ul className="flex gap-5">
                      {organiser && organiser.instagram_url && (
                        <Link
                          to={organiser.instagram_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <img src={instagramIcon} alt="" />
                          </li>
                        </Link>
                      )}
                      {organiser && organiser.facebook_url && (
                        <Link
                          to={organiser.facebook_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <img src={facebookIcon} alt="" />
                          </li>
                        </Link>
                      )}
                      <li>
                        <img src={twitterIcon} alt="" />
                      </li>
                    </ul>
                  </div>
                )}
            </div> */}
          </div>

          {/* --------Upcoming Events------------ */}
          {!isEmpty(eventDetail) && eventDetail.length > 0 && (
            <div className="pt-10">
              <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
                Upcoming Events
              </h2>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1.3,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider !pb-10"
              >
                {eventDetail.map((singleEvent) => (
                  <SwiperSlide key={singleEvent.slug}>
                    <Link to={"/events/" + singleEvent.slug}>
                      <EventView singleEvent={singleEvent} />
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}

          {/* --------Past Events------------ */}
          {/* <div className="pt-10">
            <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
              Past Events
            </h2>
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1.3,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2.4,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper gallary-slider !pb-10"
            >
              <SwiperSlide>
                <div className="flex flex-col gap-4">
                  <div>
                    <img src={postEvent} alt="img" className="rounded-lg" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1.5">
                      <img src={calendar} alt="img" className="inline-block" />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        Sun 20 Oct 24
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block"
                      />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        Tea Post, Gandhinagar
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col gap-4">
                  <div>
                    <img src={postEvent2} alt="img" className="rounded-lg" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1.5">
                      <img src={calendar} alt="img" className="inline-block" />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        Sun 20 Oct 24
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block"
                      />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        Tea Post, Gandhinagar
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col gap-4">
                  <div>
                    <img src={postEvent3} alt="img" className="rounded-lg" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1.5">
                      <img src={calendar} alt="img" className="inline-block" />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        Sun 20 Oct 24
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block"
                      />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        Tea Post, Gandhinagar
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col gap-4">
                  <div>
                    <img src={postEvent} alt="img" className="rounded-lg" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1.5">
                      <img src={calendar} alt="img" className="inline-block" />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        Sun 20 Oct 24
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block"
                      />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        Tea Post, Gandhinagar
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
          {/* ----Experience of my Audience ---- */}
          {/* <div className="pt-10">
            <h4 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
              Experience of my Audience
            </h4>
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1.3,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper gallary-slider !pb-10"
            >
              <SwiperSlide>
                <div className="bg-white border border-[#EFEFEF] hover:bg-[#FFF1F233] hover:border-[#CB2129] rounded-[16px] p-6 duration-1000 ease-in-out">
                  <p className="text-[#101010] font-normal text-sm md:text-lg">
                    It works really wonders in the hybrid culture. No echo and
                    seamless integration with the current workflow. Love this
                    application.
                  </p>
                  <div className="flex items-center mt-4">
                    <div className="flex-none">
                      <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                        <img
                          src={gallaryImg}
                          alt=""
                          className="aspect-[1/1] rounded-full object-cover"
                        />
                      </div>
                    </div>
                    <div className="flex-1 ml-3">
                      <h4 className="text-[#101010] font-semibold text-lg md:text-xl">
                        Beth Wilson
                      </h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white border border-[#EFEFEF] hover:bg-[#FFF1F233] hover:border-[#CB2129] rounded-[16px] p-6 duration-1000 ease-in-out">
                  <p className="text-[#101010] font-normal text-sm md:text-lg">
                    It works really wonders in the hybrid culture. No echo and
                    seamless integration with the current workflow. Love this
                    application.
                  </p>
                  <div className="flex items-center mt-4">
                    <div className="flex-none">
                      <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                        <img
                          src={gallaryImg}
                          alt=""
                          className="aspect-[1/1] rounded-full object-cover"
                        />
                      </div>
                    </div>
                    <div className="flex-1 ml-3">
                      <h4 className="text-[#101010] font-semibold text-lg md:text-xl">
                        Beth Wilson
                      </h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white border border-[#EFEFEF] hover:bg-[#FFF1F233] hover:border-[#CB2129] rounded-[16px] p-6 duration-1000 ease-in-out">
                  <p className="text-[#101010] font-normal text-sm md:text-lg">
                    It works really wonders in the hybrid culture. No echo and
                    seamless integration with the current workflow. Love this
                    application.
                  </p>
                  <div className="flex items-center mt-4">
                    <div className="flex-none">
                      <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                        <img
                          src={gallaryImg}
                          alt=""
                          className="aspect-[1/1] rounded-full object-cover"
                        />
                      </div>
                    </div>
                    <div className="flex-1 ml-3">
                      <h4 className="text-[#101010] font-semibold text-lg md:text-xl">
                        Beth Wilson
                      </h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white border border-[#EFEFEF] hover:bg-[#FFF1F233] hover:border-[#CB2129] rounded-[16px] p-6 duration-1000 ease-in-out">
                  <p className="text-[#101010] font-normal text-sm md:text-lg">
                    It works really wonders in the hybrid culture. No echo and
                    seamless integration with the current workflow. Love this
                    application.
                  </p>
                  <div className="flex items-center mt-4">
                    <div className="flex-none">
                      <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                        <img
                          src={gallaryImg}
                          alt=""
                          className="aspect-[1/1] rounded-full object-cover"
                        />
                      </div>
                    </div>
                    <div className="flex-1 ml-3">
                      <h4 className="text-[#101010] font-semibold text-lg md:text-xl">
                        Beth Wilson
                      </h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}

          {/* --------Artists worked with------------ */}
          <div className="pt-10">
            <Artist artists={artists} props={props} />
          </div>
        </div>
      </section>
    </>
  );
};

export default OrganiserDetails;
