import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"

const DatePicker = ({ validation, name, label, isRequire, className, placeholderText, selectedDate }) => {
  const [startDate, setStartDate] = useState('');
  const handleDateChange = (date) => {
    setStartDate(date);
    validation.setFieldValue(name, moment(new Date(date)).format('DD-MM-YYYY'));
    const today = new Date();
    const birthDate = new Date(date);
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      calculatedAge--;
    }
    validation.setFieldValue("age", calculatedAge)
  }

  return (
    <div>
      <label className="mb-[10px] block text-base font-medium text-[#101010]">
        {label}
        {isRequire &&
          <span className="text-primary">*</span>
        }
      </label>
      <div className="relative">
        <ReactDatePicker selected={startDate}
          onChange={(date) => handleDateChange(date)}
          name={name}
          // value={startDate}
          value={selectedDate ? selectedDate : startDate}

          placeholderText={placeholderText ? placeholderText : "Select date"}
          dateFormat="dd/MM/yyyy"
          showYearDropdown
          showMonthDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}  // Number of years to display in the dropdown
          maxDate={new Date()}
          className={className ? className : "relative text-[#131313] text-base bg-white border border-[#E7E7E7] font-normal font-body rounded-lg block w-full py-2.5 px-3"}


        />
        {validation.touched["dates"] && validation.errors["dates"] ? (
          <div className="text-red-500">{validation.errors["dates"]}</div>
        ) : null}
      </div>
    </div >
  );
};

DatePicker.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default DatePicker;
