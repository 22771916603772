import React from 'react';

const RegText = ({
    field,
    handleChangeField,
    formErrors
}) => {

    return (

        <>
            <label className="mb-[10px] block text-base font-medium text-[#101010]">
                {field.display_name}
                {field.is_required == 'Yes' && <span className="text-[#CB2027]">*</span>}
            </label>
            <input
                name={field.field_name}
                type="text"
                value={field.value}
                onChange={
                    (e) => {
                        handleChangeField(e, field)
                    }
                }
                className={"bg-white border border-[#E7E7E7] text-[#101010] text-base font-semibold font-body  rounded-lg placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5"}
                placeholder={"Enter " + field.display_name}
            />
            {formErrors && formErrors[field.field_name] && (
                <div className="text-red-500 mt-1">{formErrors[field.field_name]} </div>
            )}
        </>

    )
}
RegText.propTypes = {

}
export default RegText
