import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import hBannerImageDefault from "../../assets/images/default/h-event-image.png";
import calendarIcon from "../../assets/light_theme_images/calender-dark.svg";
import clockIcon from "../../assets/light_theme_images/clock-dark.svg";
import passIcon from "../../assets/light_theme_images/pass-icon.svg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

const PassDetails = ({
  passDetails,
  textColor,
  backGroundColor,
  bannerImage,
  primaryColor,
  secondaryColor,
}) => {
  return (
    <>
      {!isEmpty(bannerImage) && bannerImage.length > 0 && (
        <Swiper
          cssMode={true}
          mousewheel={true}
          keyboard={true}
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
          className="mySwiper gallary-slider pass-ticket-slider !pb-5"
        >
          {bannerImage && (
            <>
              {bannerImage.map((item, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={item ? item : hBannerImageDefault}
                    alt=""
                    className="rounded-md aspect-[2.336/1] w-full"
                  />
                </SwiperSlide>
              ))}
            </>
          )}
        </Swiper>
      )}
      <div className="p-[15px]">
        <div>
          <h1
            style={{ color: textColor }}
            className={`text-base text-[${textColor}] font-semibold capitalize`}
          >
            {passDetails && passDetails.event && passDetails.event.name}
            {/* {passDetails && passDetails.event && passDetails.event.tagline
              ? " : "
              : ""}
            {passDetails && passDetails.event && passDetails.event.tagline} */}
          </h1>
          <h2
            style={{
              background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            className="font-bold text-xl uppercase break-all mt-1"
          >
            {passDetails.event_pass && passDetails.event_pass.name}
          </h2>
        </div>
      </div>
    </>
  );
};
PassDetails.propTypes = {};
export default PassDetails;
