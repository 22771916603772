import React, { useEffect } from "react";
import banner4 from "../../assets/images/terms-condition-banner.png";
import SeoDetails from "../../seo/SeoDetails";
import { Link } from "react-router-dom";
function TermsCondition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SeoDetails
        title="mepass | Terms & Condition"
        description="mepass | Terms & Condition"
      />

      <section className="bg-white pt-14 pb-14">
        <div className="container">
          <h1 className="text-xl md:text-4xl text-[#101010] font-semibold text-center mb-8">
            Terms and Conditions
          </h1>
          <h2 className="text-base md:text-3xl text-[#101010] font-semibold">
            User terms & conditions
          </h2>
          <p className="text-sm md:text-xl text-[#101010] font-normal mt-2">
            Welcome to Mepass, your go-to platform for finding and booking
            tickets to exciting events! By using Mepass, you agree to these
            Terms & Conditions, so please read them carefully;
          </p>
          <div>
            <ol className="list-decimal list-inside	 mt-4">
              <li className="text-sm md:text-xl text-[#101010] font-semibold">
                Account Creation and Use
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  You'll be required to create an account on Mepass to access
                  its features.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Make sure your account details are accurate and up-to-date.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  You can link social media accounts for easier login and event
                  discovery.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Event-Specific Terms
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Each event listed on Mepass may have its own specific terms.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  You are responsible for reviewing and agreeing to the terms of
                  each event you want to attend.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Ticket Purchase and Fees
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Tickets for events on Mepass can be purchased through our
                  platform.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Some purchases may include convenience or handling fees, which
                  will be clearly shown during checkout.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Refunds
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Refunds are managed based on the policy of the event organiser
                  or merchant.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  If an event is cancelled, Mepass will help process refunds
                  according to the organiser’s policy.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  For tickets bought directly from event merchants, not through
                  Mepass, any refund requests must be handled directly with the
                  merchant.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Ticket Sharing
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  You can share purchased tickets with others using Mepass’s
                  sharing feature.
                </li>
              </ul>
              <li className="text-sm  md:text-xl text-[#101010] font-semibold mt-2">
                Ticket History and Pass Management
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  You can view your ticket history and manage your active and
                  past passes within the platform.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Entry and Identification
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  For entry to some events, you may need to show your ticket
                  along with ID.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  We recommend carrying a digital or printed copy of your
                  ticket, especially in areas with limited network access.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Intellectual Property
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  All content, information, and branding on Mepass are owned by
                  Mepass and protected by copyright and trademark laws.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Dispute Resolution
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  If any issues arise related to these Terms & Conditions, they
                  will be resolved through arbitration in Ahmedabad, India.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Changes to Terms
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Mepass reserves the right to modify or update these Terms &
                  Conditions at any time.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Contact Us
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Have questions? Reach out at{" "}
                  <Link
                    to="https://www.mepass.in/contact-us"
                    target="_blank"
                    className="text-[#5937d3] underline"
                  >
                    mepass.in/contact-us.
                  </Link>
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Privacy Policy
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  For details on how we handle your personal data, visit our
                  Privacy Policy at{" "}
                  <Link
                    to="http://mepass.in/privacy-policy"
                    target="_blank"
                    className="text-[#5937d3] underline"
                  >
                    mepass.in/privacy-policy.
                  </Link>
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Ticket Transfers and Resale
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Mepass currently does not officially support the resale or
                  transfer of tickets to third parties or individuals through
                  its platform.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Tickets purchased through Mepass are intended for the original
                  buyer’s use only, unless explicitly stated otherwise by the
                  event organiser.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  If you share tickets via Mepass’s designated sharing feature,
                  please do so responsibly, as Mepass is not liable for issues
                  arising from such transfers.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Notifications and Communication
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  By creating an account on Mepass, users agree to receive
                  notifications and communications related to their account
                  activity, event updates, and promotional offers. Where
                  applicable, users may choose to opt out of promotional
                  communications through their account settings.
                </li>
              </ul>
            </ol>
          </div>
          <h3 className="text-base md:text-3xl text-[#101010] font-semibold mt-7">
            Merchant terms and conditions
          </h3>
          <p className="text-sm md:text-xl text-[#101010] font-normal mt-2">
            Welcome to Mepass Merchant Services! These Terms & Conditions
            explain how to use the Mepass platform as a merchant. By using
            Mepass, you agree to follow these terms;
          </p>
          <div>
            <ol className="list-decimal list-inside mt-4">
              <li className="text-sm md:text-xl text-[#101010] font-semibold">
                Introduction
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  By creating a merchant account on Mepass, you agree to use the
                  platform responsibly and in line with these terms.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
                Merchant Responsibilities
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                Merchants must provide accurate and current information when creating an account.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                Mepass will use merchant information in line with our Privacy Policy for internal purposes.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                Merchants are solely responsible for using Mepass to manage and host events.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
              Event Hosting
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                Merchants must list accurate, up-to-date event details and ensure compliance with all relevant terms.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                Merchants are responsible for any changes to event details, pricing, or features.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                Ticket prices and offers are decided by merchants; Mepass is not liable for any discounts or offers provided by merchants.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
              Promotion and Sales
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                Mepass may promote events based on performance or special requests for featuring.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                Merchants are responsible for managing event ticket sales, including online and manual channels.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                Only authorised personnel should access the Mepass merchant account.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
              Financial Transactions
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Convenience fees and internet handling charges may apply based
                  on Mepass pricing plans.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Merchants can purchase additional services from the Mepass
                  marketplace as needed.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Government taxes will apply to all fees and services.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Convenience fees, GST, and other applicable charges will be
                  deducted from ticket sales. All payments to Mepass are
                  non-refundable.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Mepass may deduct pending fees from future ticketing revenue.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
              Data Usage and Protection
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Mepass owns data from ecommerce and manual ticketing sales,
                  though merchants may use their own data for marketing.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Merchants cannot extract data from Mepass software to sell to
                  third parties; data use is limited to marketing purposes only.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Mepass reserves the right to change data visibility and
                  availability at any time.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
              Intellectual Property
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Mepass software and platform tools are for authorised event
                  hosting only. Copying or sharing the software is strictly
                  prohibited.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Merchants may use the Mepass logo for promotional purposes
                  with prior written approval.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
              Termination and Refunds
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Mepass may terminate a merchant’s account if these terms are
                  violated.
                </li>
                <li className="text-sm text-[#101010] font-normal">
                  Merchants are responsible for refunding customers if an event
                  is cancelled or postponed.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
              Updates to Terms
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Mepass may update these Terms & Conditions at any time.
                  Merchants are encouraged to review them regularly.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
              Event Quality and Content Compliance
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  Mepass reserves the right to review and remove any event
                  listings that do not meet our quality standards or that
                  contain inappropriate content. Events that do not comply with
                  Mepass’s Terms & Conditions may be rejected or suspended from
                  the platform at our discretion.
                </li>
              </ul>
              <li className="text-sm md:text-xl text-[#101010] font-semibold mt-2">
              Advance Minimum Guarantee Terms
              </li>
              <ul className="list-disc pl-[44px] md:pl-[60px] mt-2">
                <li className="text-sm text-[#101010] font-normal">
                  If an Organizer requests an advance minimum guarantee for
                  event ticket sales, they must provide the following
                  documentation:
                </li>
                <ol className="list-decimal pl-6">
                  <li className="text-sm text-[#101010] font-semibold">
                    Venue Booking Confirmation:
                    <span className="font-normal ml-1">
                      {" "}
                      A formal letter from the venue authority confirming the
                      event booking.
                    </span>
                  </li>
                  <li className="text-sm text-[#101010] font-semibold">
                    Artist Confirmation:
                    <span className="font-normal ml-1">
                      An official email or written confirmation from the artist
                      or performer confirming their participation in the event.
                    </span>
                  </li>
                </ol>
                <li className="text-sm text-[#101010] font-normal">
                  Once the required documentation is submitted, our team will
                  review the details and assess the event's potential. Based on
                  this analysis, we will provide the Organizer with the maximum
                  budget available for the event.
                </li>
              </ul>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsCondition;
