import TagManager from 'react-gtm-module'

export const pushClickEvent = (buttonClick, pageName, buttonText, eventDetails) => {
    const tagManagerArgs = {
        dataLayer: {
            event: buttonClick,
            eventCategory: pageName,
            eventAction: 'click',
            eventLabel: buttonText,
            eventValue: 1,
            eventNonInteraction: false,
            // pass name and slug of the event
            eventName: eventDetails.name,
            eventSlug: eventDetails.slug,
        },
        dataLayerName: 'mepassDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)
}

// make function for view event
export const pushViewEvent = (viewEvent, pageName, buttonText, eventDetails) => {
    const tagManagerArgs = {
        dataLayer: {
            event: viewEvent,
            eventCategory: pageName,
            eventAction: 'view',
            eventLabel: buttonText,
            eventValue: 1,
            eventNonInteraction: false,
            eventName: eventDetails.name,
            eventSlug: eventDetails.slug,
            // pass event city if available
            eventCity: eventDetails.city,
        },
        dataLayerName: 'mepassDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)
}

// make function for select location
export const pushSelectLocation = (selectLocation, pageName, buttonText) => {
    const tagManagerArgs = {
        dataLayer: {
            event: selectLocation,
            eventCategory: pageName,
            eventAction: 'select',
            eventLabel: buttonText,
            eventValue: 1,
            eventNonInteraction: false,
        },
        dataLayerName: 'mepassDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)
}

// make a functiona of add or remove to cart click
export const pushAddToCart = (addToCart, pageName, action, buttonText, eventDetails) => {
    const tagManagerArgs = {
        dataLayer: {
            event: addToCart,
            eventCategory: pageName,
            eventAction: action ,
            eventLabel: buttonText,
            eventValue: 1,
            eventNonInteraction: false,
            passCount: [
                {
                    passName: eventDetails.name,
                    passType: eventDetails.pass_type_slug,
                    passQuantity: eventDetails.quantity,
                }
            ],
        },
        dataLayerName: 'mepassDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)
}

export const pushMakePayment = (makePayment, pageName, buttonText, totalPasses, eventDate, totalAmount) => {
    const tagManagerArgs = {
        dataLayer: {
            event: makePayment,
            eventCategory: pageName,
            eventAction: 'click',
            eventLabel: buttonText,
            eventValue: 1,
            eventNonInteraction: false,
            totalPasses: totalPasses.map(pass => ({
                name: pass.name,
                passType: pass.pass_type_slug,
                quantity: pass.quantity,
            })),
            totalAmount: totalAmount,
        },
        dataLayerName: 'mepassDataLayer'
    };

    // Conditionally add eventDate if it exists
    if (eventDate) {
        tagManagerArgs.dataLayer.eventDate = eventDate;
    }

    TagManager.dataLayer(tagManagerArgs);
};


// make a function for thank you 
export const pushThankYou = (thankYou, pageName, buttonText, totalPasses, totalAmount) => {
    const tagManagerArgs = {
        dataLayer: {
            event: thankYou,
            eventCategory: pageName,
            eventAction: 'View',
            eventLabel: buttonText,
            eventValue: 1,
            eventNonInteraction: false,
            totalPasses: totalPasses.map(pass => ({
                name: pass.passName,
                passAmount: pass.pass_amount,
            })),
            totalAmount: totalAmount,
        },
        dataLayerName: 'mepassDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
}
