import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { isEmpty } from 'lodash';
import headerLogo from '../../assets/light_theme_images/logo.svg';
import detectLocation from '../../assets/light_theme_images/detect-location.svg';
import popularCity from '../../assets/light_theme_images/popular-city-icon.svg';
import {
  AhmedabadSvg,
  BengaluruSvg,
  ChandigarhSvg,
  DelhiSvg,
  GoaSvg,
  HyderabadSvg,
  JaipurSvg,
  KolkataSvg,
  MumbaiSvg,
  PuneSvg,
  SuratSvg,
  UdaipurSvg,
} from '../../components/svgExports';
import { pushClickEvent } from '../../seo/gtevents';
import { pushSelectLocation } from '../../seo/gtevents';
import user from '../../assets/images/default/user-image.png';

const SelectCityModal = ({
                           isShowCityModal,
                           setShowCityModal,
                           cityList,
                           handleOnSelect,
                           props,
                           detectYourLocation,
                           errorLocation,
                           loaderLocation,
                           setShowSearchModal,
                           authUserDetails,
                         }) => {
  const [cmsPageNotFoundError, setCmsPageNotFoundError] = useState('');
  const [filteredCityList, setFilteredCityList] = useState(cityList);
  const [cityValue, setCityValue] = useState('');
  const [viewMoreCityModal, setViewMoreCityModal] = useState(false);
  const filterInCityList = (searchCity) => {
    setCityValue(searchCity);
    const filteredCityList = cityList.filter((city) => {
      return city.name.toLowerCase().includes(searchCity.toLowerCase());
    });
    setFilteredCityList(filteredCityList);
  };

  useEffect(() => {
    let myCurrentLocationMePass = JSON.parse(
      localStorage.getItem('myCurrentLocationMePass'),
    );
    // if (myCurrentLocationMePass && myCurrentLocationMePass.city) {
    //   setCityValue(myCurrentLocationMePass.city)
    // }
  }, [localStorage.getItem('myCurrentLocationMePass')]);

  return (
    <>
      <div className="fixed z-30 overflow-y-auto top-0 md:top-24 w-full left-0">
        {loaderLocation && (
          <div className="fixed inset-0 z-50 bg-[#FAFAFA] bg-opacity-80 flex items-center justify-center">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        )}
        <div
          className="flex md:items-center justify-center min-h-screen md:pt-4 md:px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center bg-white md:rounded-[32px] text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] lg:w-[800px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {/* <div className="bg-white md:px-4 pt-1">
              <div className="flex items-center justify-end">
                <button
                  type="button"
                  className="pt-3 px-4"
                  onClick={() => setShowCityModal(false)}
                >
                  <i className="fas fa-times text-2xl text-[#5A5A5A]"
                  onClick={() => { pushClickEvent("Close City Modal", "location_cancel", "Cancel Location ", {}); }}
                  ></i>
                </button>
              </div>
            </div> */}
            <div className="bg-white py-4 px-4 border-b-2 border-[#f7f7f7] block md:hidden">
              <div className="flex items-center justify-end">
                <div className="flex-1">
                  <div className="w-[110.63px]">
                    <img
                      className="w-auto"
                      src={headerLogo}
                      alt="Your Company"
                    />
                  </div>
                </div>
                <div className="flex-none">
                  <div className="flex gap-2.5 items-center">
                    {/* <div className="relative">
                      <div onClick={() => setShowSearchModal(true)}
                        className="size-[39px] flex items-center justify-center cursor-pointer search-border-liner-gradient">
                        <i class="fa-solid fa-magnifying-glass text-[#101010]"></i>
                      </div>
                    </div> */}
                    {localStorage.getItem('publicMePassUser') ? (
                      <Link to="/user-profile">
                        {!isEmpty(authUserDetails.profile_image_path) ? (
                          <img
                            src={authUserDetails.profile_image_path || logo}
                            className="size-[39px] xl:size-12 rounded-full"
                            alt="Profile"
                          />
                        ) : (
                          <>
                            {!isEmpty(authUserDetails.name) ? (
                              <div
                                className="flex items-center justify-center h-10 xl:h-12 w-10 xl:w-12 bg-[#FFDEDE] rounded-full text-primary">
                                {authUserDetails.name.charAt(0)}
                              </div>
                            ) : (
                              <img
                                src={user}
                                className="h-10 w-10 rounded-full"
                                alt="Default User"
                              />
                            )}
                          </>
                        )}
                      </Link>
                    ) : (
                      <Link
                        to="/login"
                        className="btn-liner-gradient text-[12px] md:text-base text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded-lg flex items-center justify-center"
                        state={{ is_login_page: 'Yes' }}
                      >
                        Login / Register
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-3 md:p-[30px] h-screen md:h-full">
              <div className="flex gap-3 md:gap-5">
                <div className="flex-1">
                  <div className="relative">
                    {/* City List in Search and Select */}
                    <input
                      type="text"
                      placeholder="search city or events"
                      value={cityValue}
                      onChange={(e) => filterInCityList(e.target.value)}
                      className="w-full bg-white border border-[#5D5D5D] rounded-lg px-2 md:px-4 py-4 text-[#101010] text-sm md:text-base font-normal outline-none  focus:border-[#5D5D5D] placeholder:text-[#5D5D5D]"
                    />
                    <span className="bg-white p-2 absolute top-1/2 right-1 md:right-3 -translate-y-1/2">
                      <i className="fa fa-search text-[#5D5D5D] text-base"></i>
                    </span>
                  </div>
                </div>
                <div className="flex-none">
                  <button
                    className="detect-my-location-border-liner-gradient px-2 md:px-4 py-4 text-tittle-liner-gradient-color text-sm md:text-base font-semibold"
                    onClick={() => detectYourLocation()}
                  >
                    <img
                      src={detectLocation}
                      alt="logo"
                      className="inline-block mr-1 md:mr-3"
                    />
                    Detect my location
                  </button>
                </div>
              </div>
              {errorLocation && (
                <div className="text-red-500 mt-1">{errorLocation} </div>
              )}
              {cmsPageNotFoundError && (
                <div className="text-dark mt-1">{cmsPageNotFoundError} </div>
              )}
              <div className="max-h-[70vh] md:max-h-[57vh] overflow-auto mt-10">
                <div className="text-tittle-liner-gradient-color text-base font-semibold text-center mb-10">
                  <img
                    src={popularCity}
                    alt="logo"
                    className="inline-block mr-3"
                  />
                  Popular Cities
                </div>
                <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 md:gap-6 mb-10 items-end">
                  {!isEmpty(filteredCityList) &&
                    filteredCityList.map((city, indexCity) => (
                      <>
                        {city.image == 'Yes' && (
                          <div
                            key={city.name + indexCity}
                            className="text-center cursor-pointer hover-container group"
                            onClick={() => {
                              city.cmspage == 'Yes'
                                ? handleOnSelect(city)
                                : props.router.navigate(
                                  `/city/${city.name}/events`,
                                );
                              setShowCityModal(false);
                              localStorage.setItem(
                                'publicUserSelectMePassCity',
                                city.name,
                              );
                              pushSelectLocation(
                                'Select City',
                                'Select your City',
                                city.name,
                              );
                            }}
                          >
                            <div className="w-[72px] h-[72px] m-auto flex flex-col justify-end items-center">
                              {city.name === 'Ahmedabad' ? (
                                <AhmedabadSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Surat' ? (
                                <SuratSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Delhi' ? (
                                <DelhiSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Chandigarh' ? (
                                <ChandigarhSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Udaipur' ? (
                                <UdaipurSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Jaipur' ? (
                                <JaipurSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Mumbai' ? (
                                <MumbaiSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Pune' ? (
                                <PuneSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Bengaluru' ? (
                                <BengaluruSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Goa' ? (
                                <GoaSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Hyderabad' ? (
                                <HyderabadSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : city.name === 'Kolkata' ? (
                                <KolkataSvg colouring="group-hover:fill-[#CB2129]" />
                              ) : (
                                ''
                              )}
                            </div>
                            <p
                              className="text-[#101010] text-sm md:text-base font-medium capitalize break-all mt-[3px] group-hover:text-red-500">
                              {city.name}
                            </p>
                          </div>
                        )}
                      </>
                    ))}
                </div>
                <div className="text-tittle-liner-gradient-color text-base font-semibold text-center mb-10">
                  <img
                    src={popularCity}
                    alt="logo"
                    className="inline-block mr-3"
                  />
                  Other Cities
                </div>
                <div className="grid grid-cols-3 lg:grid-cols-5 gap-6 mb-10">
                  {!isEmpty(filteredCityList) &&
                    filteredCityList.map((city, indexCity) => (
                      <>
                        {city.image == 'No' && (
                          <>
                            {viewMoreCityModal && (
                              <div
                                key={city.name + indexCity}
                                className="flex flex-col gap-4"
                              >
                                <p
                                  onClick={() => {
                                    city.cmspage == 'Yes'
                                      ? handleOnSelect(city)
                                      : props.router.navigate(
                                        `/city/${city.name}/events`,
                                      );
                                    setShowCityModal(false);
                                  }}
                                  className={
                                    props.router.params.city_name === city.name
                                      ? 'text-[#CB2129] text-sm md:text-base font-medium inline capitalize cursor-pointer break-all'
                                      : 'text-[#101010] text-sm md:text-base font-medium inline capitalize cursor-pointer break-all'
                                  }
                                >
                                  {city.name}
                                </p>
                              </div>
                            )}
                            {!viewMoreCityModal && indexCity < 25 && (
                              <div
                                key={city.name + indexCity}
                                className="flex flex-col gap-4"
                              >
                                <p
                                  onClick={() => {
                                    city.cmspage == 'Yes'
                                      ? handleOnSelect(city)
                                      : props.router.navigate(
                                        `/city/${city.name}/events`,
                                      );
                                    setShowCityModal(false);
                                  }}
                                  className={
                                    props.router.params.city_name === city.name
                                      ? 'text-[#CB2129] text-sm md:text-base font-medium inline capitalize cursor-pointer break-all'
                                      : 'text-[#101010] text-sm md:text-base font-medium inline capitalize cursor-pointer break-all'
                                  }
                                >
                                  {city.name}
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ))}
                </div>
                {filteredCityList.length > 25 && !viewMoreCityModal && (
                  <div className="text-center mb-4">
                    <button
                      onClick={() => setViewMoreCityModal(true)}
                      className="text-[#CB2129] text-center font-semibold text-sm inline-block"
                    >
                      View more cities
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectCityModal;
