import React, { useState, useEffect, useCallback } from 'react';
import logo2 from '../../assets/light_theme_images/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import googleIcon from '../../assets/light_theme_images/google.svg';
import instagramIcon from '../../assets/light_theme_images/instagram-icon.svg';
import Text from '../Common/Text';
import api from '../../constants/api';
import regex from '../../constants/regex';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUpdateNewData,
  getData,
  loginUser,
  clearData,
} from '../../store/AppMaster/actions';
import labelConstant from '../../constants/label';
import OTP from '../Common/OTP';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { messaging, getToken } from '../../firebase';
import { useGoogleLogin } from '@react-oauth/google';

const LoginPopup = ({ isVisible, toggleLoginModal, props }) => {
  if (!isVisible) return null;
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  console.log(loading);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes = 120 seconds
  const [showButton, setShowButton] = useState(false);
  const dispatch = useDispatch();
  const [fcmToken, setFcmToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerifyOnSubmit = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return '';
    }
    const token = await executeRecaptcha('submit');
    return token;
  };
  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });
        console.log('FCM Token:', token);
        setFcmToken(token);
        // Save the token to your backend or use it as needed
      } else {
        console.log('Notification permission denied');
      }
    } catch (error) {
      console.error('Error getting notification token:', error);
    }
  };
  useEffect(() => {
    requestNotificationPermission();
  }, []);
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setShowButton(true);
    }
  }, [timeLeft]);
  const [location, setLocation] = useState({
    city: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        // Fetch location data from a free API
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        if (data.city && data.country_name) {
          setLocation({
            city: data.city ? data.city : '',
            state: data.region ? data.region : '',
            country: data.country_name ? data.country_name : '',
            latitude: data.latitude ? data.latitude : '',
            longitude: data.longitude ? data.longitude : '',
          });
        } else {
          setError('Could not retrieve location.');
        }
      } catch (err) {
        setError('Failed to fetch location.');
      }
    };

    fetchLocation();
  }, []);

  // Formik initialization
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobile: '',
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .required('Please Enter Your Mobile')
        .length(10, 'Mobile number should be exactly 10 digits'),
    }),
    onSubmit: (values) => {
      setShowOtpForm(true);

      setMobileNumber(values.mobile);
      dispatch(getData({ mobile: values.mobile }, '', api.sendOtp));
    },
  });

  const validationOtp = useFormik({
    enableReinitialize: true,

    initialValues: {
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      otp5: '',
      otp6: '',
      mobile: mobileNumber,
    },
    validationSchema: Yup.object({
      otp1: Yup.string().required('Please Enter Your OTP'),
      otp2: Yup.string().required('Please Enter Your OTP'),
      otp3: Yup.string().required('Please Enter Your OTP'),
      otp4: Yup.string().required('Please Enter Your OTP'),
      otp5: Yup.string().required('Please Enter Your OTP'),
      otp6: Yup.string().required('Please Enter Your OTP'),
    }),
    onSubmit: async (values) => {
      const newToken = await handleReCaptchaVerifyOnSubmit();
      const requestData = {
        mobile: validation.values.mobile,
        otp:
          values.otp1 +
          values.otp2 +
          values.otp3 +
          values.otp4 +
          values.otp5 +
          values.otp6,
        gRecaptchaResponse: newToken,
        push_notification_id: fcmToken,
        device_type: 'web',
        city: location && location.city ? location.city : '',
        state: location && location.state ? location.state : '',
        country: location && location.country ? location.country : '',
        latitude: location && location.latitude ? location.latitude : '',
        longitude: location && location.longitude ? location.longitude : '',
      };
      dispatch(loginUser(requestData, props.router.navigate, api.login));
      window.scrollTo(0, 0);
    },
  });

  const closeModal = () => {
    setShowOtpForm(false);
    toggleLoginModal(false);
  };
  const handleResendOtp = (e) => {
    e.preventDefault();
    dispatch(addUpdateNewData({ mobile: mobileNumber }, '', api.sendOtp));
    setShowButton(false); // Hide the button
    setTimeLeft(120); // Reset the timer to 2 minutes
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };
  const googleLoginCall = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log('Login Success:', tokenResponse);
      // Use tokenResponse.access_token to make API requests
      const requestData = {
        access_token: tokenResponse.access_token,
      };
      dispatch(loginUser(requestData, props.router.navigate, api.googlSignup));
      window.scrollTo(0, 0);
    },
    onError: (error) => {
      console.error('Login Failed:', error);
    },
  });
  if (!loading && validation.isSubmitting) {
    validation.isSubmitting = false;
  }
  if (!loading && validationOtp.isSubmitting) {
    validationOtp.isSubmitting = false;
  }
  return (
    <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
      {loading && (
        <div className="fixed inset-0 z-50 bg-[#FAFAFA] bg-opacity-80 flex items-center justify-center">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className={`inline-block align-center bg-white border border-[#4D4444] rounded-[12px] md:rounded-[32px] text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] lg:w-[55%] 2xl:w-[38%]`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 md:px-14 py-10 md:py-14 relative">
            <button
              type="button"
              className="py-2 px-2 absolute top-3 right-5"
              onClick={closeModal}
            >
              <i className="fas fa-times text-2xl"></i>
            </button>
            <div className="text-center">
              <div className="w-[163.63px] m-auto">
                <img
                  src={logo2}
                  alt=""
                  className="m-auto mb-10 inline-block"
                />
              </div>
              {showOtpForm ? (
                <div className="flex flex-col gap-2 mt-0">
                  <h2 className="text-[#101010] text-3xl font-semibold">
                    OTP Verification
                  </h2>
                  <p className="text-[#101010] text-base font-normal">
                    Enter the OTP sent to your mobile to verify and continue
                    your journey with us!
                  </p>
                </div>
              ) : (
                <div className="flex flex-col gap-2 mt-0">
                  <h2 className="text-[#101010] text-3xl font-semibold">
                    Welcome back to Mepass!
                  </h2>
                  <p className="text-[#101010] text-base font-normal">
                    Join our vibrant community and turn every moment into an
                    unforgettable memory
                  </p>
                </div>
              )}
            </div>
            {!showOtpForm ? (
              <div className="mt-10">
                <div className="mb-4">
                  <Text
                    validation={validation}
                    name="mobile"
                    label="Mobile Number"
                    isRequire={true}
                    placeholder="Mobile Number"
                    regexCompare={regex.MOBILE}
                    maxLength={10}
                    className="bg-[#F9F9F9] text-[#CB2129] text-base font-semibold rounded-[4px] placeholder:text-[#616161] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5"
                  />
                </div>
                <button
                  onClick={validation.handleSubmit}
                  disabled={validation.isSubmitting ? true : false}
                  className="btn-liner-gradient text-white text-base font-medium md:py-4 py-2 px-8 rounded-[5px] md:rounded-[10px] w-full mt-2"
                >
                  Request OTP
                </button>
              </div>
            ) : (
              <div className="mt-10">
                <div className="flex justify-center gap-2 md:gap-5">
                  <OTP
                    validation={validationOtp}
                    name="otp"
                    label={labelConstant.OTP}
                    isRequire={true}
                  />
                </div>
                <button
                  className="btn-liner-gradient text-white text-base font-medium md:py-4 py-2 px-8 rounded-[5px] md:rounded-[10px] w-full mt-7"
                  onClick={validationOtp.handleSubmit}
                  disabled={validationOtp.isSubmitting ? true : false}
                >
                  Verify
                </button>
                <div className="text-center mt-7">
                  {showButton ? (
                    <button
                      onClick={handleResendOtp}
                      className="text-[#101010] text-base font-medium"
                    >
                      Didn’t receive OTP?{' '}
                      <span className="font-bold text-[#CB2129]">
                        Resend OTP
                      </span>
                    </button>
                  ) : (
                    <p className="text-[#101010] text-base font-body font-medium">
                      Resend OTP in {formatTime(timeLeft)}
                    </p>
                  )}
                </div>
              </div>
            )}
            {showOtpForm ? (
              <></>
            ) : (
              <>
                <div className="text-center mt-7">
                  <p className="text-[#101010] text-lg font-normal font-body">
                    Don’t have an account?
                    <Link to="/sign-up" className="text-[#CB2129] font-bold">
                      Sign up
                    </Link>
                  </p>
                </div>
                <div className="mt-8 border-b border-[#E0E0E0] relative">
                  <div className="bg-white px-[7px] text-[#616161] text-sm font-light absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 -mt-[1px]">
                    Or use social
                  </div>
                </div>
                <div className="flex justify-center gap-1.5 md:gap-6 mt-10">
                  <button
                    onClick={() => googleLoginCall()}
                    className="socail-border-liner-gradient text-[#101010] text-[10px] md:text-base font-normal py-1 md:py-3 px-1.5 md:px-2 xl:px-4"
                  >
                    <img
                      src={googleIcon}
                      alt="icon"
                      className="inline-block mr-1 md:mr-2"
                    />
                    Log In with Google
                  </button>
                  {/* <button className="socail-border-liner-gradient text-[#101010] text-[10px] md:text-base font-normal py-1 md:py-3 px-1.5 md:px-2 xl:px-4">
                    <img
                      src={instagramIcon}
                      alt="icon"
                      className="inline-block mr-1 md:mr-2"
                    />
                    Log In with Instagram
                  </button> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
