import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bannerImageDefault from '../../assets/images/default/event-image.jpg';
import hBannerImageDefault from '../../assets/images/default/h-event-image.png';
import banner1 from '../../assets/images/event-new.png';
import icon from '../../assets/images/small/Star.png';
import icon4 from '../../assets/images/small/percentage-red.png';
import icon2 from '../../assets/images/small/percentage-yellow.png';
import icon3 from '../../assets/images/small/small-star.png';
import Artist from '../../components/Event/Details/Artist';
import Sponsor from '../../components/Event/Details/Sponsor';
import calendarIcon from '../../assets/light_theme_images/calender-dark.svg';
import clockIcon from '../../assets/light_theme_images/clock-dark.svg';
import starIcon from '../../assets/light_theme_images/star-dark.svg';
import locationIcon from '../../assets/light_theme_images/location-dark.svg';
import mapImg from '../../assets/light_theme_images/map.svg';

import gallaryImg from '../../assets/light_theme_images/gallary-img.png';
import gallaryImg2 from '../../assets/light_theme_images/gallary-img2.png';
import gallaryImg3 from '../../assets/light_theme_images/gallary-img3.png';
import durartionIcon from '../../assets/light_theme_images/duration-hours.svg';
import carbonDirection from '../../assets/light_theme_images/carbon-direction-bear.svg';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ReactPlayer from 'react-player/youtube';
import { Link, useParams } from 'react-router-dom';
import logo from '../../assets/images/default/organiser-logo.png';
import { convertToAmPm, convertToDate } from '../../common/commonFunction';
import withRouter from '../../components/Common/withRouter';
import api from '../../constants/api';
import {
  addUpdateNewData,
  clearData,
  getData,
} from '../../store/AppMaster/actions';
import EventUpComing from '../../components/Event/Details/EventUpComing';
import SeoDetails from '../../seo/SeoDetails';
import SkeletonLoaderEventDetails from '../../components/SkeletonLoader/EventCategory/Details';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import ShareButtons from '../../components/Event/ShareButtons';
import { pushClickEvent } from '../../seo/gtevents';

const EventDetails = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  // const {merchant_id}=useState(true);
  const params = useParams();
  // console.log(params);
  const [singleEvent, setSingleEvent] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [artists, setArtists] = useState([]);
  const [isArtistDataGet, setIsArtistDataGet] = useState(true);
  const [sponsors, setSponsors] = useState([]);
  const [isSponsorDataGet, setIsSponsorDataGet] = useState(true);
  const [eventDetail, setEventDetail] = useState([]);
  const [isUpcomingEventData, setIsUpcomingEventData] = useState(true);
  const [profileData, setProfileData] = useState([]);
  const [upcomingEventDetail, setUpcomingEventDetail] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const [ratingList, setRatingList] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [videoUrl, setVideoUrl] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const mapContainerStyle = {
    width: '100%',
    height: '300px',
    borderRadius: '12px',
  };

  const dispatch = useDispatch();

  const artistDataGet = useCallback(() => {
    setIsArtistDataGet(true);
    const userDetail = JSON.parse(
      localStorage.getItem('publicMePassUserDetails'),
    );
    let requestData = {
      event_slug: props.router.params.event_slug,
      user_id: userDetail && userDetail.id ? userDetail.id : '',
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.EventArtistList));
  }, [dispatch, props.router.navigate, props.router.params.event_slug]);

  const sponsorDataGet = useCallback(() => {
    setIsSponsorDataGet(true);
    let requestData = {
      event_slug: props.router.params.event_slug,
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.EventSponsorList));
  }, [dispatch, props.router.navigate, props.router.params.event_slug]);

  const upComingEventDataGet = (merchant_company_slug) => {
    setIsUpcomingEventData(true);
    const userDetail = JSON.parse(
      localStorage.getItem('publicMePassUserDetails'),
    );
    let requestData = {
      event_slug: props.router.params.event_slug,
      user_id: userDetail && userDetail.id ? userDetail.id : '',
    };
    dispatch(clearData());
    dispatch(getData(requestData, '', api.nearestEvent));
  };
  useEffect(() => {
    if (props.router.params.event_slug) {
      setIsApiCall(0);
    }
  }, [props.router.params.event_slug]);
  useEffect(() => {
    if (isApiCall === 0) {
      setIsUpdateData(true);
      dispatch(clearData());
      const userDetail = JSON.parse(
        localStorage.getItem('publicMePassUserDetails'),
      );
      const requestData = {
        event_slug: props.router.params.event_slug,
        user_id: userDetail && userDetail.id ? userDetail.id : '',
      };
      dispatch(getData(requestData, props.router.navigate, api.EventDetails));
      artistDataGet();
      sponsorDataGet();
      setIsApiCall(1);
    }
  }, [
    isApiCall,
    dispatch,
    artistDataGet,
    sponsorDataGet,
    props.router.navigate,
    props.router.params.event_slug,
  ]);

  const convertToEmbedUrl = (url) => {
    const regex = /place\/([^/]+)\/@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const regex2 = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(regex);
    const match2 = url.match(regex2);
    if (match) {
      const latitude = match[2];
      const longitude = match[3];
      setMapCenter({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
    } else if (match2) {
      const latitude = match2[1];
      const longitude = match2[2];
      setMapCenter({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
    }
    return null;
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.eventView) && isUpdateData) {
      setSingleEvent(data.eventView);
      setRatingList(data.rating);
      if (data.eventView.gmap_link) {
        convertToEmbedUrl(data.eventView.gmap_link);
      }
      if (data.eventView.horizontal_banner_image) {
        //Separate using Comma
        let bannerImage = data.eventView.horizontal_banner_image.split(',');
        setBannerImage(bannerImage);
      }
      if (data.eventView.youtube_url) {
        setEmbedUrl(extractEmbedUrl(data.eventView.youtube_url));
        setVideoUrl(data.eventView.youtube_url);
      }
      upComingEventDataGet(data.eventView.merchant_company_slug);
      setIsUpdateData(false);
    }
    if (!isEmpty(data) && !isEmpty(data.artistList) && isArtistDataGet) {
      setArtists(data.artistList);
      setIsArtistDataGet(false);
    }
    if (!isEmpty(data) && !isEmpty(data.sponsorList) && isSponsorDataGet) {
      setSponsors(data.sponsorList);
      setIsSponsorDataGet(false);
    }
  }, [
    data,
    isUpdateData,
    isArtistDataGet,
    isSponsorDataGet,
    isUpcomingEventData,
    setSingleEvent,
    setArtists,
    setSponsors,
  ]);

  if (!isEmpty(data) && !isEmpty(data.nearestEvents) && isUpcomingEventData) {
    setUpcomingEventDetail(data.nearestEvents);
    setIsUpcomingEventData(false);
  }
  const [isAddData, setIsAddData] = useState(false);
  const addToFavorite = (eventId) => {
    setIsAddData(true);
    const newData = {
      favorite_id: eventId,
      type: 'Event',
    };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.AddFavorites),
    );
  };

  if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
    setIsAddData(false);
    setIsUpdateData(true);
    singleEvent.is_favorite = 'Yes';
  }

  // Remove favorite
  const [isRemoveData, setIsRemoveData] = useState(false);
  const removeToFavorite = (eventId) => {
    setIsRemoveData(true);
    const newData = {
      favorite_id: eventId,
      type: 'Event',
    };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.RemoveFavorites),
    );
  };
  if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
    setIsRemoveData(false);
    setIsUpdateData(true);
    dispatch(clearData());
    singleEvent.is_favorite = 'No';
  }

  const eventDateDisplay = () => {
    if (singleEvent.dates && singleEvent.dates[0]) {
      //First and Last Array Date not Same then Display Date
      if (singleEvent.dates.length > 1) {
        if (
          singleEvent.dates[0]['date'] !=
          singleEvent.dates[singleEvent.dates.length - 1]['date']
        ) {
          //First and Last Array Date in Year Same then only Date and month Display Remove Year
          if (
            singleEvent.dates[0]['date'].split('-')[2] ==
            singleEvent.dates[singleEvent.dates.length - 1]['date'].split(
              '-',
            )[2]
          ) {
            let firstDate = convertToDate(singleEvent.dates[0]['date']);
            let lastDate = convertToDate(
              singleEvent.dates[singleEvent.dates.length - 1]['date'],
            );
            return (
              firstDate.split(',')[0] +
              ', ' +
              firstDate.split(',')[1] +
              ' - ' +
              lastDate
            );
          } else {
            return (
              convertToDate(singleEvent.dates[0]['date']) +
              ' - ' +
              convertToDate(
                singleEvent.dates[singleEvent.dates.length - 1]['date'],
              )
            );
          }
        } else {
          return convertToDate(singleEvent.dates[0]['date']);
        }
      } else {
        return convertToDate(singleEvent.dates[0]['date']);
      }
    }
  };

  const getFullAddress = (date) => {
    let address = '';
    if (date['address']) {
      address = date['address'];
    }
    if (date['city']) {
      address += ', ' + date['city'];
    }
    if (date['state'] && date['state'] != date['city']) {
      address += ', ' + date['state'];
    }
    if (date['country'] && date['country'] != date['state']) {
      address += ', ' + date['country'];
    }
    return address;
  };

  const extractEmbedUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      const videoId = parsedUrl.searchParams.get('v'); // Extract video ID
      const playlistId = parsedUrl.searchParams.get('list'); // Extract playlist ID
      if (videoId) {
        let embedBase = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        if (playlistId) {
          embedBase += `&list=${playlistId}`;
        }
        return embedBase; // Construct the iframe embed URL
      }
      return null;
    } catch (error) {
      return null; // Handle invalid URLs
    }
  };

  const swiperRef = useRef(null);

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };

  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
  };

  const handleMarkerClick = () => {
    // Replace with the desired Google Maps redirection link
    window.open(singleEvent.gmap_link, '_blank');
  };

  const [swiperInstance, setSwiperInstance] = useState(null); // Store Swiper instance

  const handleReachEnd = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.stop(); // Stop autoplay when the last slide is reached
    }
  };

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <>
      <SeoDetails
        title={singleEvent.seo_title}
        description={singleEvent.tagline}
        keywords={singleEvent.keywords}
        ogImage={singleEvent.banner_image}
        schemaData={singleEvent} // pass the event data to generate schema
        artist={artists} // pass the artist data to generate schema
      />

      {((!isEmpty(bannerImage) && bannerImage.length > 0) || embedUrl) && (
        <section className="pt-6 md:pt-10">
          <div className="container">
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={1.1}
              centeredSlides={false}
              spaceBetween={40}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1.1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 1.1,
                  spaceBetween: 40,
                },
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper event-details-slider banner-slider !pb-10"
              onSwiper={setSwiperInstance} // Store Swiper instance
              onReachEnd={handleReachEnd} // Trigger when the last slide is reached// Trigger when the last slide is reached
            >
              {bannerImage && (
                <>
                  {bannerImage.map((item, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={item ? item : hBannerImageDefault}
                        alt=""
                        className="rounded-xl aspect-[99/40] w-full"
                      />
                    </SwiperSlide>
                  ))}
                  {/* {videoUrl &&
                    <SwiperSlide>
                      <ReactPlayer
                        className='react-player rounded-xl aspect-[99/40] w-full'
                        url={videoUrl}
                        playing={isVideoPlaying}
                        onPlay={handleVideoPlay}
                        onEnded={handleVideoEnd}
                      />
                    </SwiperSlide>
                  } */}
                  {embedUrl && (
                    <SwiperSlide>
                      <iframe
                        className="rounded-xl aspect-[99/40] w-full"
                        src={embedUrl}
                        allow="fullscreen"
                        allowfullscreen
                      ></iframe>
                    </SwiperSlide>
                  )}
                </>
              )}
            </Swiper>
          </div>
        </section>
      )}

      <section className="pt-2 md:pt-10">
        <div className="container">
          <div className="block md:flex gap-10">
            <div className="flex-1">
              <h1 className="text-[#101010] text-3xl md:text-[40px] font-bold">
                {singleEvent.name}
              </h1>
              <div className="block lg:flex gap-4 mt-4 md:mt-2.5">
                <div className="flex-none">
                  <div className="flex gap-1.5 mb-2 lg:mb-0">
                    <img
                      src={calendarIcon}
                      alt="img"
                      className="inline-block"
                    />
                    <p className="text-[#101010] text-base md:text-xl font-normal">
                      {eventDateDisplay()}
                    </p>
                  </div>
                </div>
                <div className="flex-none">
                  <div className="flex gap-4">
                    <div className="flex-none">
                      <div className="flex gap-1.5 mb-2 lg:mb-0">
                        <img
                          src={clockIcon}
                          alt="img"
                          className="inline-block"
                        />
                        <p className="text-[#101010] text-base md:text-xl font-normal">
                          {singleEvent &&
                          singleEvent.dates &&
                          singleEvent.dates[0] &&
                          singleEvent.dates[0]['start_time']
                            ? convertToAmPm(singleEvent.dates[0]['start_time'])
                            : ''}
                        </p>
                      </div>
                    </div>
                    {singleEvent.duration_hours && (
                      <div className="flex-none">
                        <div className="flex gap-1.5 mb-2 lg:mb-0">
                          <img
                            src={durartionIcon}
                            alt="img"
                            className="inline-block"
                          />
                          <p className="text-[#101010] text-base md:text-xl font-normal">
                            {singleEvent.duration_hours} Hours
                          </p>
                        </div>
                      </div>
                    )}
                    {/* <div className="flex-none">
                      {singleEvent.rating > 0 ? (
                        <div className="flex gap-1.5 mb-2 lg:mb-0">
                          <img
                            src={starIcon}
                            alt="img"
                            className="inline-block"
                          />
                          <p className="text-[#101010] text-base md:text-xl font-normal">
                            {singleEvent.rating ? singleEvent.rating : ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                </div>
                <div className="flex-1">
                  {singleEvent.dates &&
                  singleEvent.dates[0] &&
                  singleEvent.dates[0]['address'] ? (
                    <div className="flex items-start gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block mt-[3px]"
                      />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        {getFullAddress(singleEvent.dates[0])}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className="flex-none mt-7 md:mt-0">
              <div className="flex md:flex-col items-center md:items-end">
                {singleEvent.pass_available === true ? (
                  <Link
                    to={`/events/${singleEvent.slug}/book-tickets`}
                    onClick={() =>
                      pushClickEvent(
                        'Event Click',
                        'click_bookNow',
                        'Book Now',
                        singleEvent,
                      )
                    }
                  >
                    <button className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-4 md:px-8 rounded-lg font-body duration-1000 ease-in-out h-[54px] flex items-center justify-center">
                      Book Now
                    </button>
                  </Link>
                ) : (
                  ''
                )}
                {singleEvent.original_price > 0 && (
                  <p className="text-[#101010] text-base md:text-xl font-bold md:mt-2 ml-3 md:ml-0">
                    ₹{singleEvent.original_price}{' '}
                    <span className="font-medium">onwards</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white pt-4">
        <div className="container">
          <div className="flex flex-wrap gap-y-2 md:gap-y-0 gap-2 md:gap-x-6">
            {singleEvent.keywords && !isEmpty(singleEvent.keywords) ? (
              <>
                {singleEvent.keywords &&
                  singleEvent.keywords.split(',').map((item, index) => (
                    // <button
                    //   key={index}
                    //   className="text-white text-[16px] font-normal tracking-[.125em] py-2 px-[18px] rounded-full font-body bg-black  hover:text-secondary border border-black hover:border hover:border-secondary duration-1000 ease-in-out"
                    // >
                    //   #{item}
                    // </button>
                    <p
                      key={index}
                      className="text-[#CB2129] text-base md:text-lg font-medium py-1 md:py-2 px-2 md:px-4 "
                    >
                      #{item}
                    </p>
                  ))}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
      <section className="bg-white pt-10 pb-24">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              {singleEvent.tagline && (
                <h2 className="text-xl md:text-3xl font-medium text-[#101010] mb-8 md:leading-[48px] italic">
                  {singleEvent.tagline}
                </h2>
              )}
              {singleEvent.short_description && (
                <div className="mb-7">
                  <div className="flex gap-3">
                    <div className="flex-1">
                      <h2 className="text-xl md:text-3xl font-semibold text-[#101010] mb-4">
                        Why should you attend?
                      </h2>
                    </div>
                    <div className="flex-none">
                      <div className="hidden md:block">
                        {singleEvent.rating > 0 && (
                          <div className="bg-[#F0BA0E] rounded-full py-1 px-3 text-[#101010] flex items-center justify-center">
                            <i className="fas fa-star text-lg mr-1"></i>
                            <p className="text-xl md:text-2xl font-semibold">
                              {singleEvent.rating > 0 ? singleEvent.rating : ''}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`break-all ${
                      singleEvent.short_description &&
                      singleEvent.short_description.includes('<ul>')
                        ? 'bullet-point'
                        : singleEvent.short_description &&
                          singleEvent.short_description.includes('<ol>')
                        ? 'number-point'
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: singleEvent.short_description,
                    }}
                  />
                </div>
              )}
              {singleEvent.description && (
                <div className="mb-7">
                  <h2 className="text-xl md:text-3xl font-semibold text-[#101010] mb-4">
                    Key takeaways...
                  </h2>
                  <div
                    className={`${
                      singleEvent.description &&
                      singleEvent.description.includes('<ul>')
                        ? 'bullet-point'
                        : singleEvent.description &&
                          singleEvent.description.includes('<ol>')
                        ? 'number-point'
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: singleEvent.description,
                    }}
                  />
                </div>
              )}
            </div>
            <div>
              <div className="flex flex-col gap-10">
                <div>
                  <h3 className="text-xl lg:text-[32px] font-semibold text-[#101010] ">
                    {' '}
                    Where it’s happening?{' '}
                  </h3>
                  {singleEvent.dates &&
                  singleEvent.dates[0] &&
                  singleEvent.dates[0]['address'] ? (
                    <div className="flex gap-1.5 pt-2 md:pt-4 mb-3">
                      <p className="text-[#101010] text-xl font-normal">
                        {getFullAddress(singleEvent.dates[0])}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {singleEvent.gmap_link && (
                    <>
                      <div className="relative">
                        <LoadScript
                          googleMapsApiKey={process.env.REACT_APP_PLACE_API_KEY}
                        >
                          <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={mapCenter}
                            zoom={14}
                            options={{
                              zoomControl: false, // Disables the + and - zoom controls
                              disableDefaultUI: true, // Disables all default UI controls (optional)
                            }}
                          >
                            <Marker
                              position={mapCenter}
                              onClick={handleMarkerClick}
                            />
                          </GoogleMap>
                        </LoadScript>
                      </div>
                      <Link
                        to={singleEvent.gmap_link}
                        className="rounded-lg p-2 px-10 bg-white border border-[#E2E2E2] w-full mt-4 block text-center text-2xl font-normal"
                        target="_blank"
                      >
                        <img
                          src={carbonDirection}
                          alt="img"
                          className="mr-2 inline-block size-6"
                        />
                        Check Direction
                      </Link>
                    </>
                  )}
                </div>

                <div className="flex flex-col gap-5">
                  <h4 className="text-base md:text-2xl font-semibold text-[#101010] ">
                    Invite your tribe
                  </h4>
                  <ul className="flex gap-5">
                    <ShareButtons singleEvent={singleEvent} />
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ----Artists-Performing---- */}
          <div className="mt-8">
            <Artist artists={artists} props={props} />
          </div>
          {/* ----Gallery---- */}
          {/* <div className="mt-8">
            <h4 className="text-2xl md:text-[32px] font-semibold text-[#101010] mb-5">
             Glimpse , A sneak peek at our gallery
            </h4>
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 3.1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper gallary-slider !pb-10"
            >
              <SwiperSlide>
                <img
                  src={gallaryImg}
                  alt=""
                  className="rounded-xl aspect-[9/16] w-full"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={gallaryImg2}
                  alt=""
                  className="rounded-xl aspect-[9/16] w-full"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={gallaryImg3}
                  alt=""
                  className="rounded-xl aspect-[9/16] w-full"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={gallaryImg2}
                  alt=""
                  className="rounded-xl aspect-[9/16] w-full"
                />
              </SwiperSlide>
            </Swiper>
          </div> */}

          {/* ----People say about us---- */}
          {!isEmpty(ratingList) && (
            <div className="mt-8">
              <h4 className="text-xl md:text-[32px] font-semibold text-[#101010] mb-5">
                What people say about us
              </h4>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 2.1,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2.1,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider !pb-10"
              >
                {ratingList.map((item, index) => (
                  <SwiperSlide>
                    <div className="bg-white border border-[#EFEFEF] hover:bg-[#FFF1F233] hover:border-[#CB2129] rounded-[16px] p-3 md:p-6 duration-1000 ease-in-out flex flex-col justify-between h-full">
                      <div className="flex-1">
                        <p className="text-[#101010] font-normal text-[10px] md:text-lg">
                          {item.comments}
                        </p>
                      </div>
                      <div className="flex-none">
                        <div className="flex items-center mt-4">
                          <div className="flex-none">
                            {item.profile_image ? (
                              <div className="w-[16px] md:w-[40px] h-[16px] md:h-[40px] rounded-full overflow-hidden">
                                <img
                                  src={item.profile_image}
                                  alt=""
                                  className="aspect-[1/1] rounded-full object-cover"
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="flex-1 ml-3">
                            <h4 className="text-[#101010] font-semibold text-[10px] md:text-xl">
                              {item.user_name}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          {/* ----Event-Sponsors---- */}
          <div className="mt-8">
            <Sponsor sponsors={sponsors} props={props} />
          </div>

          {/* ----Terms & Conditions---- */}
          {!isEmpty(singleEvent.terms_and_conditions) ? (
            <>
              <div className="pt-4">
                <div
                  className="accordion-header flex items-start gap-2 cursor-pointer"
                  onClick={() => handleToggle(0)}
                >
                  <h4 className="text-base md:text-2xl font-semibold text-[#101010] flex-1">
                    Terms & Conditions
                  </h4>
                  <div className="flex-none">
                    <button className="flex items-center justify-center text-black text-base md:text-xl mt-2.5 md:mt-0">
                      <i
                        className={`fas ${
                          openIndex === 0 ? 'fa-chevron-up' : 'fa-chevron-down'
                        }`}
                      ></i>
                    </button>
                  </div>
                </div>
                {openIndex === 0 && (
                  <div className="mt-3 px-3">
                    <p className="text-sm md:text-base text-[#101010] font-normal mt-3">
                      <span
                        className={`${
                          singleEvent.terms_and_conditions &&
                          singleEvent.terms_and_conditions.includes('<ul>')
                            ? 'bullet-point'
                            : singleEvent.terms_and_conditions &&
                              singleEvent.terms_and_conditions.includes('<ol>')
                            ? 'number-point'
                            : ''
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: singleEvent.terms_and_conditions,
                        }}
                      ></span>
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            ''
          )}
          {/* ----You-may-love-these-too---- */}
          <div className="mt-8">
            <EventUpComing events={upcomingEventDetail} props={props} />
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(EventDetails);
