const apiConstant = {
  QrPassDetails: 'v1/public/qr-pass/view',
  QrSponsorList: 'v1/public/qr-pass/sponsor-view',
  QrPopupList: 'v1/public/qr-pass/popup-view',
  SplitSharePass: 'v1/public/qr-pass/split-share-pass',
  EventList: 'v1/public/event-detail/list',
  EventDetails: 'v1/public/event-detail/event-view',
  EventArtistList: 'v1/public/event-detail/artist-list',
  EventSponsorList: 'v1/public/event-detail/sponsor-list',
  OrganiserDetails: 'v1/public/event-detail/merchant-view',
  CmsPage: 'v1/public/cms-page',
  checkIsEventSeller: 'v1/public/register/check-event-seller',
  eventSellerSave: 'v1/public/register/seller-register',
  sendOtp: 'v1/public/send-otp',
  login: 'v1/public/public-login',
  googlSignup: 'v1/public/google-signup',
  checkMobile: 'v1/public/check-mobile',
  checkEmail: 'v1/public/check-email',
  CityListForHeader: 'v1/public/country-state-city/city-list',
  CategoryList: 'v1/public/event-detail/category-list',
  getPassPurchaseList: 'v1/public/payment/get-details',
  register: 'v1/public/public-signup',
  getProfile: 'v1/public/get-profile',
  updateProfile: 'v1/public/update-profile',
  getNotification: 'v1/public/notification',
  ApplyOffer: 'v1/public/payment/offer-apply',
  MakePayment: 'v1/public/payment/make-payment',
  CheckPaymentStatus: 'v1/public/payment/check-order-status',
  ArtistView: 'v1/public/event-detail/artist-view',
  upcommingEvent: 'v1/public/event-detail/upcoming-event',
  BillingInfo: 'v1/public/payment/billing-details',
  viewUserProfile: 'v1/public/profile/view',
  updateUserProfile: 'v1/public/profile/profile-update',
  getTicketHistory: 'v1/public/event-pass-history/pass-history',
  updateSetting: 'v1/public/profile/setting',
  addFavoritesArtistList: 'v1/public/add-favorites/list',
  ArtistList: 'v1/public/add-favorites/artist-list',
  AddFavorites: 'v1/public/add-favorites/store',
  RemoveFavorites: 'v1/public/add-favorites/delete',
  getEventDateList: 'v1/public/payment/date-dropdown',
  getPassListByDate: 'v1/public/payment/get-details-date',
  getTicketHistoryDetails: 'v1/public/event-pass-history/share-history',
  cmsPageDropdown: 'v1/public/cms-page-dropdown',
  NotificationList: 'v1/public/notification/list',
  MarkAllAsRead: 'v1/public/notification/read',
  UpdateFcmToken: 'v1/public/add-notification-token',
  userView: 'v1/public/profile/newView',

  saveRating: 'v1/public/rating/store',
  saveFeedback: 'v1/public/add-favorites/store',
  deleteFeedback: 'v1/public/add-favorites/delete',
  nearestEvent: 'v1/public/event-detail/nearest-events',
  GetUserNameByMobile: 'v1/public/get-details-by-mobile',
  popularCityList: 'v1/public/event-detail/popular-cities',
  cityDetect: 'v1/public/city-detect',

  RegistrationFormGet: 'v1/public/registration-form/view',
  RegistrationFormStore: 'v1/public/registration-form/submit',
  GetCityByState: 'v1/public/get-city',
  DeleteImage: 'v1/public/image-delete',
  UploadImage: 'v1/public/image-upload',
  BusinessPageCount: 'v1/public/business/business-count',
};
export default apiConstant;
