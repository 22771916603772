import React from 'react';
import Select2 from 'react-select';

const RegDropdown = ({
                       field,
                       handleChangeField,
                       formErrors,
                     }) => {

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: '#E7E7E7',
      appearance: 'none',
      color: '#131313',
      fontSize: '1rem',
      backgroundColor: '#FAFAFA',
      borderRadius: '4px',
      fontFamily: 'popins, sans-serif',
      '&:hover': {
        borderColor: '#ced4da',
      },
      '&:focus-within': {
        borderColor: '#blue-500',
      },
    }),
    menu: base => ({
      ...base,
      borderRadius: '0.25rem',
      marginTop: 0,
      borderColor: '#E7E7E7',
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      backgroundColor: '#fff',
      borderColor: '#E7E7E7',
    }),
    placeholder: base => ({
      ...base,
      color: '#9C9C9C',
    }),
    singleValue: base => ({
      ...base,
      color: '#131313',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#131313',
      '&:hover': {
        color: '#131313',
      },
    }),
  };

  return (

    <>
      <label className="mb-[10px] block text-base font-medium text-[#101010]">
        {field.display_name}
        {field.is_required == 'Yes' && <span className="text-[#CB2027]">*</span>}
      </label>
      <Select2
        value={field.value} // Bind the value from Formik
        onChange={(selectedValue) =>
          handleChangeField(selectedValue, field)
        }
        options={field.options}
        className="select2-selection"
        styles={customStyles}
        placeholder={'Select ' + field.display_name}
      />
      {formErrors && formErrors[field.field_name] && (
        <div className="text-red-500">{formErrors[field.field_name]} </div>
      )}
    </>

  );
};
RegDropdown.propTypes = {};
export default RegDropdown;
