import { isEmpty } from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import icon from '../../assets/images/small/percentage-yellow.png';
import { convertToAmPm, convertToDate } from '../../common/commonFunction';
import withRouter from '../../components/Common/withRouter';
import api from '../../constants/api';
import {
  addUpdateNewData,
  clearData,
  getData,
} from '../../store/AppMaster/actions';
import logo from '../../assets/images/logo.png';
import PaymentOption from './PaymentOption';
import getPassCalculation from './PassPaymentCalculation';
import EventDetails from '../../components/PassPurchase/EventDetails';
import EventDetailsRes from '../../components/PassPurchase/EventDetailsRes';

import Select2 from 'react-select';
import SelectPass from '../../components/PassPurchase/SelectPass';
import SelectPassRes from '../../components/PassPurchase/SelectPassRes';
import PassOffers from '../../components/PassPurchase/PassOffers';
import PassOffersRes from '../../components/PassPurchase/PassOffersRes';
import SelectDate from '../../components/PassPurchase/SelectDate';
import SelectDateRes from '../../components/PassPurchase/SelectDateRes';
import SeoDetails from '../../seo/SeoDetails';
import logo2 from '../../assets/light_theme_images/logo.svg';
import googleIcon from '../../assets/light_theme_images/google.svg';
import instagramIcon from '../../assets/light_theme_images/instagram-icon.svg';
import LoginPopup from '../../components/Event/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { pushMakePayment } from '../../seo/gtevents';

const PassPurchase = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const [passWithQuantity, setPassWithQuantity] = useState([]);
  const [event, setEvent] = useState('');
  const [eventDates, setEventDates] = useState([]);
  const [settings, setSettings] = useState('');
  const [passList, setPassList] = useState([]);
  const [passOffers, setPassOffers] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isDefault, setDefault] = useState(true);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isUpdateDateData, setIsUpdateDateData] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [isProcessedToPay, setIsProcessedToPay] = useState(false);
  const [passPaymentCalculation, setPassPaymentCalculation] = useState([]);
  const [makePaymentError, setMakePaymentError] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [optionGroupDate, setOptionGroupDate] = useState([]);
  const [dailyPassCount, setDailyPassCount] = useState(0);
  const [sessionPassCount, setSessionPassCount] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [isApplyPromoCode, setIsApplyPromoCode] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownResOpen, setIsDropdownResOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownResRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleResDropdown = () => {
    setIsDropdownResOpen(!isDropdownResOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  const handleClickOutsideRes = (event) => {
    if (
      dropdownResRef.current &&
      !dropdownResRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (isDropdownResOpen) {
      document.addEventListener('mousedown', handleClickOutsideRes);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideRes);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownResOpen]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (isApiCall === 0) {
      getEventDateList();
      setIsApiCall(1);
    }
  }, [
    isApiCall,
    props.router.params.event_slug,
    dispatch,
    props.router.navigate,
  ]);

  const getEventDateList = () => {
    setIsUpdateDateData(true);
    const requestEventDateData = {
      event_slug: props.router.params.event_slug,
    };
    dispatch(
      getData(requestEventDateData, props.router.navigate, api.getEventDateList),
    );
  };

  const getPassListData = (date_id) => {
    setIsUpdateData(true);
    const requestData = {
      event_slug: props.router.params.event_slug,
      event_date_id: date_id,
    };
    dispatch(clearData());
    dispatch(
      getData(requestData, props.router.navigate, api.getPassListByDate),
    );
  };

  if (!isEmpty(data) && !isEmpty(data.event) && isUpdateDateData) {
    setEvent(data.event);
    setEventDates(data.eventDates);
    setDailyPassCount(data.passAvailability.dailyPassType);
    setSessionPassCount(data.passAvailability.seasonPassType);
    const passDates = [];
    data.eventDates.map((eventDate) => {
      if (eventDate.dailyPassType > 0) {
        passDates.push({
          label: eventDate.formatted_date,
          value: eventDate.id,
        });
      }
    });
    setOptionGroupDate(passDates);
    if (data.passAvailability.dailyPassType > 0) {
      let isDefaultDate = false;
      if (!isDefaultDate) {
        if (data.passAvailability.seasonPassType > 0) {
          setSelectedDate({ label: '', value: '' });
          getPassListData('');
          isDefaultDate = true;
        }
      }
      data.eventDates.map((item, index) => {
        if (item.dailyPassType > 0 && !isDefaultDate) {
          if (localStorage.getItem('selectedDateForLocalStorage')) {
            let selectedDateFromLocalStorage = JSON.parse(
              localStorage.getItem('selectedDateForLocalStorage'),
            );
            if (selectedDateFromLocalStorage.value == item.id) {
              setSelectedDate({ label: item.formatted_date, value: item.id });
              getPassListData(item.id);
              isDefaultDate = true;
              localStorage.removeItem('selectedDateForLocalStorage');
            }
          } else {
            setSelectedDate({ label: item.formatted_date, value: item.id });
            getPassListData(item.id);
            isDefaultDate = true;
          }
        }
      });
    } else {
      getPassListData();
    }
    setIsUpdateDateData(false);
  }
  const handlePromoCodeApply = () => {
    if (!isEmpty(promoCode)) {
      setPromoCode(promoCode);
      // If Pass Quantity is 0 then set it to remove it from the request
      setPassPaymentCalculation(
        getPassCalculation(passList, promoCode, event, settings, passOffers),
      );
    }
  };
  if (!isEmpty(data) && !isEmpty(data.settings) && isUpdateData) {
    setSettings(data.settings);
    if (data.eventPasses.length > 0) {
      data.eventPasses &&
      data.eventPasses.forEach((pass) => {
        pass.percentage = 0;
        if (pass.amount > 0 && pass.amount_after_discount != pass.amount) {
          pass.percentage = Math.round(
            ((pass.amount - pass.amount_after_discount) / pass.amount) * 100,
          );
        }
        pass.quantity = 0;
        pass.PriceDisplay = 0;
        if (
          pass.pass_type_slug === 'cover-mepass' ||
          pass.pass_type_slug === 'normal-mepass'
        ) {
          pass.event_date_id =
            pass.eventPassDates.length > 0
              ? pass.eventPassDates[0].event_date_id
              : '';
          setPassWithQuantity((passWithQuantity) => [
            ...passWithQuantity,
            {
              event_pass_id: pass.id,
              quantity: 0,
              event_date_id:
                pass.eventPassDates.length > 0
                  ? pass.eventPassDates[0].event_date_id
                  : '',
            },
          ]);
        } else {
          setPassWithQuantity((passWithQuantity) => [
            ...passWithQuantity,
            {
              event_pass_id: pass.id,
              quantity: 0,
              event_date_id: '',
            },
          ]);
        }
      });
    }
    if (data.eventPasses.length > 0) {
      if (localStorage.getItem('passDetailForLocalStorage')) {
        let passDetailFromLocalStorage = JSON.parse(
          localStorage.getItem('passDetailForLocalStorage'),
        );
        passDetailFromLocalStorage.map((passDetail) => {
          data.eventPasses.map((pass) => {
            if (passDetail.event_pass_id === pass.id) {
              pass.quantity = passDetail.quantity;
            }
          });
        });
        localStorage.removeItem('passDetailForLocalStorage');
      }
    }
    setPassList(data.eventPasses);
    setPassOffers(data.offers);
    setPassPaymentCalculation(
      getPassCalculation(
        data.eventPasses,
        promoCode,
        event,
        data.settings,
        data.offers,
      ),
    );

    if (localStorage.getItem('promoCode')) {
      setPromoCode(localStorage.getItem('promoCode'));
      let promoCode = localStorage.getItem('promoCode');
      // If Pass Quantity is 0 then set it to remove it from the request
      setPassPaymentCalculation(
        getPassCalculation(
          data.eventPasses,
          promoCode,
          event,
          data.settings,
          data.offers,
        ),
      );
      localStorage.removeItem('promoCode');
    }
    setIsUpdateData(false);
  }

  const handleIncrement = (passId, prevQuantity) => {
    setMakePaymentError('');
    passList.forEach((pass) => {
      if (pass.id === passId) {
        pass.quantity += 1;
      }
    });
    setPassList(passList);

    setPassPaymentCalculation(
      getPassCalculation(passList, promoCode, event, settings, passOffers),
    );
  };

  const handleDecrement = (passId, prevQuantity) => {
    if (prevQuantity - 1 >= 0) {
      passList.forEach((pass) => {
        if (pass.id === passId) {
          pass.quantity -= 1;
        }
      });
      setPassList(passList);
      setPassPaymentCalculation(
        getPassCalculation(passList, promoCode, event, settings, passOffers),
      );
    }
  };

  //Payment Code
  // Apply promo code
  const [paymentStatus, setPaymentStatus] = useState('');
  const [orderDetails, setOrderDetails] = useState({});
  const makePayment = () => {
    if (localStorage.getItem('publicMePassUser')) {
      const pass_with_quantity = passList.filter(
        (passWithQuantity) => passWithQuantity.quantity > 0,
      );
      if (pass_with_quantity.length > 0) {
        setIsProcessedToPay(true);
        setMakePaymentError('');
      } else {
        setMakePaymentError('Please select atleast one pass to proceed');
      }
    } else {
      //setIsLoginOpen(true);
      setIsProcessedToPay(false);
    }
  };

  const handleDateDropdown = (selectedOption) => {
    if (selectedOption && selectedOption.label) {
      setSelectedDate({
        label: selectedOption.label,
        value: selectedOption.value,
      });
      toggleDropdown();
      getPassListData(selectedOption.value);
    } else {
      setSelectedDate({
        label: '',
        value: '',
      });
      getPassListData('');
    }
  };

  if (!isEmpty(data) && !isEmpty(data.makePayment) && isEmpty(orderDetails)) {
    setOrderDetails(data.makePayment);
    if (data.makePayment.net_amount > 0) {
      setPaymentStatus('');
      const userDetail = JSON.parse(
        localStorage.getItem('publicMePassUserDetails'),
      );
      const createOrder = {
        amount: data.makePayment.net_amount,
        currency: 'INR',
        description: 'Test Transaction',
        order_id: data.makePayment.payment_order_id,
        name: data.makePayment.user_name,
        email: data.makePayment.user_email,
        contact: userDetail.mobile,
      };
      handlePaymentRazorpay(createOrder);
    } else if (data.makePayment.net_amount == 0) {
      setIsProcessedToPay(false);
      props.router.navigate(
        `/payment/${data.makePayment.payment_order_id}/thank-you`,
      );
    }
  }
  //  Payment Details
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  function getPaymentStatus(payment_id, order_id, signature) {
    setPaymentStatus('');
    const requestData = {
      payment_id: payment_id,
      order_id: order_id,
      signature: signature,
    };
    dispatch(addUpdateNewData(requestData, '', api.CheckPaymentStatus));
  }

  if (
    !isEmpty(data) &&
    !isEmpty(data.paymentStatus) &&
    isEmpty(paymentStatus)
  ) {
    setPaymentStatus(data.paymentStatus);
    if (data.paymentStatus.status === 'paid') {
      props.router.navigate(
        `/payment/${data.paymentStatus.payment_order_id}/thank-you`,
      );
    } else if (data.paymentStatus.status === 'created') {
      setTimeout(() => {
        getPaymentStatus(data.paymentStatus.payment_order_id);
      }, 2000);
    } else if (
      data.paymentStatus.status === 'attempted' ||
      data.paymentStatus.retry === 'No'
    ) {
      setTimeout(() => {
        getPaymentStatus(data.paymentStatus.payment_order_id);
      }, 2000);
    } else if (
      data.paymentStatus.status === 'attempted' ||
      data.paymentStatus.retry === 'Yes'
    ) {
      const userDetail = JSON.parse(
        localStorage.getItem('publicMePassUserDetails'),
      );
      const createOrder = {
        amount: data.makePayment.totalAmount,
        currency: 'INR',
        description: 'Test Transaction',
        order_id: data.makePayment.payment_order_id,
        name: data.makePayment.user_name,
        email: data.makePayment.user_email,
        contact: userDetail.mobile,
      };
      handlePaymentRazorpay(createOrder);
    }
  }

  function handlePaymentRazorpay(order) {
    try {
      // Initialize Razorpay payment
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, //  Razorpay API key Get From .env file
        amount: order.amount, // Amount in smallest currency unit (e.g., paise for INR)
        currency: 'INR',
        name: process.env.REACT_APP,
        description: order.description,
        //image: event.mepass_logo ? event.mepass_logo : '',
        order_id: order.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function(response) {
          // Handle payment success
          // You should verify this payment ID & signature in your server.
          getPaymentStatus(
            response.razorpay_payment_id,
            response.razorpay_order_id,
            response.razorpay_signature,
          );
        },
        prefill: {
          name: order.name,
          email: order.email,
          contact: order.contact_number,
        },
        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#CB2027',
        },
      };
      let paymentObject = new Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Payment failed', error);
    }
  }

  const processedToPay = () => {
    if (localStorage.getItem('publicMePassUser')) {
      const pass_with_quantity = passWithQuantity.filter(
        (passWithQuantity) => passWithQuantity.quantity > 0,
      );
      setOrderDetails('');
      const requestData = getPassCalculation(
        passList,
        promoCode,
        event,
        settings,
        passOffers,
      );
      dispatch(addUpdateNewData(requestData, '', api.MakePayment));
    } else {
      setIsProcessedToPay(false);
    }
  };

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
    if (showOtpModal) {
      setShowOtpModal(false);
    }
  };

  const toggleOtpModal = () => {
    setShowOtpModal(!showOtpModal);
    if (showLoginModal) {
      setShowLoginModal(false);
    }
  };
  return (
    <>

      <SeoDetails
        title={'Book your Pass Now | ' + event.name}
        description={event.tagline + ' Pass Booking'}
        keywords={event.keywords}
        ogImage={event.image}
      />
      <>
        {isProcessedToPay ? (
          <PaymentOption
            event={event}
            paymentDetails={passPaymentCalculation}
            passList={passList}
            processedToPay={processedToPay}
            settings={settings}
            setIsProcessedToPay={setIsProcessedToPay}
            passPaymentCalculation={passPaymentCalculation}
            passOffers={passOffers}
            handlePromoCodeApply={handlePromoCodeApply}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
          />
        ) : (
          <div className="bg-white pt-14 pb-14">
            <div className="container">
              <EventDetails event={event} eventDates={eventDates} />

              {/* Select Dates Section */}
              <SelectDate
                dropdownRef={dropdownRef}
                toggleDropdown={toggleDropdown}
                isDropdownOpen={isDropdownOpen}
                optionGroupDate={optionGroupDate}
                selectedDate={selectedDate}
                handleDateDropdown={handleDateDropdown}
                activeTab={activeTab}
                sessionPassCount={sessionPassCount}
              />

              {/* Pass List Section */}
              {!isEmpty(passList) && (
                <div className="bg-[#F9F9F9] p-4 md:p-7 rounded-lg mt-7">
                  <h3 className="text-[22px] md:text-3xl text-[#101010] font-semibold mb-5">
                    Choose Mepass
                  </h3>
                  {passList.map((pass, index) => (
                    <SelectPass
                      key={index}
                      pass={pass}
                      handleIncrement={handleIncrement}
                      handleDecrement={handleDecrement}
                      activeTab={activeTab}
                    />
                  ))}
                </div>
              )}

              {/* Pass Offers Section */}
              {/* {!isEmpty(passOffers) && (
                <PassOffers
                  passPaymentCalculation={passPaymentCalculation}
                  passOffers={passOffers}
                  icon={icon}
                  handlePromoCodeApply={handlePromoCodeApply}
                  promoCode={promoCode}
                  setPromoCode={setPromoCode}
                />
              )} */}

              {/* Summary Section */}
              <div className="bg-[#F9F9F9] p-5 md:p-7 rounded-lg mt-7">
                <div className="border-y-2 border-[#E7E7E7] py-5 my-5">
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-base text-[#101010] font-body font-normal">
                      GST
                    </p>
                    <p className="text-base text-[#101010] font-body font-medium">
                      ₹{passPaymentCalculation.tax_amount || 0}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-base text-[#101010] font-body font-normal">
                      Convenience Charge
                    </p>
                    <p className="text-base text-[#101010] font-body font-medium">
                      ₹{passPaymentCalculation.convenience_charge || 0}
                    </p>
                  </div>
                  {passPaymentCalculation.discount > 0 && (
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-base text-[#101010] font-body font-normal">
                        Discount applied
                      </p>
                      <p className="text-base text-[#101010] font-body font-medium">
                        - ₹{passPaymentCalculation.discount}
                      </p>
                    </div>
                  )}
                </div>

                {/* Total Amount and Actions */}
                <div className="flex items-center justify-between text-center md:text-left">
                  <div>
                    <span className="text-xl md:text-3xl text-[#101010] font-body font-semibold">
                      ₹{passPaymentCalculation.net_amount || 0}
                    </span>
                    <p className="text-base md:text-xl text-[#101010] font-body font-normal mt-1 md:mt-2">
                      Amount
                    </p>
                  </div>
                  {localStorage.getItem('publicMePassUser') ? (
                    <>
                      {makePaymentError && (
                        <p className="text-red-500 text-sm font-body font-normal mt-2">
                          {makePaymentError}
                        </p>
                      )}
                      {passList.reduce((total, pass) => total + pass.quantity, 0) > 0 && (
                        <button
                          onClick={() => {
                            makePayment();
                            pushMakePayment('makePayment', 'PassPurchase', 'Make Payment', passList, selectedDate.label, passPaymentCalculation.net_amount);
                          }
                          }
                          className="btn-liner-gradient text-white text-base md:text-lg font-semibold py-2 md:py-4 px-4 md:px-7 rounded-lg mt-3 md:mt-0"
                        >
                          Make Payment
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        toggleLoginModal();
                      }}
                      className="btn-liner-gradient text-white text-lg font-semibold py-3 px-8 rounded-lg mt-3 md:mt-0"
                    >
                      Login
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>


      {/* -----------OTP-popup------ */}

      {!localStorage.getItem('publicMePassUser') &&
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>

            <LoginPopup
              isVisible={showLoginModal}
              toggleLoginModal={toggleLoginModal}
              toggleOtpModal={toggleOtpModal}
              props={props}
            />
          </GoogleReCaptchaProvider>
        </GoogleOAuthProvider>

      }


    </>
  );
};

export default withRouter(PassPurchase);
