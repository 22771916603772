import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getData } from '../../store/AppMaster/actions';
import api from '../../constants/api';
import { Link } from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';

const ChipFilter = ({ props }) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { popularCityData } = useSelector(state => ({
    popularCityData: state.AppMaster.popularCityData,
  }));
  const [sidebarArray, setSidebarArray] = useState([]);
  const [isUpdateCityData, setIsUpdateCityData] = useState(false);
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const fullUrl = window.location.href;
  const appUrl = fullUrl.split('/');
  const [popularCityForLink, setPopularCityForLink] = useState([]);
  const [isUpdatePopularCityDataLink, setIsUpdatePopularCityDataLink] = useState(false);
  const [activeNavMenu, setActiveNavMenu] = useState('');
  const [cityCategoryList, setCityCategoryList] = useState([]);
  const [isUpdateSidebarArray, setIsUpdateSidebarArray] = useState(false);
  //Get Current Route Name
  const domain = window.location.hostname;
  let currentRoute = '';
  if (domain === 'www.mepass.in' || domain === 'mepass.in') {
    currentRoute = fullUrl.replace('https://www.mepass.in', '');
  } else if (domain === 'localhost') {
    currentRoute = fullUrl.replace('http://localhost:3000', '');
  } else {
    currentRoute = fullUrl.replace('https://stage-frontendpublic.mepass.co.in', '');
  }

  const getCityCategory = (city) => {
    setIsUpdateCityData(true);
    setCityCategoryList([]);
    const requestData = {
      city: city,
    };
    dispatch(getData(requestData, props.router.navigate, api.cmsPageDropdown));
  };

  // useEffect(() => {
  //   if (!isEmpty(popularCityData) && isEmpty(popularCityForLink)) {
  //     setPopularCityForLink(popularCityData);
  //     dispatch(clearData());
  //     getCityCategory(props.router.params.city_name);
  //   } else {
  //     popularCityList();
  //   }
  // }, [popularCityData]);

  const popularCityList = () => {
    setIsUpdatePopularCityDataLink(true);
    setPopularCityForLink([]);
    const requestData = {};
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.popularCityList));
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.allCities) && isUpdatePopularCityDataLink) {
      setPopularCityForLink(data.allCities);
      setIsUpdatePopularCityDataLink(false);
      dispatch(clearData());
      getCityCategory(props.router.params.city_name);
    }
  });

  if (!isEmpty(data) && !isEmpty(data.city_list) && isUpdateCityData) {
    setCityCategoryList(data);
    setIsUpdateCityData(false);
    setIsUpdateSidebarArray(true);
  }

  useEffect(() => {
    if (props.router.params.city_name) {
      if (!isEmpty(popularCityData) && isEmpty(popularCityForLink)) {
        setPopularCityForLink(popularCityData);
        dispatch(clearData());
        getCityCategory(props.router.params.city_name);
      } else {
        popularCityList();
      }
    }
  }, [props.router.params.city_name, popularCityData]);

  useEffect(() => {
    if (isUpdateSidebarArray) {
      console.log('isUpdateSidebarArray', isUpdateSidebarArray);
      setSidebarArray([]);
      const sidebarArray = [];
      const categoryArray = [];
      let cityName = '';
      if (props.router.params.city_name) {
        cityName = props.router.params.city_name;
      } else if (localStorage.getItem('publicUserSelectMePassCity')) {
        cityName = localStorage.getItem('publicUserSelectMePassCity');
      }
      let linkHome = '/home';
      if (cityName) {
        if (popularCityForLink.length > 0) {
          //filter popular city
          const popularCity = popularCityForLink.filter((item) => item.name === cityName);
          if (popularCity.length > 0) {
            if (popularCity[0].cmspage == 'Yes') {
              linkHome = `/city/${cityName}`;
            } else {
              linkHome = `/city/${cityName}/events`;
            }
          }
        }
      }
      if (currentRoute == linkHome) {
        setActiveNavMenu(linkHome + sidebarArray.length);
      }
      sidebarArray.push({ name: 'Top Trending', link: linkHome, submenu: [] });
      let link = '/events';
      if (cityName) {
        link = `/city/${cityName}/events`;
      }
      if (currentRoute == link) {
        setActiveNavMenu(link + sidebarArray.length);
      }
      sidebarArray.push({ name: 'Events', link: link, submenu: [] });

      if (!isEmpty(cityCategoryList.categoriesWithCity)) {
        cityCategoryList.categoriesWithCity.map((item) => {
          let link = '';
          if (cityName) {
            link = `/city/${cityName}/category/${item.slug}`;
          } else {
            link = `/category/${item.slug}`;
          }
          item.link = link;
          categoryArray.push(item);
        });
      }
      // if (!isEmpty(cityCategoryList.categoriesWithoutCity)) {
      //   cityCategoryList.categoriesWithoutCity.map((item) => {
      //     let link = '';
      //     link = `/category/${item.slug}`;
      //     item.link = link;
      //     categoryArray.push(item);
      //   });
      // }

      if (!isEmpty(categoryArray)) {
        categoryArray.map((item, itemIndex) => {
          let categoryName =
            item.name.charAt(0).toUpperCase() + item.name.slice(1);
          if (itemIndex < 5) {

            if (currentRoute == item.link) {
              setActiveNavMenu(item.link + sidebarArray.length);
            }
            sidebarArray.push({
              name: categoryName,
              link: item.link,
              submenu: [],
            });
          }

        });
      }
      setSidebarArray(sidebarArray);
      setIsUpdateSidebarArray(false);
    }
  }, [isUpdateSidebarArray]);

  const handleMenuClick = (link, index) => {
    setActiveNavMenu(link + index);
  };

  console.log('activeNavMenu', activeNavMenu);
  return (
    <section className="pt-10 md:pt-16">
      <div className="container">
        <div className="flex lg:flex-wrap gap-3 md:gap-5 overflow-auto">
          {sidebarArray.map((item, index) => (
            <Link
              to={item.link}
              key={item.name + index}
              onClick={() => {
                // const viewName = item.link.replace(/\//g, "_"); // Dynamically create a view name, e.g., "/home" becomes "home"
                // pushViewEvent("Page View", `${viewName}_page`, viewName, {});
                console.log('item.link', item.link + index);
                handleMenuClick(item.link, index);
              }}
              className={`py-1 lg:py-2 px-4 lg:px-[20px] text-base lg:text-2xl border duration-300 ease-in-out rounded-full text-nowrap 
                ${activeNavMenu == item.link + index
                ? 'bg-[#CB2129] text-white border-[#CB2129] font-semibold'
                : 'bg-white border-[#101010] text-[#101010] font-medium'
              }`
              }
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default withRouter(ChipFilter);