import React, { useCallback } from 'react';

const RegFile = ({
    field,
    handleImageChange,
    formErrors,
    previews,
    setPreviews,
    fileInputRefs,
    uploadIcon,
    handleDivClick,
    imageRatio,
}) => {

    return (

        <>
            <label className="mb-[10px] block text-base font-medium text-[#101010]">
                {field.display_name}
                {field.is_required == 'Yes' &&
                    <span className="text-primary">*</span>
                }
            </label>
            <div className="" onClick={() => handleDivClick(field.field_name)}>
                <div className="flex justify-center lg:justify-start mb-3">
                    <div className="bg-white p-4 rounded-lg w-full border border-[#E7E7E7] flex flex-col justify-center items-center">
                        <div className="p-1 rounded-lg cursor-pointer justify-center relative text-center">
                            {previews[field.field_name] ? (
                                <div>
                                    <img
                                        src={previews[field.field_name]}
                                        alt="Selected"
                                        className={`w-full aspect-[${imageRatio}] object-cover`}
                                    />
                                    <div
                                        className="bg-[#FFD1D3] rounded-full p-1 w-[28px] h-[28px] flex items-center justify-center text-primary absolute top-[13px] right-[9px] cursor-pointer"
                                    // onClick={() => handleDivClick(field.field_name)}
                                    >
                                        <i className="fas fa-pen text-sm"></i>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <img
                                            src={uploadIcon}
                                            alt="upload"
                                            className="inline-block text-center"
                                        />
                                        <p className="text-[#101010] text-base font-medium mt-2">
                                            {field.display_name}
                                        </p>
                                        <p className="text-[#9C9C9C] text-[12px] font-normal mt-2">
                                            JPEG/PNG, max size 2MB.
                                        </p>
                                    </div>
                                </>
                            )}
                            <input
                                type="file"
                                id={field.field_name}
                                className="hidden"
                                ref={(el) => (fileInputRefs.current[field.field_name] = el)}
                                accept="image/jpeg,image/png"
                                onChange={(e) => handleImageChange(e, field.field_name)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {formErrors && formErrors[field.field_name] && (
                <div className="text-red-500 mt-1">{formErrors[field.field_name]} </div>
            )}
        </>

    )
}
RegFile.propTypes = {

}
export default RegFile
