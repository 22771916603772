import React from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const RegEditor = ({
    field,
    handleEditorChange,
    formErrors,
    editorStates

}) => {

    return (

        <>
            <label className="mb-[10px] block text-base font-medium text-[#101010]">
                {field.display_name}
                {field.is_required == 'Yes' &&
                    <span className="text-primary">*</span>
                }
            </label>
            <Editor
                editorState={editorStates[field.field_name]}
                onEditorStateChange={(state) => handleEditorChange(field.field_name, state)}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="border border-[#E7E7E7] rounded-[5px] p-2 bg-[#ffffff]"
                placeholder={`Enter ${field.display_name}`}
                toolbar={{
                    options: ["inline", "blockType", "fontSize", "list", "textAlign"],
                    inline: {
                        options: ["bold", "italic", "underline", "strikethrough"],
                    },
                    blockType: {
                        inDropdown: true,
                        options: [
                            "Normal",
                            "H3",
                            "H4",
                            "H5",
                            "H6",
                            "Blockquote",
                            "Code",
                        ],
                    },
                }}
            />
            {formErrors && formErrors[field.field_name] && (
                <div className="text-red-500">{formErrors[field.field_name]} </div>
            )}
        </>

    )
}
RegEditor.propTypes = {

}
export default RegEditor
