import React from 'react';
import urlLink from '../../assets/images/small/octicon_link.png';

const RegUrl = ({
                  field,
                  handleChangeField,
                  formErrors,
                }) => {

  return (

    <>
      <label className="mb-[10px] block text-base font-medium text-[#101010]">
        {field.display_name}
        {field.is_required == 'Yes' && <span className="text-[#CB2027]">*</span>}
      </label>
      <div className="relative">
        <input
          name={field.field_name}
          type="text"
          value={field.value}
          onChange={
            (e) => {
              handleChangeField(e, field);
            }
          }
          className={'bg-white border border-[#E7E7E7] text-[#101010] text-base font-semibold font-body  rounded-lg placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5'}
          placeholder={'Enter ' + field.display_name}
        />
        <img src={urlLink} alt="icon" className="absolute top-4 right-3 z-10" />
      </div>
      {formErrors && formErrors[field.field_name] && (
        <div className="text-red-500 mt-1">{formErrors[field.field_name]} </div>
      )}
    </>

  );
};
RegUrl.propTypes = {};
export default RegUrl;
