import React from "react";
import { Link } from "react-router-dom";
import icon from "../../assets/images/small/vector.webp";
import gifUrl from "../../assets/images/PaymentSuccessful.gif";
import copyIcon from "../../assets/light_theme_images/copy-icon.svg";
import withRouter from "../../components/Common/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import api from "../../constants/api";
import { getData } from "../../store/AppMaster/actions";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import gifUrlReview from "../../assets/light_theme_images/Review-rating.gif";
import { pushThankYou } from "../../seo/gtevents";

const ThankYou = (props) => {
  const params = useParams();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [billingData, setBillingData] = useState("");
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showReviewExperienceModal, setShowReviewExperienceModal] =
    useState(false);
  const authData = JSON.parse(localStorage.getItem("publicMePassUserDetails"));

  useEffect(() => {
    if (isApiCall === 0) {
      setIsUpdateData(true);
      const requestData = { order_id: params.order_id };
      dispatch(getData(requestData, props.router.navigate, api.BillingInfo));
      setIsApiCall(1);
    }
  }, [isApiCall, dispatch, props.router.navigate, params.order_id]);

  if (!isEmpty(data) && !isEmpty(data.billingDetail) && isUpdateData) {
    setBillingData(data);
    setIsUpdateData(false);
  }

  // trigger pushMakePayment after payment success
  useEffect(() => {
    if (!isEmpty(billingData)) {
      pushThankYou('Thank you', 'Payment Successful', 'Thank you', billingData.billingDetail, billingData.totalAmount);
    }
  }, [billingData]);

  const [copySuccess, setCopySuccess] = useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false); // Clear the message after 3 seconds
        }, 2000);
      })
      .catch((err) => {
        setCopySuccess(false);
      });
  };
  const toggleReviewModal = () => {
    setShowReviewModal(!showReviewModal);
    if (showReviewExperienceModal) {
      setShowReviewExperienceModal(false);
    }
  };

  const toggleReviewExperienceModal = () => {
    setShowReviewExperienceModal(!showReviewExperienceModal);
    if (showReviewModal) {
      setShowReviewModal(false);
    }
  };
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleStarClick = (index) => {
    setActiveIndex(index);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      setIsUpdateFormData(true);
      const requestData = {
        notification: values.notification,
        send_email: values.send_email,
        send_whatsapp: values.send_whatsapp,
        send_facebook: values.send_facebook,
      };
      dispatch(
        addUpdateNewData(requestData, props.router.navigate, api.updateSetting)
      );
    },
  });

  return (
    <div className="bg-white pt-14 pb-14">
      <div className="container">
        <div className="lg:px-14">
          <h2 className="text-3xl md:text-4xl text-[#101010] font-semibold text-center">
            Payment Successful!
          </h2>
          <p className="text-lg text-[#101010] font-normal text-center mt-2">
            Your Mepass is secured! Get ready to dive into an unforgettable
            experience.
          </p>
          <div className="flex justify-center">
            <div className="w-[400px] h-[400px]">
              <img src={gifUrl} alt="Example GIF" />
            </div>
          </div>

          <div className="bg-[#F9F9F9] p-3 md:p-7 rounded-lg mt-2">
            <h3 className="text-[22px] md:text-3xl text-[#101010] font-semibold mb-4">
              Booking Details
            </h3>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 md:gap-5">
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Name
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold">
                  {authData && authData.name}
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Email
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold break-all">
                  {authData && authData.email}
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Mobile no.
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold">
                  {authData && authData.mobile}
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Booking ID
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold break-all">
                  {params.order_id ? params.order_id : ""}
                  <img
                    src={copyIcon}
                    alt=""
                    className="inline-block ml-3"
                    onClick={() =>
                      copyToClipboard(params.order_id ? params.order_id : "")
                    }
                  />
                  {copySuccess && (
                    <span className="text-[#00FF00] text-sm font-body font-normal ml-2">
                      Copied!
                    </span>
                  )}
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Ticket
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold">
                  {billingData &&
                    billingData.totalPass &&
                    billingData.totalPass}{" "}
                  ticket
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Order total
                </span>

                <div className="flex">
                  <p className="text-base md:text-xl text-[#CB2129] font-semibold mr-[2px]">
                    ₹
                  </p>
                  <h4 className="text-base md:text-xl text-[#CB2129] font-semibold">
                    
                    {billingData &&
                      billingData.totalAmount &&
                      billingData.totalAmount}
                  </h4>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mt-5 md:mt-9">
              <Link
                to="/home"
                className="text-[#CB2129] border border-[#CB2129] bg-white text-lg text-center rounded-lg font-semibold py-2 px-4"
              >
                Go to home
              </Link>
              {billingData.billingDetail && (
                <Link
                  to={"/qr/" + billingData.billingDetail[0].url_string}
                  className="text-[#FFFFFF] btn-liner-gradient text-lg text-center rounded-lg font-semibold py-2 px-4"
                >
                  View ticket
                </Link>
              )}
            </div>
          </div>
        </div>

        {/* <button
          onClick={() => {
            toggleReviewModal();
          }}
          className="bg-primary p-3 text-white rounded-[4px] mr-3"
        >
          review1
        </button>
        <button
          onClick={() => {
            toggleReviewExperienceModal();
          }}
          className="bg-primary p-3 text-white rounded-[4px]"
        >
          review2
        </button> */}
        {/* ----------------card-start--------------- */}
        {/* <div className="hidden md:block">
          <div className="bg-black p-5 rounded-lg">
            <h3 className="text-[22px] md:text-2xl text-white font-body font-normal mb-5">
              Booking ID : {params.order_id ? params.order_id : ""}
              <img
                src={icon}
                alt=""
                className="inline-block ml-4"
                onClick={() =>
                  copyToClipboard(params.order_id ? params.order_id : "")
                }
              />
              {copySuccess && (
                <span className="text-[#00FF00] text-sm font-body font-normal ml-2">
                  Copied!
                </span>
              )}
            </h3>
            <div className="flex items-center justify-between">
              <h3 className="text-[20px] text-white font-body font-semibold">
                Ticket Quantity
              </h3>
              <span className="text-[20px] text-white font-body font-semibold">
                {billingData && billingData.totalPass && billingData.totalPass}
              </span>
            </div>
            <div className="border-y border-[#F6F6F6] py-5 my-5">
              {billingData &&
                billingData.billingDetail &&
                billingData.billingDetail.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between mb-2"
                    >
                      <p className="text-base text-[#DCDCDC] font-body font-normal">
                        {item.pass_count} x {item.passName}
                      </p>
                      <p className="text-base text-[#DCDCDC] font-body font-medium">
                        ₹ {item.pass_amount}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className="flex items-center justify-between">
              <span className="text-xl text-white font-body font-semibold">
                Total Amount
              </span>
              <p className="text-xl text-white font-body font-semibold">
                ₹
                {billingData &&
                  billingData.totalAmount &&
                  billingData.totalAmount}
              </p>
            </div>
          </div>
        </div> */}
        {/* ----------------card-end---------------- */}

        {/* -----------review-popup------ */}
        {showReviewModal ? (
          <>
            <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                  &#8203;
                </span>
                <div
                  className="inline-block align-center bg-white border border-[#4D4444] rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] lg:w-[50%] 2xl:w-[30%]"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div className="bg-white px-4 md:px-10 py-8 relative">
                    <button
                      type="button"
                      className="py-2 px-2 absolute top-3 right-5"
                      onClick={() => {
                        toggleReviewModal();
                      }}
                    >
                      <i className="fas fa-times text-2xl"></i>
                    </button>
                    <div className="text-center">
                      <h2 className="text-[#101010] text-2xl font-semibold">
                        Share Your Experience
                      </h2>
                    </div>
                    <div className="border-b-2 border-[#F0BA0E] my-4"></div>
                    <p className="text-xl font-normal text-[#101010] mt-5">
                      How would you like to review the{" "}
                      <span className="font-semibold text-[#CB2129]">
                        artist name
                      </span>
                      ?
                    </p>
                    <div className="rating-box my-5">
                      <div className="stars flex items-center justify-center gap-4">
                        {[...Array(5)].map((_, index) => (
                          <i
                            key={index}
                            className={`fa-solid fa-star text-3xl cursor-pointer transition-transform duration-200 ${
                              index <= activeIndex
                                ? "star-gradient-color"
                                : "text-[#D8D8D8]"
                            }`}
                            onClick={() => handleStarClick(index)}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="text-base text-[#101010] font-medium block mb-[10px]">
                        Add a note
                      </label>
                      <textarea
                        className="bg-[#F9F9F9] rounded-[4px] p-[11px] w-full placeholder:text-[#616161]"
                        id="inputText"
                        rows="5"
                        placeholder="note..."
                      ></textarea>
                    </div>
                    <p className="text-xl font-normal text-[#101010]">
                      How would you like to review the{" "}
                      <span className="font-semibold text-[#CB2129]">
                        organiser name
                      </span>
                      ?
                    </p>
                    <div className="rating-box my-5">
                      <div className="stars flex items-center justify-center gap-4">
                        {[...Array(5)].map((_, index) => (
                          <i
                            key={index}
                            className={`fa-solid fa-star text-3xl cursor-pointer transition-transform duration-200 ${
                              index <= activeIndex
                                ? "star-gradient-color"
                                : "text-[#D8D8D8]"
                            }`}
                            onClick={() => handleStarClick(index)}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="text-base text-[#101010] font-medium block mb-[10px]">
                        Add a note
                      </label>
                      <textarea
                        className="bg-[#F9F9F9] rounded-[4px] p-[11px] w-full placeholder:text-[#616161]"
                        id="inputText"
                        rows="5"
                        placeholder="note..."
                      ></textarea>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-3">
                      <button className="text-[#CB2129] border border-[#CB2129] bg-white text-lg text-center rounded-[4px] font-semibold py-2.5 px-4">
                        Home
                      </button>
                      <button className="text-[#FFFFFF] btn-liner-gradient text-lg text-center rounded-[4px] font-semibold py-2.5 px-4">
                        Review
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/* -----------Review-experience-popup------ */}

        {showReviewExperienceModal ? (
          <>
            <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                  &#8203;
                </span>
                <div
                  className="inline-block align-center bg-white border border-[#4D4444] rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] lg:w-[50%] 2xl:w-[30%]"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div className="bg-white px-4 md:px-10 py-10  relative">
                    <button
                      type="button"
                      className="py-2 px-2 absolute top-3 right-5"
                      onClick={() => {
                        toggleReviewExperienceModal();
                      }}
                    >
                      <i className="fas fa-times text-2xl"></i>
                    </button>
                    <div className="w-[240px] h-[240px] flex justify-center m-auto">
                      <img src={gifUrlReview} alt="rating review" />
                    </div>
                    <div className="mt-3">
                      <h2 className="text-3xl text-[#101010] font-semibold text-center">
                        Thank You For Sharing Your Experience
                      </h2>
                      <div className="border-b-2 border-[#F0BA0E] my-7"></div>
                      <p className="text-xl text-[#101010] font-normal mb-7">
                        Subscribe for future updates
                      </p>
                      <div className="flex mb-6">
                        <div className="flex-1">
                          <h3 className="text-2xl text-[#101010] font-semibold">
                            Artist Name
                          </h3>
                        </div>
                        <div className="flex-none">
                          <div>
                            <label
                              for="toggle4"
                              className="flex items-center cursor-pointer"
                            >
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  id="toggle4"
                                  className="sr-only peer"
                                  checked={
                                    validation.values["send_facebook"] === "Yes"
                                  }
                                  onChange={(e) => {
                                    validation.setFieldValue(
                                      "send_facebook",
                                      e.target.checked ? "Yes" : "No"
                                    );
                                  }}
                                />
                                <div className="block switch-inner-shadow w-14 h-7 rounded-full bg-gray-300 transition-all duration-500 peer-checked:bg-primary"></div>
                                <div className="absolute top-1 left-1 h-5 w-5 bg-white rounded-full transition-all duration-500 peer-checked:left-8"></div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex mb-7">
                        <div className="flex-1">
                          <h3 className="text-2xl text-[#101010] font-semibold">
                            Organiser Name
                          </h3>
                        </div>
                        <div className="flex-none">
                          <div>
                            <label
                              for="toggle4"
                              className="flex items-center cursor-pointer"
                            >
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  id="toggle4"
                                  className="sr-only peer"
                                  checked={
                                    validation.values["send_facebook"] === "Yes"
                                  }
                                  onChange={(e) => {
                                    validation.setFieldValue(
                                      "send_facebook",
                                      e.target.checked ? "Yes" : "No"
                                    );
                                  }}
                                />
                                <div className="block switch-inner-shadow w-14 h-7 rounded-full bg-gray-300 transition-all duration-500 peer-checked:bg-primary"></div>
                                <div className="absolute top-1 left-1 h-5 w-5 bg-white rounded-full transition-all duration-500 peer-checked:left-8"></div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button className="text-[#FFFFFF] btn-liner-gradient text-lg text-center rounded-[4px] font-semibold py-2.5 px-4 w-[128px]">
                          Home
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(ThankYou);
