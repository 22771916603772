import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import EventView from "../../components/Event/View";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import PropTypes from "prop-types";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
import { isEmpty } from "lodash";
import popularEvent from "../../assets/light_theme_images/popular-event.gif";
import offerEvent from "../../assets/light_theme_images/exclusive-offer.gif";
import upcomingEvent from "../../assets/light_theme_images/upcoming-event.gif";
import arrowRight from "../../assets/light_theme_images/arrow-right.svg";
import { pushViewEvent } from "../../seo/gtevents";

const Event = ({ events, props, section_title }) => {
  const swiperRef = useRef(null);

  const handlePageClick = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };

  return (
    <div onClick={handlePageClick}>
      {events && events.length > 0 ? (
        <>
          <div className="pb-8 md:pb-16">
            <div>
              {/* <div className="flex items-center md:justify-between gap-4 mb-6">
                <div className="flex gap-4">
                  <div className="flex">
                  <img
                      src={
                        section_title === "Popular Events Near You"
                          ? popularEvent
                          : section_title === "Events With Exclusive Offers"
                          ? offerEvent
                          : section_title === "Upcoming Events Near You"
                          ? upcomingEvent
                          : popularEvent
                      }
                      alt="popular event"
                      className="inline-block mr-2"
                    />
                    <h2 className="text-xl md:text-3xl font-semibold text-[#212121] capitalize line-clamp-1">
                      {section_title}
                    </h2>
                  </div>
                  <img
                    src={arrowRight}
                    alt="popular event"
                    className="inline-block"
                  />
                </div>
                
                <button
                  onClick={() => {
                    const category =
                      section_title === "Popular Events Near You"
                        ? "Popular Events"
                        : section_title === "Events With Exclusive Offers"
                        ? "Exclusive Offer Events"
                        : section_title === "Upcoming Events Near You"
                        ? "Upcoming Events"
                        : "Other Events";

                    pushViewEvent("View All Events", category, "View All", {});
                    props.router.navigate("/events");
                  }}
                  className="text-[#CB2129] border border-[#CB2129] text-sm md:text-base font-semibold py-2 px-4 rounded-lg font-body whitespace-nowrap hidden md:inline-block"
                >
                  View all
                </button>
              </div> */}
              <div className="flex mb-6">
                <img
                  src={
                    section_title === "Popular Events Near You"
                      ? popularEvent
                      : section_title === "Events With Exclusive Offers"
                        ? offerEvent
                        : section_title === "Upcoming Events Near You"
                          ? upcomingEvent
                          : popularEvent
                  }
                  alt="popular event"
                  className="inline-block mr-2 size-6 md:size-9"
                />
                <h2 className="text-base md:text-3xl font-bold text-[#212121] capitalize line-clamp-1">
                  {section_title}
                </h2>
              </div>
            </div>
            {/* <Swiper
              slidesPerView={3}
              spaceBetween={30}
              freeMode={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2.1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, FreeMode, Pagination]}
              className="mySwiper"
              onSwiper={(swiper) => (swiperRef.current = swiper)}
            >
              {events &&
                events.map((singleEvent, index) => (
                  <React.Fragment key={index}>
                    {!isEmpty(singleEvent.event) ? (
                      <SwiperSlide>
                        <Link to={`/events/${singleEvent.event.slug}`}>
                          <EventView
                            singleEvent={singleEvent.event}
                            props={props}
                            key={index}
                            section_title={section_title}
                          />
                        </Link>
                      </SwiperSlide>
                    ) : null}
                  </React.Fragment>
                ))}
            </Swiper> */}
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 lg:gap-5">
              {events &&
                events.map((singleEvent, index) => (
                  <React.Fragment key={index}>
                    {index < 4 && !isEmpty(singleEvent.event) ? (
                      <Link to={`/events/${singleEvent.event.slug}`}>
                        <EventView
                          singleEvent={singleEvent.event}
                          props={props}
                          key={index}
                          section_title={section_title}
                        />
                      </Link>
                    ) : null}
                  </React.Fragment>
                ))}
            </div>
            {/* <div className="text-center mt-7">
              <button
                onClick={() => {
                  props.router.navigate("/events");
                }}
                className="text-[#CB2129] border border-[#CB2129] text-sm md:text-base font-semibold py-2 px-4 rounded-lg font-body whitespace-nowrap inline-block md:hidden"
              >
                View all
              </button>
            </div> */}
          </div>
        </>
      ) : null}
    </div>
  );
};

Event.propTypes = {
  events: PropTypes.array,
  props: PropTypes.object,
  section_title: PropTypes.string,
};

export default Event;
