import React, { useState } from "react";
import { InstagramLogin } from "@amraneze/react-instagram-login";

const InstagramLoginPage = () => {
  const [profile, setProfile] = useState(null);

  const responseInstagram = async (accessToken) => {
    console.log("Access Token:", accessToken);

    // Fetch basic profile info
    const apiUrl = `https://graph.instagram.com/me?fields=id,username,account_type&access_token=${accessToken}`;

    try {
      const res = await fetch(apiUrl);
      const data = await res.json();
      console.log(res);
       console.log(data);
      if (res.ok) {
        console.log("User Profile:", data);
        setProfile(data);
      } else {
        console.error("Error fetching profile:", data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  
  return (
    <div className="container text-center">
      <h1>Instagram Login</h1>
      <InstagramLogin
        clientId="572839015473495"
        buttonText="Login with Instagram"
        onSuccess={(response) => responseInstagram(response)}
        onFailure={(error) => console.error("Login Error:", error)}
        scope={["instagram_business_basic","instagram_business_content_publish","instagram_business_manage_messages","instagram_business_manage_comments"]}
      />

      {profile && (
        <div className="profile-info">
          <h2>Profile Information</h2>
          <p>ID: {profile.id}</p>
          <p>Username: {profile.username}</p>
          <p>Account Type: {profile.account_type}</p>
          
        </div>
      )}
    </div>
  );
};

export default InstagramLoginPage;
