import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import withRouter from '../Common/withRouter';
import logo from '../../assets/light_theme_images/logo.svg';
import notification from '../../assets/images/small/notification.png';
import user from '../../assets/images/default/user-image.png';
import locationIcon from '../../assets/light_theme_images/location.svg';
import downArrow from '../../assets/light_theme_images/down-arrow.svg';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getData } from '../../store/AppMaster/actions';
import api from '../../constants/api';
import SelectCityModal from './SelectCityModal';
import SidebarMenu from './SidebarMenu';
import ProfileMenu from './ProfileMenu';
import searchIcon from '../../assets/light_theme_images/search.svg';
import calendarGradient from '../../assets/light_theme_images/calendar-gradient.svg';
import { pushClickEvent } from '../../seo/gtevents';

const Header = (props) => {
  const authUserDetails = JSON.parse(
    localStorage.getItem('publicMePassUserDetails'),
  );

  const [cityList, setCityList] = useState([]);
  const [isApiCallHeader, setIsApiCallHeader] = useState(0);
  const [isUpdateCityData, setIsUpdateCityData] = useState(false);
  const [activeItem, setActiveItem] = useState('/home');
  const fullUrl = window.location.href;
  const appUrl = fullUrl.split('/');
  const lastSegment = appUrl[appUrl.length - 1];
  const dispatch = useDispatch();
  const [cmsData, setCmsData] = useState('');
  const [isCmsData, setIsCmsData] = useState(false);
  const [searchEvent, setSearchEvent] = useState('');
  const [isUpdatePopularCityData, setIsUpdatePopularCityData] = useState(false);

  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { popularCityData } = useSelector(state => ({
    popularCityData: state.AppMaster.popularCityData,
  }));
  const [sidebarArray, setSidebarArray] = useState([]);
  const currentLocation = useLocation();
  const currentRoute = currentLocation.pathname;
  useEffect(() => {
    if (isApiCallHeader === 0) {
      getCityCategory(props.router.params.city_name);
      if (currentRoute == '/home' || currentRoute == '/') {
        setShowCityModal(true);
      }
      CmsPage();
      setIsApiCallHeader(1);
    }
  }, [isApiCallHeader]);

  const CmsPage = () => {
    setIsCmsData(true);
    let requestCmsData = {
      section_titles: 'trending',
      is_page: 'Event',
    };
    if (props.router.params.city_name) {
      requestCmsData.city = props.router.params.city_name;
    }
    dispatch(clearData());
    dispatch(getData(requestCmsData, props.router.navigate, api.CmsPage));
  };

  useEffect(() => {
    if (
      !isEmpty(data) &&
      !isEmpty(data.cmsPage) &&
      !isEmpty(data.cmsPage.trending) &&
      isCmsData
    ) {
      setCmsData(data.cmsPage.trending);
      setIsCmsData(false);
    }
  });

  const getCityCategory = (city) => {
    setIsUpdateCityData(true);
    setSidebarArray([]);
    const requestData = {
      city: city,
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.cmsPageDropdown));
  };

  useEffect(() => {
    if (!isEmpty(popularCityData)) {
      setCityList(popularCityData);
    } else {
      popularCityList();
    }
  }, [popularCityData]);

  const popularCityList = () => {
    setIsUpdatePopularCityData(true);
    setCityList([]);
    const requestData = {};
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.popularCityList));
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.allCities) && isUpdatePopularCityData) {
      setCityList(data.allCities);
      setIsUpdatePopularCityData(false);
    }
  });

  if (!isEmpty(data) && !isEmpty(data.city_list) && isUpdateCityData) {
    const categoryArray = [];
    sidebarArray.push({ name: 'Home', link: '/home', submenu: [] });
    let link = '/events';
    if (props.router.params.category_name && props.router.params.city_name) {
      link = `/city/${props.router.params.city_name}/category/${props.router.params.category_name}/events`;
    } else if (props.router.params.category_name) {
      link = `/category/${props.router.params.category_name}/events`;
    } else if (props.router.params.city_name) {
      link = `/city/${props.router.params.city_name}/events`;
    }
    sidebarArray.push({ name: 'Events', link: link, submenu: [] });

    if (!isEmpty(data.categoriesWithCity)) {
      data.categoriesWithCity.map((item) => {
        let link = '';
        if (props.router.params.city_name) {
          link = `/city/${props.router.params.city_name}/category/${item.slug}`;
        } else {
          link = `/category/${item.slug}`;
        }
        item.link = link;
        categoryArray.push(item);
      });
    }
    if (!isEmpty(data.categoriesWithoutCity)) {
      data.categoriesWithoutCity.map((item) => {
        let link = '';
        link = `/category/${item.slug}`;
        item.link = link;
        categoryArray.push(item);
      });
    }

    if (!isEmpty(categoryArray)) {
      categoryArray.map((item, itemIndex) => {
        let categoryName =
          item.name.charAt(0).toUpperCase() + item.name.slice(1);
        if (itemIndex < 2) {
          sidebarArray.push({
            name: categoryName,
            link: item.link,
            submenu: [],
          });
        } else if (itemIndex == 2) {
          sidebarArray.push({
            name: 'More',
            link: '#',
            submenu: [{ name: categoryName, link: item.link }],
          });
        } else if (itemIndex > 2) {
          sidebarArray[4] &&
          sidebarArray[4].submenu &&
          sidebarArray[4].submenu.push({
            name: categoryName,
            link: item.link,
          });
        }
      });
    }
    setIsUpdateCityData(false);
  }

  const [sidebarToggle, setSidebarToggle] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarToggle(true);
  };

  const handleNavLinkClick = () => {
    setSidebarToggle(false); // Close sidebar when a link is clicked
  };

  const [isShowCityModal, setShowCityModal] = useState(false);
  const [isShowSearchModal, setShowSearchModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loaderLocation, setLoaderLocation] = useState(false);

  const handleCityModal = () => {
    setShowCityModal(!isShowCityModal);
  };

  const handleOnSelect = (item) => {
    setSelectedItem(item.name); // Set the selected item as active
    getCityCategory(item.name);
    localStorage.setItem('publicUserSelectMePassCity', item.name);
    setShowCityModal(false);
    // if (item.cmspage == 'Yes') {
    //   props.router.navigate(`/city/${item.name}`);
    // }
    if (lastSegment === 'events') {
      let link = '/events';
      if (props.router.params.category_name) {
        link = `/city/${item.name}/category/${props.router.params.category_name}/events`;
      } else {
        link = `/city/${item.name}/events`;
      }
      props.router.navigate(link);
    } else {
      props.router.navigate(`/city/${item.name}`);
    }
  };

  // State to track which submenu is open
  const [openSubmenu, setOpenSubmenu] = useState(null);

  // Function to toggle submenu visibility
  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  // Function to set active menu or submenu
  const handleActiveItem = (path) => {
    setActiveItem(path);
  };

  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

  // Function to toggle submenu
  const toggleSubmenuIndex = (index) => {
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
  };
  const navigate = useNavigate();
  const handleEventSearchChange = () => {
    setShowSearchModal(false);
    if (searchEvent) {
      navigate(`/events?search=${encodeURIComponent(searchEvent)}`);
    } else {
      window.location.href = '/events';
    }
  };

  const [locationDataUpdate, setLocationDataUpdate] = useState(false);
  const [location, setLocation] = useState({
    city: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    postal_code: '',
  });
  const [errorLocation, setErrorLocation] = useState('');
  const detectYourLocation = () => {
    setLoaderLocation(true);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocationDataUpdate(true);
          const requestData = {
            latitude: latitude,
            longitude: longitude,
            user_id: authUserDetails ? authUserDetails.id : '',
          };
          dispatch(getData(requestData, props.router.navigate, api.cityDetect));
        },
        (err) => {
          setLoaderLocation(false);
          //setErrorLocation("Failed to fetch city data")
        },
      );
    } else {
      setLoaderLocation(false);
      setError('Geolocation is not supported by your browser.');
    }
  };

  if (!isEmpty(data) && !isEmpty(data.locationDetails) && locationDataUpdate) {
    setLoaderLocation(false);
    setLocation({
      city: data.locationDetails.city,
      state: data.locationDetails.state,
      country: data.locationDetails.country,
      postal_code: data.locationDetails.postal_code,
    });
    setSelectedItem(data.locationDetails.city); // Set the selected item as active
    getCityCategory(data.locationDetails.city);
    localStorage.setItem(
      'publicUserSelectMePassCity',
      data.locationDetails.city,
    );
    if (data.cmsExists == 'Yes') {
      setSelectedItem(data.locationDetails.city); // Set the selected item as active
      getCityCategory(data.locationDetails.city);
      props.router.navigate(`/city/${data.locationDetails.city}`);
    } else {
      setSelectedItem(data.locationDetails.city); // Set the selected item as active
      getCityCategory(data.locationDetails.city);
      props.router.navigate(`/city/${data.locationDetails.city}/events`);
    }
    setShowCityModal(false);
    setLocationDataUpdate(false);
  }
  return (
    <React.Fragment>
      <div className="relative z-10">
        <nav className="bg-white w-full header-border-liner-gradient">
          <div className="container mx-auto py-[15px]">
            <div className="relative flex md:h-16 items-center justify-between gap-3 md:gap-14">
              <div className="w-[163.63px]">
                <Link to="/home">
                  <img className="w-auto" src={logo} alt="Your Company name" />
                </Link>
              </div>
              <div className="flex-auto items-center w-full">
                <div className="hidden lg:block">
                  <div className="flex gap-4">
                    {/* To Do Remove As Of Now
                      <div className="flex-none">
                        <div
                          onClick={() => {
                            setShowSearchModal(true)
                            setSearchEvent('')
                          }}
                          className="size-12 flex items-center justify-center cursor-pointer search-border-liner-gradient"
                        >
                          <img
                            src={searchIcon}
                            alt="search icon"
                            className="inline-block"
                          />
                        </div>
                      </div> */}
                    <div className="flex-1">
                      <div className="flex gap-6 justify-between">
                        <div className="w-[350px] xl:w-[480px] border border-[#CB2129] rounded-lg px-3 py-[10px]">
                          <div className="flex items-center">
                            <i class="fa-solid fa-magnifying-glass text-[#737373] mr-1"></i>
                            <input
                              onClick={() => {
                                setShowSearchModal(true),
                                  setShowCityModal(false);
                              }}
                              type="text"
                              className="text-[#101010] text-base font-medium border border-white bg-transparent rounded-lg  placeholder:text-[#737373] focus-visible:border-white focus-visible:outline-none w-full"
                              placeholder="search events/concerts/gigs"
                            />
                          </div>
                        </div>
                        <div className="relative">
                          <div className="select-location-border-liner-gradient px-3 py-3 relative">
                            <div
                              className="flex items-center cursor-pointer relative z-10"
                              onClick={() => {
                                setShowCityModal(true);
                                setShowSearchModal(false);
                              }}
                            >
                              <div
                                className="flex-1"
                                onClick={() => {
                                  pushClickEvent(
                                    'Location Select',
                                    'location_select',
                                    'Select your City',
                                    {},
                                  );
                                }}
                              >
                                <div className="flex items-center">
                                  <img
                                    src={locationIcon}
                                    alt="img"
                                    className="inline-block mr-1"
                                  />
                                  <p className="text-sm md:text-base text-black font-medium">
                                    {props.router.params.city_name
                                      ? props.router.params.city_name
                                      : localStorage.getItem(
                                        'publicUserSelectMePassCity',
                                      )
                                        ? localStorage.getItem(
                                          'publicUserSelectMePassCity',
                                        )
                                        : 'Select your City'}
                                  </p>
                                </div>
                              </div>
                              <div className="flex-none">
                                <img
                                  src={downArrow}
                                  className="inline-block ml-3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-none">
                      <div className="flex gap-2">
                        <ProfileMenu
                          notification={notification}
                          authUserDetails={authUserDetails}
                          user={user}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-end">
                    <div className="block lg:hidden">
                      <div className="flex">
                        <div className="relative mr-2">
                          <div
                            onClick={() => setShowSearchModal(true)}
                            className="size-[34px] md:size-[45px] flex items-center justify-center cursor-pointer search-border-liner-gradient"
                          >
                            <i class="fa-solid fa-magnifying-glass text-[#101010]"></i>
                          </div>
                        </div>
                        <div className="relative mr-2">
                          <div
                            className="size-[34px] md:size-[45px] flex items-center justify-center cursor-pointer search-border-liner-gradient"
                            onClick={() => {
                              setShowCityModal(true);
                            }}
                          >
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => {
                                setShowCityModal(true);
                              }}
                            >
                              <i className="fa-solid fa-location-dot text-[#101010]"></i>
                              {/* <p className="text-sm md:text-lg text-black font-normal">
                                {props.router.params.city_name
                                  ? props.router.params.city_name
                                  : ""}
                              </p> */}
                            </div>
                          </div>
                        </div>
                        <ProfileMenu
                          notification={notification}
                          authUserDetails={authUserDetails}
                          user={user}
                        />
                      </div>
                    </div>
                    {/* {sidebarToggle && ( */}
                    <SidebarMenu
                      sidebarArray={sidebarArray}
                      toggleSubmenu={toggleSubmenu}
                      handleActiveItem={handleActiveItem}
                      activeItem={activeItem}
                      openSubmenu={openSubmenu}
                      isResponsive={true}
                      handleSidebarToggle={handleSidebarToggle}
                      sidebarToggle={sidebarToggle}
                      handleNavLinkClick={handleNavLinkClick}
                      toggleSubmenuIndex={toggleSubmenuIndex}
                      openSubmenuIndex={openSubmenuIndex}
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* modal city */}
      {isShowCityModal && !isEmpty(cityList) ? (
        <SelectCityModal
          isShowCityModal={isShowCityModal}
          setShowCityModal={setShowCityModal}
          cityList={cityList}
          handleOnSelect={handleOnSelect}
          props={props}
          detectYourLocation={detectYourLocation}
          errorLocation={errorLocation}
          loaderLocation={loaderLocation}
          setShowSearchModal={setShowSearchModal}
          authUserDetails={authUserDetails}
        />
      ) : null}

      {/* Search modal */}
      {isShowSearchModal ? (
        <div className="fixed z-30 overflow-y-auto top-0 md:top-24 w-full left-0">
          <div
            className="flex md:items-center justify-center min-h-screen md:pt-4 md:px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <div
              className="inline-block align-center bg-white md:rounded-[32px] text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] lg:w-[800px]"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="relative z-10 block md:hidden">
                <nav className="bg-white w-full header-border-liner-gradient">
                  <div className="container mx-auto py-[15px]">
                    <div className="relative flex md:h-16 items-center justify-between gap-3 md:gap-14">
                      <div className="w-[163.63px]">
                        <Link to="/home">
                          <img
                            className="w-auto"
                            src={logo}
                            alt="Your Company name"
                          />
                        </Link>
                      </div>
                      <div className="flex-auto items-center w-full">
                        <div>
                          <div className="flex items-center justify-end">
                            <div className="block lg:hidden">
                              <div className="flex">
                                <div className="relative mr-2">
                                  <div
                                    onClick={() => {
                                      setShowSearchModal(true);
                                      setShowCityModal(false);
                                    }}
                                    className="size-[34px] md:size-[45px] flex items-center justify-center cursor-pointer search-border-liner-gradient"
                                  >
                                    <i class="fa-solid fa-magnifying-glass text-[#101010]"></i>
                                  </div>
                                </div>
                                <div className="relative mr-2">
                                  <div
                                    className="size-[34px] md:size-[45px] flex items-center justify-center cursor-pointer search-border-liner-gradient"
                                    onClick={() => {
                                      setShowCityModal(true);
                                      setShowSearchModal(false);
                                    }}
                                  >
                                    <div
                                      className="flex items-center cursor-pointer"
                                      onClick={() => {
                                        setShowCityModal(true);
                                      }}
                                    >
                                      <i className="fa-solid fa-location-dot text-[#101010]"></i>
                                      {/* <p className="text-sm md:text-lg text-black font-normal">
                        {props.router.params.city_name
                          ? props.router.params.city_name
                          : ""}
                      </p> */}
                                    </div>
                                  </div>
                                </div>
                                <ProfileMenu
                                  notification={notification}
                                  authUserDetails={authUserDetails}
                                  user={user}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="bg-white p-5 md:p-[30px] h-screen md:h-full">
                <div className="flex gap-3">
                  <div className="flex-1">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="search events/concerts/gigs"
                        value={searchEvent}
                        onChange={(e) => setSearchEvent(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleEventSearchChange();
                          }
                        }}
                        className="w-full bg-white border border-[#5D5D5D] rounded-lg p-4 text-[#101010] text-base font-normal outline-none  focus:border-[#5D5D5D] placeholder:text-[#5D5D5D]"
                      />
                      <span
                        onClick={() => handleEventSearchChange()}
                        className="cursor-pointer bg-white p-2 absolute top-1/2 right-3 -translate-y-1/2"
                      >
                        <i className="fa fa-search text-[#5D5D5D] text-xl"></i>
                      </span>
                    </div>
                  </div>
                  <div className="flex-none">
                    <button
                      type="button"
                      className="p-[10px] size-[57px] rounded-[4px] border border-[#5D5D5D] flex items-center justify-center"
                      onClick={() => {
                        setShowSearchModal(false);
                        setShowCityModal(false);
                      }}
                    >
                      <i className="fas fa-times text-2xl text-[#5A5A5A]"></i>
                    </button>
                  </div>
                </div>
                <div className="flex mt-8 mb-6">
                  <img
                    src={calendarGradient}
                    alt="calendar"
                    className="inline-block mr-3"
                  />
                  <h3 className="text-xl font-semibold text-tittle-liner-gradient-color">
                    Trending events
                  </h3>
                </div>
                <div className="max-h-[70vh] md:max-h-[53vh] overflow-auto pr-2">
                  {!isEmpty(cmsData) &&
                    cmsData.map((singleCms, index) => (
                      <>
                        {index < 6 && !isEmpty(singleCms.event) ? (
                          <>
                            <Link
                              to={'/events/' + singleCms.event.slug}
                              onClick={() => setShowSearchModal(false)}
                            >
                              <div
                                className="bg-white border border-[#E7E7E7] p-4 rounded-lg mb-3 hover:border-[#CB2129]">
                                <div className="flex gap-4">
                                  <div className="flex-1">
                                    <p className="text-sm md:text-base text-[#101010] font-base">
                                      {singleCms &&
                                        singleCms.event &&
                                        singleCms.event.name}
                                    </p>
                                  </div>
                                  <div className="flex-none">
                                    <i className="fas fa-chevron-right mt-[2px]"></i>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default withRouter(Header);
