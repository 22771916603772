import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import eventDefaultBanner from '../../assets/images/default/event-image.jpg';
import rank from '../../assets/images/small/rank.png';
import withRouter from '../../components/Common/withRouter';
import EventView from '../../components/Event/View';
import api from '../../constants/api';
import { clearData, getData } from '../../store/AppMaster/actions';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, FreeMode, Pagination, Mousewheel } from 'swiper/modules';
import SeoDetails from '../../seo/SeoDetails';
import banner from '../../assets/light_theme_images/event-category-banner-mg.png';
import locationIcon from '../../assets/light_theme_images/location-icon.svg';
import calendar from '../../assets/light_theme_images/calender.svg';
import clock from '../../assets/light_theme_images/clock.svg';
import star from '../../assets/light_theme_images/star.svg';
import { convertToAmPm, convertToDate } from '../../common/commonFunction';
import { pushClickEvent, pushViewEvent } from '../../seo/gtevents';
import ChipFilter from '../Home/ChipFilter';

const EventCategory = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [filterData, setFilterData] = useState({
    start: 0,
    limit: 12,
    search: '',
    sort_by: '',
    sort_order: 'desc',
    status: '',
    category_id: '',
  });
  const [events, setEvents] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [totalEventsCount, setTotalEventsCount] = useState(0); // New state for total count of events
  const [cmsData, setCmsData] = useState('');
  const [isCmsData, setIsCmsData] = useState(false);
  const [isCategoryData, setIsCategoryData] = useState(false);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  const getListData = useCallback(
    (status, search, start) => {
      setIsUpdateData(true);
      dispatch(clearData());
      filterData.status = status ? status : '';
      filterData.search = search ? search : filterData.search;
      filterData.start = start ? start : 0;
      filterData.category_slug = props.router.params.category_name
        ? props.router.params.category_name
        : '';
      filterData.city = props.router.params.city_name
        ? props.router.params.city_name
        : '';
      if (!isLoadMore) {
        setEvents([]);
        setTotalEventsCount(0);
      }
      dispatch(getData(filterData, props.router.navigate, api.EventList));
    },
    [dispatch, filterData, props.router.navigate],
  );
  useEffect(() => {
    if (location.search) {
      setIsLoadMore(false);
      const queryParams = new URLSearchParams(location.search);
      const searchParam = queryParams.get('search');
      filterData.search = searchParam;
      getListData('', searchParam, '');
      setCmsData('');
      CmsPage();
    }
    if (props.router.params.city_name) {
      setIsLoadMore(false);
      getListData('', '', '');
      setCmsData('');
      CmsPage();
    }
  }, [location, location.search, props.router.params.city_name]);

  const CmsPage = useCallback(() => {
    setIsCmsData(true);
    let requestCmsData = {
      section_titles: 'trending',
      is_page: 'Event',
    };
    if (props.router.params.city_name) {
      requestCmsData.city = props.router.params.city_name;
    }
    if (props.router.params.category_name) {
      requestCmsData.category = props.router.params.category_name;
    }
    dispatch(clearData());
    dispatch(getData(requestCmsData, props.router.navigate, api.CmsPage));
  }, [dispatch, props.router.navigate]);

  const getCategoryList = useCallback(() => {
    setIsCategoryData(true);
    const requestData = {};
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.CategoryList));
  }, [dispatch, props.router.navigate]);

  useEffect(() => {
    if (isApiCall === 0) {
      setIsLoadMore(false);
      setCmsData('');
      getListData();
      //Cms Page Data For Rank
      CmsPage();
      //Get Category List
      // getCategoryList();
      setIsApiCall(1);
    }
  }, [isApiCall, getListData, CmsPage, getCategoryList]);

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.eventList) && isUpdateData) {
      setIsUpdateData(false);
      if (isLoadMore) {
        setEvents((prevEvents) => [...prevEvents, ...data.eventList]);
      } else {
        setEvents(data.eventList);
      }
      setTotalEventsCount(data.filter_count); // Assuming the API returns the total count of events
    }
  }, [data, isUpdateData]);

  useEffect(() => {
    if (
      !isEmpty(data) &&
      !isEmpty(data.cmsPage) &&
      !isEmpty(data.cmsPage.trending) &&
      isCmsData
    ) {
      setCmsData(data.cmsPage.trending);
      setIsCmsData(false);
    }
    if (!isEmpty(data) && !isEmpty(data.categoryList) && isCategoryData) {
      setCategories(data.categoryList);
      setIsCategoryData(false);
    }
  }, [data, isCmsData, isCategoryData]);

  const loadMore = () => {
    const start = filterData.start + filterData.limit;
    filterData.start = start;
    getListData(filterData.status, filterData.search, start);
  };

  const swiperRef = useRef(null);

  // Handle click anywhere on the page to stop Swiper autoplay
  const handlePageClick = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };
  return (
    <>
      <SeoDetails
        title={
          cmsData && cmsData[0] && cmsData[0].seo_title
            ? cmsData[0].seo_title
            : ''
        }
        description={
          cmsData && cmsData[0] && cmsData[0].short_description
            ? cmsData[0].short_description
            : ''
        }

        ogImage={
          cmsData && cmsData[0] && cmsData[0].seo_image && cmsData[0].seo_image
            ? cmsData[0].seo_image
            : ''
        }
      />
      <div onClick={handlePageClick}>
        <Swiper
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          slidesPerView={1}
          freeMode={true}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, FreeMode, Pagination]}
          className="mySwiper banner-slider event-category-slider"
        >
          {!isEmpty(cmsData) &&
            cmsData.map((singleCms, index) => (
              <>
                {index < 3 && !isEmpty(singleCms.event) ? (
                  <SwiperSlide>
                    <div
                      className="relative w-full min-h-[620px] md:min-h-full md:h-full bg-cover pt-14 md:pt-[135px] pb-10 md:pb-20">
                      <div
                        className="absolute inset-0 -z-10"
                        style={{
                          backgroundImage: `url(${singleCms.event.banner_image
                            ? singleCms.event.banner_image
                            : eventDefaultBanner
                          })`,
                          filter: 'blur(30px)',
                          opacity: '0.5px',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }}
                      ></div>
                      <div className="absolute inset-0 bg-[#00000033] -z-10"></div>
                      <div className="container">
                        <div
                          className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-10 lg:gap-32 mt-5 md:mt-0 items-end md:items-center">
                          <div className="col-span-1 xl:col-span-2">
                            <div className="relative">
                              <h1
                                className="text-lg md:text-2xl lg:text-[32px] font-bold text-white text-outline tracking-[0.2rem]">
                                #{index + 1} Trending
                              </h1>
                              <h2
                                className="text-lg md:text-2xl lg:text-[32px] font-bold text-white absolute -left-[2px] top-0 right-0 bottom-0 tracking-[0.2rem]">
                                #{index + 1} Trending
                              </h2>
                            </div>
                            <div>
                              <Link
                                to={'/events/' + singleCms.event.slug}
                                onClick={() => pushViewEvent('EventPageBannerView', 'click_eventView', 'View Event', singleCms.event)}
                                className="relative block "
                              >
                                <h2
                                  className="text-2xl lg:text-4xl xl:text-7xl font-body font-bold text-white text-outline-main xl:leading-[100px]">
                                  {singleCms && singleCms.event && index + 1}{' '}
                                  {singleCms &&
                                    singleCms.event &&
                                    singleCms.event.name}
                                </h2>
                                <h3
                                  className="text-2xl lg:text-4xl xl:text-7xl font-body font-bold text-white w-full absolute -left-[2px] top-0 right-0 bottom-0 xl:leading-[100px]">
                                  {singleCms && singleCms.event && index + 1}{' '}
                                  {singleCms &&
                                    singleCms.event &&
                                    singleCms.event.name}
                                </h3>
                              </Link>
                            </div>
                            <p className="text-[#FFFFFF] text-base font-normal pt-4 leading-6 line-clamp-2 ">
                              {singleCms &&
                              singleCms.event &&
                              singleCms.event.short_description
                                ? singleCms &&
                                singleCms.event &&
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: singleCms.event.short_description,
                                  }}
                                />
                                : ''}
                            </p>
                            <div className="block lg:flex gap-4 pt-3 md:pt-5">
                              {singleCms.event.dates &&
                              singleCms.event.dates[0] ? (
                                <h4 className="text-base md:text-[20px] text-[#FFFFFF] font-normal font-body">
                                  <img
                                    src={calendar}
                                    alt="calendar"
                                    className="inline-block mr-2 -mt-[4px]"
                                  />
                                  {convertToDate(
                                    singleCms.event.dates[0]['date'],
                                  )}
                                </h4>
                              ) : (
                                ''
                              )}
                              <div className="flex gap-4 pt-3 lg:pt-0">
                                {singleCms.event &&
                                singleCms.event.dates &&
                                singleCms.event.dates[0] &&
                                singleCms.event.dates[0]['start_time'] ? (
                                  <h5 className="text-base md:text-[20px] text-[#FFFFFF] font-normal font-body">
                                    <img
                                      src={clock}
                                      alt="clock"
                                      className="inline-block mr-2 -mt-[4px]"
                                    />
                                    {convertToAmPm(
                                      singleCms.event.dates[0]['start_time'],
                                    )}
                                  </h5>
                                ) : (
                                  ''
                                )}
                                {singleCms.event.rating > 0 ? (
                                  <h5 className="text-base md:text-[20px] text-[#FFFFFF] font-normal font-body">
                                    <img
                                      src={star}
                                      alt="star"
                                      className="inline-block mr-2 size-5 -mt-[4px]"
                                    />
                                    {singleCms.event.rating}
                                  </h5>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            {singleCms.event.dates &&
                            singleCms.event.dates[0] &&
                            singleCms.event.dates[0]['address'] ? (
                              <h5 className="text-base md:text-[20px] text-[#FFFFFF] font-normal font-body mt-3">
                                <img
                                  src={locationIcon}
                                  alt="location"
                                  className="inline-block mr-2"
                                />
                                {singleCms.event.dates[0]['address']}
                              </h5>
                            ) : (
                              ''
                            )}

                            <div className="hidden md:inline-block mt-10">
                              {singleCms.event.pass_created === true &&
                              singleCms.event.pass_available === true ? (
                                <Link
                                  to={`/events/${singleCms.event.slug}/book-tickets`}
                                  onClick={() => {
                                    pushClickEvent('EventPage_Banner_Book', 'click_bookNow', 'Book Now', singleCms.event);
                                  }}
                                >
                                  <button
                                    className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center">
                                    Book Now
                                  </button>
                                </Link>
                              ) : singleCms.event.pass_created === false ? (
                                <Link to={`/events/${singleCms.event.slug}`}
                                      onClick={() => {
                                        pushViewEvent('EventPageBannerView', 'click_eventView', 'View Event', singleCms.event);
                                      }}
                                >
                                  <button
                                    className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center">
                                    View Event
                                  </button>
                                </Link>
                              ) : singleCms.event.pass_available === false &&
                              singleCms.event.pass_created === true ? (
                                <button
                                  className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center w-[120px]"
                                  disabled
                                >
                                  Sold
                                </button>
                              ) : null}
                            </div>
                          </div>
                          <div className="text-center md:text-right mt-4 md:mt-0">
                            <Link to={'/events/' + singleCms.event.slug}
                            >
                              <img
                                src={
                                  singleCms.event.banner_image
                                    ? singleCms.event.banner_image
                                    : banner
                                }
                                className="w-full rounded-xl aspect-[9/16] object-fill"
                                alt=""
                                onClick={() => pushViewEvent('EventPageBannerView', 'click_eventView', 'View Event', singleCms.event)}
                              />
                            </Link>
                            <div className="md:hidden inline-block text-center mt-10 mb-10">
                              {singleCms.event.pass_created === true &&
                              singleCms.event.pass_available === true ? (
                                <Link
                                  to={`/events/${singleCms.event.slug}/book-tickets`}
                                  onClick={() => {
                                    pushClickEvent('EventPage_Banner_Book', 'click_bookNow', 'Book Now', singleCms.event);
                                  }}
                                >
                                  <button
                                    className="btn-liner-gradient text-white text-base text-center font-semibold py-3 px-6 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center w-[120px]">
                                    Book Now
                                  </button>
                                </Link>
                              ) : singleCms.event.pass_created === false ? (
                                <Link to={`/events/${singleCms.event.slug}`}
                                      onClick={() => {
                                        pushViewEvent('EventPageBannerView', 'click_eventView', 'View Event', singleCms.event);
                                      }}
                                >
                                  <button
                                    className="btn-liner-gradient text-white text-base text-center font-semibold py-3 px-6 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center">
                                    View Event
                                  </button>
                                </Link>
                              ) : singleCms.event.pass_available === false &&
                              singleCms.event.pass_created === true ? (
                                <button
                                  className="btn-liner-gradient text-white text-base text-center font-semibold py-3 px-6 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center w-[120px]"
                                  disabled
                                >
                                  Sold
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ) : (
                  ''
                )}
              </>
            ))}
        </Swiper>
      </div>
      <ChipFilter props={props} />
      <section className="bg-white py-16">
        <div className="container">
          <div className="flex lg:flex-wrap gap-3 md:gap-5 overflow-auto mb-16">
            {/* {categories &&
              categories.map((singleCategory, index) => (
                <button
                  key={index}
                  onClick={() => {
                    if (!isEmpty(props.router.params.city_name)) {
                      setIsApiCall(0);
                      props.router.navigate(
                        `/city/${props.router.params.city_name}/category/${singleCategory.slug}/events`
                      );
                    } else {
                      setIsApiCall(0);
                      props.router.navigate(
                        `/category/${singleCategory.slug}/events`
                      );
                    }
                  }}
                  className={
                    props.router.params.category_name === singleCategory.slug
                      ? "py-1 lg:py-2 px-4 lg:px-[20px] text-base lg:text-2xl border duration-300 ease-in-out rounded-full text-nowrap bg-[#CB2129] text-white border-[#CB2129] font-semibold"
                      : "py-1 lg:py-2 px-4 lg:px-[20px] text-base lg:text-2xl border duration-300 ease-in-out rounded-full text-nowrap bg-white border-[#101010] text-[#101010] font-medium"
                  }
                >
                  {singleCategory.name}
                </button>
              ))} */}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 lg:gap-5">
            {events &&
              events.map((singleEvent, index) => (
                <Link to={'/events/' + singleEvent.slug}>
                  <EventView singleEvent={singleEvent} key={index} />
                </Link>
              ))}
          </div>
          {events.length === 0 && (
            <div className="text-center text-black">
              <h2 className="text-2xl font-body font-normal text-center">
                No Events Found
              </h2>
            </div>
          )}
          {events.length < totalEventsCount && (
            <div className="flex justify-center mt-9">
              <button
                onClick={
                  () => {
                    loadMore();
                    setIsLoadMore(true);
                  }}
                className="border border-[#CB2129] text-[#CB2129] text-lg text-center font-semibold py-2 px-4 rounded-lg duration-1000 ease-in-out h-[48px] flex items-center justify-center"
              >
                Load more
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default withRouter(EventCategory);