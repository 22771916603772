import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '../../constants/api';
import { addUpdateNewData, getData } from '../../store/AppMaster/actions';
import { convertToAmPm, convertToDate } from '../../common/commonFunction';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from 'swiper/modules';
import { Link } from 'react-router-dom';
import img from '../../assets/images/App-Store.png';
import img1 from '../../assets/images/google-play.png';
import PriceDetail from './PriceDetail';
import SponserList from './SponserList';
import calendarIcon from '../../assets/light_theme_images/calender-dark.svg';
import clockIcon from '../../assets/light_theme_images/clock-dark.svg';
import passIcon from '../../assets/light_theme_images/pass-icon.svg';
import locationIcon from '../../assets/light_theme_images/location-red.svg';
import ReviewPopup from '../../components/QrCode/Index';
import shareYourExperience from '../../assets/light_theme_images/share-your-experience.svg';
import BlurImage from '../../assets/images/default/BlurImage.png';

const SeasonMePass = ({
                        seasonArray,
                        passDetails,
                        textColor,
                        primaryColor,
                        secondaryColor,
                        backGroundColor,
                        RFIDImage,
                        ExpiredImage,
                        DeletedImage,
                        ScannedImage,
                        SharedImage,
                        BlurImage,
                        map,
                        toggleSplitModal,
                        setShowTermsModal,
                        pricingDetails,
                        sponsorList,
                        setUrlString,
                        setPassAvailableQuantity,
                        distributionType,
                        meetLink,
                        openMeetLink,
                        bannerImage,
                        artistDetail,
                        eventDetail,
                        ratingDetail,
                      }) => {
  console.log(artistDetail, eventDetail);
  const totalCircles = 10;
  const [showReviewModal, setShowReviewModal] = useState(false);
  const circles = new Array(totalCircles).fill(0);
  const toggleReviewModal = () => {
    setShowReviewModal(!showReviewModal);
  };
  return (
    <>
      <div
        className={`rounded-t-[4px] border border-[#CB2129]`}
        style={{ backgroundColor: backGroundColor }}
      >
        {!isEmpty(bannerImage) && bannerImage.length > 0 && (
          <Swiper
            cssMode={true}
            mousewheel={true}
            keyboard={true}
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
            className="mySwiper gallary-slider pass-ticket-slider !pb-5"
          >
            {bannerImage && (
              <>
                {bannerImage.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={item ? item : hBannerImageDefault}
                      alt=""
                      className="rounded-md aspect-[2.336/1] w-full"
                    />
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        )}
        <div className="p-[15px]">
          <h1
            style={{ color: textColor }}
            className={`text-base font-semibold capitalize mb-1`}
          >
            {passDetails && passDetails.event && passDetails.event.name}
          </h1>
          <h2
            style={{
              background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
            className={`font-bold text-xl uppercase break-all`}
          >
            {passDetails.event_pass && passDetails.event_pass.name}
          </h2>
          <div className="flex">
            <div className="flex-1"></div>
          </div>
        </div>
        <div
          style={{ borderColor: primaryColor }}
          className="border-b-2 border-dashed  my-4 mx-4 relative"
        >
          <span
            style={{ borderColor: primaryColor }}
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[29px] bg-[#f4f4f4] border  before:-left-[3px] before:absolute before:w-[14px] before:h-[21px] before:bg-[#f4f4f4] box-border flex items-center p-1`}
          ></span>
          <span
            style={{ borderColor: primaryColor }}
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[29px] bg-[#f4f4f4] border  before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[14px] before:h-[21px] box-border flex items-center p-1`}
          ></span>
        </div>

        <div className="p-[15px]">
          {seasonArray.length > 0 && (
            <div
              className="mt-4 mb-8"
              style={{ backgroundColor: backGroundColor }}
            >
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className={`mySwiper season-pass-updated season-pass ${backGroundColor === '#000000'
                  ? 'season-pass-white'
                  : 'season-pass-black'
                }`}
              >
                {seasonArray.map((seasonSingle, indexSeason) => (
                  <SwiperSlide key={indexSeason}>
                    <div className="px-5">
                      {seasonSingle && passDetails.user_name && (
                        <h3
                          style={{
                            color: textColor,
                          }}
                          className={`text-xl font-bold mb-2 text-center capitalize text-[${textColor}]`}
                        >
                          {passDetails.user_name}
                        </h3>
                      )}
                      {seasonSingle && seasonSingle.event_date && (
                        <div className="flex gap-1.5 mb-2 justify-center">
                          <i
                            style={{
                              background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                            }}
                            class="fa-solid fa-calendar-days mt-[2px] text-[12px]"
                          ></i>
                          <p
                            style={{ color: textColor }}
                            className="text-[12px] font-normal"
                          >
                            {convertToDate(seasonSingle.event_date, 'pass')}
                          </p>
                        </div>
                      )}
                      {passDetails &&
                        passDetails.event_pass &&
                        passDetails.event_pass.pass_date &&
                        passDetails.event_date &&
                        passDetails.event_date.start_time && (
                          <div className="flex justify-center gap-2.5 mb-2">
                            <p
                              style={{ color: textColor }}
                              className="text-[12px] font-normal"
                            >
                              <i
                                style={{ color: primaryColor }}
                                className="fa-solid fa-clock mr-[4px]"
                              ></i>
                              {convertToAmPm(passDetails.event_date.start_time)}
                            </p>
                            {/* <span style={{ color: textColor }} className="font-bold"> <i class="fa-solid fa-credit-card mr-[2px]"></i> 500</span> */}
                          </div>
                        )}
                    </div>
                    <div className="max-w-[70%] m-auto">
                      {seasonSingle.available_quantity > 0 && (
                        <>
                          <div className="flex justify-center mb-2.5">
                            <div
                              style={{
                                border: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                color: textColor,
                              }}
                              className={`font-bold text-xl w-[80px] h-[29px] flex items-center justify-center text-[${textColor}] uppercase relative break-all qty-border-liner-gradient bg overflow-hidden bg-transparent`}
                            >
                              {seasonSingle.available_quantity}
                              <sub className="ml-1 text-[8px] font-normal capitalize -bottom-[5px]">
                                Qty.
                              </sub>
                              <span
                                className="absolute -top-[5px] -right-[6px] size-[14px] rounded-full z-10 border"
                                style={{
                                  backgroundColor: backGroundColor,
                                  borderColor: primaryColor,
                                }}
                              ></span>
                              <span
                                className="absolute -bottom-[5px] -right-[6px] size-[14px] rounded-full z-10 border"
                                style={{
                                  backgroundColor: backGroundColor,
                                  borderColor: primaryColor,
                                }}
                              ></span>
                              <span
                                className="absolute -top-[5px] -left-[6px] size-[14px] rounded-full z-10 border"
                                style={{
                                  backgroundColor: backGroundColor,
                                  borderColor: primaryColor,
                                }}
                              ></span>
                              <span
                                className="absolute -bottom-[5px] -left-[6px] size-[14px] rounded-full z-10 border"
                                style={{
                                  backgroundColor: backGroundColor,
                                  borderColor: primaryColor,
                                }}
                              ></span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      style={{ backgroundColor: primaryColor }}
                      className="max-w-[70%] m-auto p-1.5 rounded-[8px] mb-4"
                    >
                      {/* Qr Image Display */}
                      {seasonSingle &&
                      seasonSingle.status !== null &&
                      (seasonSingle.status === 'Expired' ||
                        seasonSingle.status === 'Deleted' ||
                        seasonSingle.status === 'Scann' ||
                        seasonSingle.available_quantity === 0) ? (
                        <>
                          {seasonSingle.status === 'Expired' ? (
                            <img
                              src={ExpiredImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : seasonSingle.status === 'Deleted' ? (
                            <img
                              src={DeletedImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : seasonSingle.status === 'Scann' ? (
                            <img
                              src={ScannedImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : seasonSingle.available_quantity === 0 ? (
                            <img
                              src={SharedImage}
                              alt="img"
                              className="inline-block my-4 rounded-[8px]"
                            />
                          ) : passDetails &&
                          passDetails.is_payment_link == 'Yes' &&
                          passDetails.payment_link_status != 'paid' ? (
                            <img
                              src={BlurImage}
                              alt="img"
                              className="inline-block rounded-[8px] w-full"
                            />
                          ) : passDetails &&
                          passDetails.event_pass &&
                          passDetails.event_pass.is_rfid == 'Yes' ? (
                            <img
                              src={RFIDImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : (
                            <img
                              src={seasonSingle.qr_image}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          )}
                        </>
                      ) : passDetails &&
                      passDetails.is_payment_link == 'Yes' &&
                      passDetails.payment_link_status != 'paid' ? (
                        <img
                          src={BlurImage}
                          alt="img"
                          className="inline-block rounded-[8px] w-full"
                        />
                      ) : passDetails &&
                      passDetails.event_pass &&
                      passDetails.event_pass.is_rfid == 'Yes' ? (
                        <img
                          src={RFIDImage}
                          alt="img"
                          className="inline-block rounded-[8px]"
                        />
                      ) : (
                        <img
                          src={seasonSingle.qr_image}
                          alt="img"
                          className="inline-block rounded-[8px]"
                        />
                      )}
                    </div>
                    {/* Spit & Share */}
                    <div className="flex gap-3 items-center justify-center mt-2 mb-8">
                      {seasonSingle &&
                      seasonSingle.status !== null &&
                      (seasonSingle.status === 'Expired' ||
                        seasonSingle.status === 'Deleted' ||
                        seasonSingle.status === 'Scann' ||
                        seasonSingle.available_quantity === 0) ? (
                        ''
                      ) : passDetails &&
                      passDetails.event_pass &&
                      passDetails.event_pass.is_rfid == 'Yes' ? (
                        ''
                      ) : (
                        <>
                          {distributionType !== 'Online' ? (
                            <>
                              {passDetails &&
                                passDetails.is_transferable === 'Yes' && (
                                  <>
                                    {passDetails.is_payment_link == 'Yes' &&
                                    passDetails.payment_link_status !=
                                    'paid' ? (
                                      <button
                                        onClick={() => {
                                          window.open(passDetails.payment_link);
                                        }}
                                        style={{
                                          background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                        }}
                                        className={`rounded-[4px] py-2 px-4 flex items-center justify-center text-base text-white font-semibold`}
                                      >
                                        Make Payment
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => {
                                          toggleSplitModal();
                                          setUrlString(seasonSingle.url_string);
                                          setPassAvailableQuantity(
                                            seasonSingle.available_quantity,
                                          );
                                        }}
                                        style={{
                                          background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                        }}
                                        className={`rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] text-white font-medium`}
                                      >
                                        Split & Share
                                      </button>
                                    )}
                                  </>
                                )}
                            </>
                          ) : (
                            <div className="flex justify-center">
                              <button
                                onClick={() =>
                                  openMeetLink(seasonSingle.meet_link)
                                }
                                style={{
                                  background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                }}
                                className={`rounded-[4px] flex items-center justify-center text-base text-white font-semibold py-2 px-4 `}
                              >
                                {!isEmpty(seasonSingle.meet_link)
                                  ? 'Join Now'
                                  : 'Link Will Be Shared Soon'}
                              </button>
                            </div>
                          )}
                        </>
                      )}

                      <div className="flex-none">
                        {seasonSingle && !isEmpty(seasonSingle.gmap_link) ? (
                          <Link
                            to={passDetails && seasonSingle.gmap_link}
                            target="_blank"
                            className="rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location text-[#CB2129] font-medium"
                          >
                            <img
                              src={locationIcon}
                              alt="img"
                              className="inline-block mr-1"
                            />
                            Location
                          </Link>
                        ) : (
                          <>
                            {passDetails &&
                              passDetails.event &&
                              !isEmpty(passDetails.event.gmap_link) && (
                                <Link
                                  to={
                                    passDetails &&
                                    passDetails.event &&
                                    passDetails.event.gmap_link
                                  }
                                  target="_blank"
                                  style={{ borderColor: primaryColor }}
                                  className="rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location text-[#CB2129] font-medium"
                                >
                                  <img
                                    src={locationIcon}
                                    alt="img"
                                    className="inline-block mr-1"
                                  />
                                  Location
                                </Link>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          {passDetails.is_payment_link == 'Yes' &&
          passDetails.payment_link_status != 'paid' ? (
            <p
              style={{ color: textColor }}
              className={`text-[12px] text-[${textColor}]  font-semibold`}
            >
              Excited to host you! Complete your payment now to
              secure your Mepass experience
            </p>
          ) : (
            ''
          )}
          {passDetails &&
            passDetails.event_pass &&
            passDetails.event_pass.notes && (
              <details className="group bg-[#F9F9F9] rounded-[10px] p-3 mt-4">
                <summary className="select-none flex justify-between items-center cursor-pointer">
                  <p className="text-base text-[#101010] font-semibold group-open:hidden">
                    Things to know
                  </p>
                  <p className="text-base text-[#101010] font-semibold hidden group-open:block">
                    Things to know
                  </p>
                  <i className="fas fa-chevron-up hidden group-open:block text-[#000000]"></i>
                  <i className="fas fa-chevron-down group-open:hidden text-[#000000]"></i>
                </summary>

                <div>
                  <div
                    style={{ borderColor: secondaryColor }}
                    className="border-b-2 border my-3"
                  ></div>
                  <div
                    className={`${passDetails &&
                    passDetails.event_pass &&
                    passDetails.event_pass.notes &&
                    passDetails.event_pass.notes.includes('<ul>')
                      ? 'bullet-point'
                      : passDetails &&
                      passDetails.event_pass &&
                      passDetails.event_pass.notes &&
                      passDetails.event_pass.notes.includes('<ol>')
                        ? 'number-point'
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: passDetails.event_pass.notes,
                    }}
                  />
                </div>
              </details>
            )}
          <>
            {localStorage.getItem('publicMePassUser') &&
              (ratingDetail.event == 'No' || ratingDetail.artist == 'No') && (
                <>
                  <div
                    className="bg-[#F9F9F9] rounded-[10px] p-3 mb-4 mt-4 cursor-pointer"
                    onClick={() => {
                      toggleReviewModal();
                    }}
                  >
                    <div className="flex gap-3">
                      <div className="flex-1">
                        <p className="text-[15px] text-left text-[#101010] font-semibold">
                          Share your experience
                        </p>
                      </div>
                      <div className="flex-none">
                        <img
                          src={shareYourExperience}
                          alt="img"
                          className="inline-block"
                        />
                      </div>
                    </div>
                  </div>
                  <ReviewPopup
                    isVisible={showReviewModal}
                    toggleReviewModal={toggleReviewModal}
                    props={''}
                    EventDetail={eventDetail}
                    ArtistDetail={artistDetail}
                    ratingDetail={ratingDetail}
                  />
                </>
              )}

            <PriceDetail
              passDetails={passDetails}
              pricingDetails={pricingDetails}
              textColor={textColor}
              secondaryColor={secondaryColor}
              primaryColor={primaryColor}
            />
          </>
        </div>

        {!isEmpty(sponsorList) && (
          <>
            <div
              style={{ borderColor: primaryColor }}
              className="border-b-2 border-dashed my-4 mx-4 relative"
            >
              <span
                style={{ borderColor: primaryColor }}
                className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[29px] bg-[#f4f4f4] border  before:-left-[3px] before:absolute before:w-[14px] before:h-[21px] before:bg-[#f4f4f4] box-border flex items-center p-1`}
              ></span>
              <span
                style={{ borderColor: primaryColor }}
                className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[29px] bg-[#f4f4f4] border before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[14px] before:h-[21px] box-border flex items-center p-1`}
              ></span>
            </div>

            <SponserList sponsorList={sponsorList} textColor={textColor} />
          </>
        )}
        <div
          style={{ borderColor: primaryColor }}
          className="border-b-2 border-dashed my-4 mx-4 relative"
        >
          <span
            style={{ borderColor: primaryColor }}
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[29px] bg-[#f4f4f4] border before:-left-[3px] before:absolute before:w-[14px] before:h-[21px] before:bg-[#f4f4f4] box-border flex items-center p-1`}
          ></span>
          <span
            style={{ borderColor: primaryColor }}
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[29px] bg-[#f4f4f4] border before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[14px] before:h-[21px] box-border flex items-center p-1`}
          ></span>
        </div>
        <div className="px-[15px] py-[20px]">
          <h3
            style={{ color: textColor }}
            className="text-base text-center font-semibold mb-3"
          >
            Download App
          </h3>
          <div className="grid grid-cols-2 gap-5">
            {process.env.REACT_APP_PLAY_STORE_URL ? (
              <Link to={process.env.REACT_APP_PLAY_STORE_URL} target="_blank">
                <img src={img1} alt="img" className="inline-block w-full" />
              </Link>
            ) : (
              <img src={img1} alt="img" className="inline-block w-full" />
            )}
            {process.env.REACT_APP_APP_STORE_URL ? (
              <Link to={process.env.REACT_APP_APP_STORE_URL} target="_blank">
                <img src={img} alt="img" className="inline-block w-full" />
              </Link>
            ) : (
              <img src={img} alt="img" className="inline-block w-full" />
            )}
          </div>
        </div>
        <div className="relative w-full mt-2 flex justify-center">
          <div className="flex justify-between absolute w-[98%] top-[50%] -translate-y-[50%] mt-[1px]">
            {circles.map((_, index) => (
              <span
                key={index}
                style={{ borderColor: primaryColor }}
                className={`w-[30px] h-[30px] rounded-full bg-[#f4f4f4] border flex items-center justify-center p-1 -rotate-90 relative after:absolute after:w-[17px] after:h-[30px] after:bg-[#f4f4f4] after:-left-[3px] after:top-[50%] after:-translate-y-[50%] after:content-[''] ${index === 0 ? 'pl-0' : ''
                } ${index === circles.length - 1 ? 'pr-0' : ''}`}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
SeasonMePass.propTypes = {};
export default SeasonMePass;
