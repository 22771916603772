import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";
import { Link } from "react-router-dom";
import defaultSponsor from "../../assets/images/default/default-profile-silver.png";

const SponserList = ({ sponsorList, textColor }) => {
  return (
    <>
      <div className="p-[15px]">
        <h2 className={`text-base text-center text-[${textColor}] font-body font-semibold`} style={{ color: textColor }}>Our Sponsors</h2>
        <div className="grid grid-cols-3 gap-4 mt-4">
          {sponsorList &&
            sponsorList.map((sponsor, index) => (
              <Link
                to={sponsor.url ? sponsor.url : "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
                key={index}
              >
                <img
                  src={sponsor.image ? sponsor.image : defaultSponsor}
                  alt="sponsor"
                  className="inline-block aspect-[1/1] rounded-[4px] object-fill"
                />
                <p
                  className={`text-[12px] text-[${textColor}] font-body font-normal mt-2`}
                  style={{ color: textColor }}
                >
                  {sponsor.name}
                </p>
              </Link>
            ))}
        </div>
      </div>
    </>
  );
};
SponserList.propTypes = {};
export default SponserList;
