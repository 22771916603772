// export default EventUpComing
import React from "react";
import { useNavigate } from "react-router-dom";
import EventView from "../../Event/View";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { isEmpty } from "lodash";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

const EventUpComing = ({ events }) => {
  const navigate = useNavigate();

  const handleEventClick = (slug) => {
    navigate(`/events/${slug}`);
    window.location.reload();
  };

  return (
    <>
      {!isEmpty(events) ? (
        <div>
          <h4 className="text-2xl md:text-[32px] font-semibold text-[#212121] ">
            You may love these too
          </h4>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2.1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2.1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper mt-5 gallary-slider !pb-10"
          >
            {events &&
              events.map((singleEvent) => (
                <SwiperSlide key={singleEvent.slug}>
                  <Link to={"/events/" + singleEvent.slug}>
                    <EventView singleEvent={singleEvent} />
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default EventUpComing;
