import React from "react";
import bannerImageDefault from "../../assets/images/default/event-image.jpg";
import icon4 from "../../assets/images/small/megaphone-dark.png";
import icon2 from "../../assets/images/small/megaphone.png";
import icon3 from "../../assets/images/small/percentage-red.png";
import icon5 from "../../assets/images/small/percentage-yellow.png";
import calendarIcon from "../../assets/light_theme_images/Calendar-Clock-light.svg";
import locationLightIcon from "../../assets/light_theme_images/location-light.svg";
import offerLightIcon from "../../assets/light_theme_images/offer-light-icon.svg";
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import { Link } from "react-router-dom";
import withRouter from "../Common/withRouter";
import { pushViewEvent } from "../../seo/gtevents";

const EventView = ({ singleEvent, props, section_title }) => {
  return (
    <>
      <div key={singleEvent.id}>
        <div class="relative group">
          <img
            src={
              singleEvent.banner_image
                ? singleEvent.banner_image
                : bannerImageDefault
            }
            alt=""
            className="w-full aspect-[9/16] object-fill rounded-lg"
            onClick={() => {
              const category =
                section_title === "Popular Events Near You"
                  ? "Popular Events"
                  : section_title === "Events With Exclusive Offers"
                  ? "Exclusive Offer Events"
                  : section_title === "Upcoming Events Near You"
                  ? "Upcoming Events"
                  : "Other Events";
              pushViewEvent("View Event", category, "View Event", singleEvent);
            }}
          />
          <div className="absolute bottom-0 left-0 right-0 w-full duration-1000 ease-in-out lg:hidden group-hover:block">
            {/* <div className="flex items-center gap-2 py-[14px] px-1.5 md:px-4">
            {singleEvent.rating > 0 ? (
              <div>
                <div class="bg-[#F0BA0E] py-[2px] px-2 rounded-full">
                  <p class="text-black text-[12px] md:text-[14px] font-body whitespace-nowrap">
                    <i className="fas fa-star mr-2 text-black"></i>
                    <b>{singleEvent.rating}</b>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {singleEvent.offer > 0 ? (
              <div class="bg-[#CB2129] py-[2px] px-2 rounded-full flex items-center justify-center">
                <p class="text-white text-[12px] md:text-base font-body whitespace-nowrap">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-4 md:size-5 mr-1 inline-block"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m8.99 14.993 6-6m6 3.001c0 1.268-.63 2.39-1.593 3.069a3.746 3.746 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043 3.745 3.745 0 0 1-3.068 1.593c-1.268 0-2.39-.63-3.068-1.593a3.745 3.745 0 0 1-3.296-1.043 3.746 3.746 0 0 1-1.043-3.297 3.746 3.746 0 0 1-1.593-3.068c0-1.268.63-2.39 1.593-3.068a3.746 3.746 0 0 1 1.043-3.297 3.745 3.745 0 0 1 3.296-1.042 3.745 3.745 0 0 1 3.068-1.594c1.268 0 2.39.63 3.068 1.593a3.745 3.745 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.297 3.746 3.746 0 0 1 1.593 3.068ZM9.74 9.743h.008v.007H9.74v-.007Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                    />
                  </svg>

                  <b>{singleEvent.offer}% off</b>
                </p>
              </div>
            ) : (
              ""
            )}
          </div> */}
            <div className="bg-[#101010] rounded-b-lg py-[6px] md:py-2 lg:py-[14px] px-1 md:px-2 lg:px-4 pass-border-liner-gradient"
              onClick={() => {
                const category =
                  section_title === "Popular Events Near You"
                    ? "Popular Events"
                    : section_title === "Events With Exclusive Offers"
                    ? "Exclusive Offer Events"
                    : section_title === "Upcoming Events Near You"
                    ? "Upcoming Events"
                    : "Other Events";
                pushViewEvent("View Event", category, "View Event", singleEvent);
              }}>
            <div>
                  {singleEvent.dates && singleEvent.dates[0] ? (
                    <p className="text-white text-[9px] md:text-[13px] lg:text-[14px] font-normal">
                      <img
                        src={calendarIcon}
                        alt="location"
                        className="inline-block mr-[3px] md:mr-2 size-[14px] md:size-6 -mt-[2px]"
                      />
                      {singleEvent.dates && singleEvent.dates[0]
                        ? singleEvent.dates[0]["date"] &&
                          convertToDate(singleEvent.dates[0]["date"])
                        : ""}
                      {singleEvent &&
                      singleEvent.dates &&
                      singleEvent.dates[0] &&
                      singleEvent.dates[0]["start_time"]
                        ? " | "
                        : ""}
                      {singleEvent &&
                      singleEvent.dates &&
                      singleEvent.dates[0] &&
                      singleEvent.dates[0]["start_time"]
                        ? convertToAmPm(singleEvent.dates[0]["start_time"])
                        : ""}
                    </p>
                  ) : (
                    ""
                  )}
                    {singleEvent.dates &&
                    singleEvent.dates[0] &&
                    singleEvent.dates[0]["address"] ? (
                      <p className="text-white text-[9px] md:text-[13px] lg:text-[14px] font-normal mt-1 md:mt-2">
                        <img
                          src={locationLightIcon}
                          alt="location"
                          className="inline-block mr-[3px] md:mr-2 size-[14px] md:size-6"
                        />
                        {singleEvent.dates[0]["address"]}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
               {/* <div className="flex-none">
                  {singleEvent.pass_created === true &&
                  singleEvent.pass_available === true ? (
                    <Link to={`/events/${singleEvent.slug}/book-tickets`}>
                      <button className="btn-liner-gradient text-white text-[11px] md:text-sm lg:text-base text-center font-semibold py-[6px] lg:py-[10px] px-2  md:px-3 lg:px-7 rounded-lg duration-1000 ease-in-out h-[35px] md:h-[48px] flex items-center justify-center">
                        Book Now
                      </button>
                    </Link>
                  ) : singleEvent.pass_created === false ? (
                    <Link to={`/events/${singleEvent.slug}`}>
                      <button className="btn-liner-gradient text-white text-[11px] md:text-sm lg:text-base text-center font-semibold py-[6px] lg:py-[10px] px-2  md:px-3 lg:px-7 rounded-lg duration-1000 ease-in-out h-[35px] md:h-[48px] flex items-center justify-center">
                        View Event
                      </button>
                    </Link>
                  ) : singleEvent.pass_available === false &&
                    singleEvent.pass_created === true ? (
                    <button
                      className="btn-liner-gradient text-white text-[11px] md:text-sm lg:text-base text-center font-semibold py-[6px] lg:py-[10px] px-2  md:px-7 rounded-lg duration-1000 ease-in-out h-[35px] md:h-[48px] flex items-center justify-center w-[69.78px] md:w-[92.45px] lg:w-[134.22px]"
                      disabled
                    >
                      Sold
                    </button>
                  ) : null}
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(EventView);