import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const RegDate = ({
                   field,
                   handleChangeField,
                   formErrors,
                 }) => {

  return (

    <>
      <label className="mb-[10px] block text-base font-medium text-[#101010]">
        {field.display_name}
        {field.is_required == 'Yes' && <span className="text-[#CB2027]">*</span>}
      </label>
      <div className="relative">
        <ReactDatePicker
          selected={field.value}
          onChange={(date) =>
            handleChangeField(date, field)
          }
          name={field.field_name}
          value={field.value}
          placeholder={'Select ' + field.display_name}
          dateFormat="dd/MM/yyyy"
          showYearDropdown
          showMonthDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}  // Number of years to display in the dropdown
          className={'relative text-[#101010] text-base bg-white border border-[#E7E7E7] font-semibold font-body rounded-lg block w-full py-2.5 px-3'}
        />
        {formErrors && formErrors[field.field_name] && (
          <div className="text-red-500">{formErrors[field.field_name]} </div>
        )}
      </div>
    </>

  );
};
RegDate.propTypes = {};
export default RegDate;
