import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import icon from "../../assets/images/small/small-star.png";
import icon2 from "../../assets/images/small/percentage-red.png";
import banner from "../../assets/images/banner.png";
import bannerImageDefault from "../../assets/images/default/event-image.jpg";
import offer from "../../assets/images/Icons.png";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  convertToAmPm,
  convertToDate,
  numberFormatter,
} from "../../common/commonFunction";
import { Link } from "react-router-dom";
import locationIcon from "../../assets/light_theme_images/location-icon.svg";
import calendar from "../../assets/light_theme_images/calender.svg";
import clock from "../../assets/light_theme_images/clock.svg";
import star from "../../assets/light_theme_images/star.svg";
import { pushClickEvent, pushViewEvent } from "../../seo/gtevents";

const Banner = ({ events, props, index }) => {
  const swiperRef = useRef(null);

  const eventDateDisplay = (singleEvent) => {
    if (singleEvent.dates && singleEvent.dates[0]) {
      //First and Last Array Date not Same then Display Date
      if (singleEvent.dates.length > 1) {
        if (
          singleEvent.dates[0]["date"] !=
          singleEvent.dates[singleEvent.dates.length - 1]["date"]
        ) {
          //First and Last Array Date in Year Same then only Date and month Display Remove Year
          if (
            singleEvent.dates[0]["date"].split("-")[2] ==
            singleEvent.dates[singleEvent.dates.length - 1]["date"].split(
              "-"
            )[2]
          ) {
            let firstDate = convertToDate(singleEvent.dates[0]["date"]);
            let lastDate = convertToDate(
              singleEvent.dates[singleEvent.dates.length - 1]["date"]
            );
            return (
              firstDate.split(",")[0] +
              ", " +
              firstDate.split(",")[1] +
              " - " +
              lastDate
            );
          } else {
            return (
              convertToDate(singleEvent.dates[0]["date"]) +
              " - " +
              convertToDate(
                singleEvent.dates[singleEvent.dates.length - 1]["date"]
              )
            );
          }
        } else {
          return convertToDate(singleEvent.dates[0]["date"]);
        }
      } else {
        return convertToDate(singleEvent.dates[0]["date"]);
      }
    }
  };

  // Handle click anywhere on the page to stop Swiper autoplay
  const handlePageClick = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };
  return (
    <div onClick={handlePageClick}>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        cssMode={true}
        mousewheel={true}
        keyboard={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper banner-slider"
      >
        {events && events.length > 0 ? (
          <>
            {events &&
              events.map((singleEvent, index) => {
                return (
                  <>
                    {index < 3 && (
                      <>
                        {!isEmpty(singleEvent.event) ? (
                          <SwiperSlide>
                            <div className="relative w-full min-h-[620px] md:min-h-full md:h-full bg-cover pt-14 md:pt-[135px] pb-10 md:pb-20">
                              <div
                                className="absolute inset-0 -z-10"
                                style={{
                                  backgroundImage: `url(${
                                    singleEvent &&
                                    singleEvent.event &&
                                    singleEvent.event.banner_image
                                      ? singleEvent.event.banner_image
                                      : bannerImageDefault
                                  })`,
                                  filter: "blur(30px)",
                                  opacity: "0.5px",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                              <div className="absolute inset-0 bg-[#00000033] -z-10"></div>
                              <div className="container">
                                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-10 lg:gap-32 mt-5 md:mt-0 items-end md:items-center">
                                  <div className="col-span-1 xl:col-span-2">
                                    <div className="relative">
                                      <h1 className="text-lg md:text-2xl lg:text-[32px] font-bold text-white text-outline tracking-[0.2rem]">
                                        #{index + 1} Trending
                                      </h1>
                                      <h2 className="text-lg md:text-2xl lg:text-[32px] font-bold text-white absolute -left-[2px] top-0 right-0 bottom-0 tracking-[0.2rem]">
                                        #{index + 1} Trending
                                      </h2>
                                    </div>
                                    <div className="flex gap-3 md:gap-6 pt-4">
                                      <Link
                                        to={"/events/" + singleEvent.event.slug}
                                        onClick={() => pushViewEvent('BannerView', 'click_eventView', 'View Event', singleEvent.event)}
                                        className="relative block "
                                      >
                                        <h2 className="text-3xl lg:text-4xl xl:text-[80px] font-body font-bold text-white text-outline-main xl:leading-[100px]">
                                          {singleEvent &&
                                            singleEvent.event &&
                                            singleEvent.event.name}
                                        </h2>
                                        <h3 className="text-3xl lg:text-4xl xl:text-[80px] font-body font-bold text-white absolute -left-[2px] w-full top-0 right-0 bottom-0 xl:leading-[100px]">
                                          {singleEvent &&
                                            singleEvent.event &&
                                            singleEvent.event.name}
                                        </h3>
                                      </Link>
                                      {/* {singleEvent && singleEvent.event && singleEvent.event.tagline && (
                                  <h3 className="text-white font-semibold text-sm lg:text-xl xl:text-3xl line-clamp-2 lg:line-clamp-none">
                                    {singleEvent.event.tagline}
                                  </h3>
                                )} */}
                                    </div>
                                    <p className="text-white text-base font-normal pt-4 leading-6 line-clamp-2">
                                      {singleEvent &&
                                      singleEvent.event &&
                                      singleEvent.event.short_description
                                        ? 
                                        <div
                                        dangerouslySetInnerHTML={{
                                          __html: singleEvent.event.short_description,
                                        }}
                                      />
                                        : ""}
                                    </p>
                                     {/* {singleEvent &&
                                      singleEvent.event &&
                                      singleEvent.event.short_description
                                     (<p className="text-white text-base font-normal pt-4 leading-6 line-clamp-2">
                                     <div
                                                dangerouslySetInnerHTML={{
                                                  __html: singleEvent.event.short_description,
                                                }}
                                              />
                                                                  
                                    </p>)
                                    } */}
                                    <div className="block xl:flex gap-5 pt-5">
                                      <h4 className="text-base md:text-[20px] text-white font-normal font-body">
                                        <img
                                          src={calendar}
                                          alt="calendar"
                                          className="inline-block mr-2"
                                        />
                                        {eventDateDisplay(singleEvent.event)}
                                      </h4>
                                      <div className="flex gap-5 mt-2 md:mt-3 xl:mt-0">
                                        {singleEvent &&
                                        singleEvent.event &&
                                        singleEvent.event &&
                                        !isEmpty(
                                          singleEvent &&
                                            singleEvent.event &&
                                            singleEvent.event.dates
                                        ) &&
                                        singleEvent &&
                                        singleEvent.event &&
                                        singleEvent.event.dates[0] &&
                                        singleEvent &&
                                        singleEvent.event &&
                                        singleEvent.event.dates[0][
                                          "start_time"
                                        ] ? (
                                          <h5 className="text-base md:text-[20px] text-white font-normal font-body">
                                            <img
                                              src={clock}
                                              alt="clock"
                                              className="inline-block mr-2"
                                            />
                                            {singleEvent &&
                                              singleEvent.event &&
                                              convertToAmPm(
                                                singleEvent.event.dates[0][
                                                  "start_time"
                                                ]
                                              )}

&nbsp;-&nbsp;
{singleEvent &&
                                              singleEvent.event &&
                                              convertToAmPm(
                                                singleEvent.event.dates[0][
                                                  "end_time"
                                                ]
                                              )}
                                          </h5>
                                        ) : (
                                          ""
                                        )}
                                        <div>
                                          {singleEvent &&
                                          singleEvent.event &&
                                          singleEvent.event.rating > 0 ? (
                                            <div className="flex-none">
                                              <div className="flex items-center justify-center">
                                                <img
                                                  src={star}
                                                  alt=""
                                                  className="inline-block mr-2 size-5 md:size-6 -mt-[2px] md:-mt-0"
                                                />
                                                <p className="text-white font-normal text-base md:text-[20px]">
                                                  {singleEvent &&
                                                    singleEvent.event &&
                                                    singleEvent.event.rating}
                                                </p>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {/* {singleEvent &&
                                singleEvent.event &&
                                singleEvent.event.no_of_ratings > 0 ? (
                                <h6 className="text-base md:text-[20px] text-white font-normal font-body block md:hidden mt-2">
                                  <i className="fas fa-user-friends mr-2"></i>
                                  {numberFormatter(singleEvent.event.no_of_ratings)}
                                </h6>
                              ) : (
                                ""
                              )} */}

                                    <h6 className="text-base md:text-[20px] text-white font-normal font-body mt-2 md:mt-4 line-clamp-1 lg:line-clamp-none">
                                      {singleEvent &&
                                      singleEvent.event &&
                                      singleEvent.event.address ? (
                                        <img
                                          src={locationIcon}
                                          alt="calendar"
                                          className="inline-block mr-2"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {singleEvent &&
                                      singleEvent.event &&
                                      singleEvent.event.address
                                        ? singleEvent &&
                                          singleEvent.event &&
                                          singleEvent.event.address
                                        : ""}
                                    </h6>
                                    {/* <div className="md:block hidden mt-3">
                                {singleEvent &&
                                singleEvent.event &&
                                singleEvent.event.feature > 0 ? (
                                  <button className="bg-secondary border border-secondary text-primary text-sm font-medium py-1 px-2 rounded inline-block mr-3">
                                    <img
                                      src={icon}
                                      alt="icon"
                                      className="inline-block mr-2 mb-1 size-4"
                                    />
                                    Featured
                                  </button>
                                ) : (
                                  ""
                                )}
                                {singleEvent &&
                                singleEvent.event &&
                                singleEvent.event.offer_available > 0 ? (
                                  <button className="bg-secondary border border-secondary text-primary text-sm font-medium py-1 px-2 rounded inline-block">
                                    <img
                                      src={icon2}
                                      alt="icon"
                                      className="inline-block mr-2 mb-1 size-4"
                                    />
                                    Offer Available
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div> */}

                                    {/* <div className="block md:hidden mt-4 mb-6">
                                {singleEvent &&
                                singleEvent.event &&
                                singleEvent.event.feature > 0 ? (
                                  <button className="bg-secondary border border-secondary text-primary text-sm font-medium py-1 px-2 rounded mb-4">
                                    <img
                                      src={icon}
                                      alt="icon"
                                      className="inline-block mr-2 mb-1 size-4"
                                    />
                                    Featured
                                  </button>
                                ) : (
                                  ""
                                )}
                                {singleEvent &&
                                singleEvent.event &&
                                singleEvent.event.offer_available > 0 ? (
                                  <button className="bg-secondary border border-secondary text-primary text-sm font-medium py-1 px-2 rounded block">
                                    <img
                                      src={icon2}
                                      alt="icon"
                                      className="inline-block mr-2 mb-1 size-4"
                                    />
                                    Offer Available
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div> */}

                                    <div className="hidden md:block">
                                      <div className="flex items-center gap-2 mt-10">
                                        {singleEvent.event.pass_created ===
                                          true &&
                                        singleEvent.event.pass_available ===
                                          true ? (
                                          <Link
                                            to={`/events/${singleEvent.event.slug}/book-tickets`}
                                            onClick={() => pushClickEvent('Banner_BookEvent','click_bookNow', 'Book Now', singleEvent.event)}
                                            className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center"
                                          >
                                            Book Now
                                          </Link>
                                        ) : singleEvent.event.pass_created ===
                                          false ? (
                                          <Link
                                            to={`/events/${singleEvent.event.slug}`}
                                            onClick={() => pushViewEvent('BannerView', 'click_eventView', 'View Event', singleEvent.event)}
                                            className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center"
                                          >
                                            View Event
                                          </Link>
                                        ) : singleEvent.event.pass_available ===
                                          false ? (
                                          <button className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center">
                                            Sold
                                          </button>
                                        ) : null}
                                        {singleEvent &&
                                        singleEvent.event &&
                                        singleEvent.event.offer_available ? (
                                          <button className="bg-inherit text-white text-sm md:text-[16px] font-normal tracking-[.25em] w-full lg:w-[213px] text-nowrap py-2.5 px-4 rounded font-body hidden md:inline-block">
                                            {singleEvent.event.discount_type ===
                                            "Percentage" ? (
                                              <span>
                                                {" "}
                                                <img
                                                  src={offer}
                                                  className="inline-block mr-2"
                                                  alt=""
                                                />
                                                {singleEvent &&
                                                singleEvent.event &&
                                                singleEvent.event.discount
                                                  ? singleEvent &&
                                                    singleEvent.event &&
                                                    singleEvent.event.discount
                                                  : ""}
                                                % Offer
                                              </span>
                                            ) : singleEvent.event
                                                .discount_type === "Amount" ? (
                                              <span>
                                                {" "}
                                                <img
                                                  src={offer}
                                                  className="inline-block mr-2"
                                                  alt=""
                                                />{" "}
                                                ₹{" "}
                                                {singleEvent &&
                                                singleEvent.event &&
                                                singleEvent.event.offer
                                                  ? singleEvent &&
                                                    singleEvent.event &&
                                                    singleEvent.event.offer
                                                  : ""}{" "}
                                                Offer
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="px-6 md:px-0">
                                    <Link
                                      to={"/events/" + singleEvent.event.slug}
                                    >
                                      <img
                                        src={
                                          singleEvent &&
                                          singleEvent.event &&
                                          singleEvent.event.banner_image
                                            ? singleEvent &&
                                              singleEvent.event &&
                                              singleEvent.event.banner_image
                                            : bannerImageDefault
                                        }
                                        className="w-full rounded-xl aspect-[9/16] object-fill"
                                        alt=""
                                        onClick={() => pushViewEvent('BannerView', 'click_eventView', 'View Event', singleEvent.event)}
                                      />
                                    </Link>
                                    <div className="block md:hidden">
                                      <div className="flex items-center justify-center gap-2 mt-5 mb-5">
                                        {singleEvent.event.pass_created ===
                                          true &&
                                        singleEvent.event.pass_available ===
                                          true ? (
                                          <Link
                                            to={`/events/${singleEvent.event.slug}/book-tickets`}
                                            onClick={() => pushClickEvent('Banner_BookEvent','click_bookNow', 'Book Now', singleEvent.event)}
                                            className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center w-[120px]"
                                          >
                                            Book Now
                                          </Link>
                                        ) : singleEvent.event.pass_created ===
                                          false ? (
                                          <Link
                                            to={`/events/${singleEvent.event.slug}`}
                                            onClick={() => pushViewEvent('BannerView', 'click_eventView', 'View Event', singleEvent.event)}
                                            className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center w-[120px]"
                                          >
                                            View Event
                                          </Link>
                                        ) : singleEvent.event.pass_available ===
                                          false ? (
                                          <button className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center w-[120px]">
                                            Sold
                                          </button>
                                        ) : null}
                                        {singleEvent &&
                                        singleEvent.event &&
                                        singleEvent.event.offer_available ? (
                                          <button className="bg-inherit text-white text-sm md:text-[16px] font-normal tracking-[.25em] w-full lg:w-[213px] text-nowrap py-2.5 px-4 rounded font-body hidden md:inline-block">
                                            {singleEvent.event.discount_type ===
                                            "Percentage" ? (
                                              <span>
                                                {" "}
                                                <img
                                                  src={offer}
                                                  className="inline-block mr-2"
                                                  alt=""
                                                />
                                                {singleEvent &&
                                                singleEvent.event &&
                                                singleEvent.event.discount
                                                  ? singleEvent &&
                                                    singleEvent.event &&
                                                    singleEvent.event.discount
                                                  : ""}
                                                % Offer
                                              </span>
                                            ) : singleEvent.event
                                                .discount_type === "Amount" ? (
                                              <span>
                                                {" "}
                                                <img
                                                  src={offer}
                                                  className="inline-block mr-2"
                                                  alt=""
                                                />{" "}
                                                ₹{" "}
                                                {singleEvent &&
                                                singleEvent.event &&
                                                singleEvent.event.offer
                                                  ? singleEvent &&
                                                    singleEvent.event &&
                                                    singleEvent.event.offer
                                                  : ""}{" "}
                                                Offer
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                );
              })}
          </>
        ) : (
          ""
        )}
      </Swiper>
    </div>
  );
};
Banner.propTypes = {
  events: PropTypes.array,
};
export default Banner;
