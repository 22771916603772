import React from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import gifUrl from "../../assets/light_theme_images/Payment-Unsuccessful.gif";
const PaymentUnsuccessfull = () => {

  return (
    <div className="bg-white pt-14 pb-14">
      <div className="container">
        <div className="lg:px-14">
          <h2 className="text-3xl md:text-4xl text-[#101010] font-semibold text-center">
          Payment Unsuccessful!
          </h2>
          <p className="text-lg text-[#101010] font-normal text-center mt-2">
          Payment unsuccessful! Your Mepass adventure is on hold. Give it another shot!
          </p>
          <div className="flex justify-center mt-5 md:mt-9">
            <div className="w-[400px] h-[400px]">
              <img src={gifUrl} alt="Example GIF"/>
            </div>
          </div>
          <div className="flex justify-center mt-5 md:mt-9">
            <Link to="" 
            className="text-[#FFFFFF] btn-liner-gradient text-lg flex justify-center items-center rounded-lg font-semibold py-2 px-4 h-[56px] w-[172px] md:w-[524px]">
            Try Again
              </Link>
            </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PaymentUnsuccessfull);
